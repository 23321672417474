import {Component, Input, OnInit} from '@angular/core';
import {Task} from '../../../models/task';
import {NbDialogRef} from '@nebular/theme';
import {TaskService} from '../../../services/task/task.service';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-task-confirm-delete',
  templateUrl: './task-confirm-delete.component.html',
  styleUrls: ['./task-confirm-delete.component.scss']
})
export class TaskConfirmDeleteComponent implements OnInit {

  @Input() task: Task;

  constructor(protected ref: NbDialogRef<TaskConfirmDeleteComponent>,
              private toastrService: SolarifToastrService,
              private taskService: TaskService) { }

  ngOnInit() {
    if (!this.task) {
      console.log('No task given to delete! Closing dialog');
      this.ref.close();
    }
  }

  cancelDelete(): void {
    this.ref.close();
  }

  deleteTask(): void {
    this.taskService.deleteTask(this.task.uuid).subscribe(success => {
      this.toastrService.success(
        $localize`:@@taskRemovedSuccess:Task successfully removed`
      );
      this.taskService.reloadTasks();
      this.ref.close();
    }, error => {
      this.toastrService.error(
        $localize`:@@taskRemovedError:Could not delete task`
      );
      console.log(error);
    });
  }
}
