import {Component, Input, OnInit} from '@angular/core';
import {ClientService} from '../../../../services/client/client.service';
import {TaxonomyService} from '../../../../services/taxonomy/taxonomy.service';
import { Subscription } from 'rxjs';
import {Client} from '../../../../models/client/client';
import {ClientContact} from '../../../../models/client/clientcontact';
import {TaxonomyTerm} from '../../../../models/terms/taxonomyTerm';

@Component({
  selector: 'app-client-contacts-primary-list',
  templateUrl: './client-contacts-primary-list.component.html',
  styleUrls: ['./client-contacts-primary-list.component.scss']
})
export class ClientContactsPrimaryListComponent implements OnInit {

  @Input() client: Client;

  @Input()
  contacts: Array<ClientContact>;


  contactTypeTerms: Array<TaxonomyTerm> = [];
  languageTerms: Array<TaxonomyTerm> = [];
  departmentTerms: Array<TaxonomyTerm> = [];

  subscription = new Subscription();

  constructor(private clientService: ClientService, private taxonomyService: TaxonomyService) { }

  ngOnInit() {
    this.getContactTypeTerms();
    this.getLanguageTerms();
    this.getDepartmentTerms();
  }


  getContactTypeTerms() {
    const termSubscription = this.taxonomyService.getTermsByVocabulary('contact_type').subscribe(data => {
      this.contactTypeTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary contact_type', error);
    });
    this.subscription.add(termSubscription);
  }

  getLanguageTerms() {
    const termSubscription = this.taxonomyService.getTermsByVocabulary('spoken_languages').subscribe(data => {
      this.languageTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary spoken_languages', error);
    });
    this.subscription.add(termSubscription);
  }

  getDepartmentTerms() {
    const termSubscription = this.taxonomyService.getTermsByVocabulary('contact_department').subscribe(data => {
      this.departmentTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary contact_department', error);
    });
    this.subscription.add(termSubscription);
  }

}
