<div class="d-flex justify-content-between align-items-baseline">
  <table>
    <tr *ngIf="contactInfo.email">
      <td><strong><span i18n="@@emailAddress">Email address</span>:</strong></td>
      <td><a href="mailto:{{ contactInfo?.email }}">{{ contactInfo?.email }}</a></td>
    </tr>
    <tr *ngIf="contactInfo.phone">
      <td><strong><span i18n="@@phoneNumber">Phone number</span>:</strong></td>
      <td>{{ contactInfo?.phone }}</td>
    </tr>
    <tr *ngIf="contactInfo.website">
      <td><strong><span i18n="@@website">Website</span>:</strong></td>
      <td><a href="{{ contactInfo?.website }}">{{ contactInfo?.website }}</a></td>
    </tr>
  </table>

  <button class="btn btn-light btn-sm edit-client-contact-info" [disabled]="currentLock === 1" (click)="openEditDialog(editDialog)">
    <nb-icon icon="edit-outline"></nb-icon>
  </button>
</div>

<ng-template #editDialog let-ref="dialogRef">
  <form [formGroup]="contactForm" class="dialog-form" (ngSubmit)="onSubmit(ref)" novalidate>
    <nb-card>
      <nb-card-header i18n="@@editContactInfo">Edit contact info</nb-card-header>
      <nb-card-body>
        <div class="form-item">
          <label for="email" class="label" i18n="@@emailAddress">Email address</label>
          <input nbInput id="email" type="email" name="email" placeholder="E-mailadres" fullWidth
                 formControlName="email">
          <div class="alert alert-danger mt-2" *ngIf="(email.invalid && email.errors.email) || (email.invalid && email.errors.pattern)">
            <small><strong i18n="@@contactInfoInvalidEmailTitle">Invalid email.</strong></small>
          </div>
        </div>
        <div class="form-item">
          <label for="phone" class="label" i18n="@@phoneNumberLabel">Phone number</label>
          <input nbInput id="phone" name="phone" placeholder="Phone number" fullWidth formControlName="phone"
                 i18n-placeholder="@@phoneNumberPlaceholder">
        </div>
        <div class="form-item">
          <label for="website" class="label" i18n="@@websiteLabel">Website</label>
          <input nbInput id="website" name="website" placeholder="Website" fullWidth formControlName="website"
                 i18n-placeholder="@@websitePlaceholder">
          <div class="alert alert-danger mt-2" *ngIf="website.invalid && website.errors.pattern">
            <small><strong i18n="@@contactInfoInvalidWebsiteTitle">Invalid website address.</strong><br>
              <span i18n="@@contactInfoInvalidWebsiteMessage">Did you forget "http://" or "http://"?</span></small>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="form-actions text-right">
          <input type="button" nbButton ghost status="primary" (click)="cancelEdit(ref)" value="Cancel"
                 i18n-value="@@cancel">
          <button nbButton status="primary" type="submit" [disabled]="contactForm.invalid" i18n="@@submit">Submit
          </button>
        </div>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
