import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ClientService} from '../../../../services/client/client.service';
import {TaxonomyService} from '../../../../services/taxonomy/taxonomy.service';
import { Subscription } from 'rxjs';
import {Client} from '../../../../models/client/client';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {ContactService} from '../../../../services/contact/contact.service';
import {SolarifToastrService} from '../../../../services/toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {UserService} from '../../../../services/user/user.service';
import {User} from '../../../../models/user';
import {SearchedContact, SearchedUser} from '../../../../models/client/searched-contact';
import {ClientContact} from '../../../../models/client/clientcontact';
import {ContactTypeTaxonomyTerm, TaxonomyTerm} from '../../../../models/terms/taxonomyTerm';

@Component({
  selector: 'app-client-contacts-list',
  templateUrl: './client-contacts-list.component.html',
  styleUrls: ['./client-contacts-list.component.scss']
})
export class ClientContactsListComponent implements OnInit, OnDestroy {

  @Input() client: Client;
  @Input() contacts: Array<ClientContact>;

  contactTypeTerms: Array<ContactTypeTaxonomyTerm> = [];
  languageTerms: Array<TaxonomyTerm> = [];
  departmentTerms: Array<TaxonomyTerm> = [];

  subscription = new Subscription();
  addContactForm: FormGroup;

  addNewUser = false;

  userAutocompleteOptions: Array<SearchedContact | SearchedUser> = [];
  loadingUsers = false;

  get username() { return this.addContactForm.get('username'); }
  get email() { return this.addContactForm.get('email'); }
  get usernameSearch() { return this.addContactForm.get('usernameSearch'); }
  get userSelect() { return this.addContactForm.get('userSelect'); }

  constructor(private userService: UserService,
              private taxonomyService: TaxonomyService,
              private dialogService: NbDialogService,
              private contactService: ContactService,
              private toastrService: SolarifToastrService
  ) { }

  ngOnInit() {
    this.getContactTypeTerms();
    this.getLanguageTerms();
    this.getDepartmentTerms();
    this.resetForm();
  }

  ngOnDestroy(): void {
    this.contacts = [];
  }

  getContactTypeTerms() {
    const termSubscription = this.taxonomyService.getTermsByVocabulary('contact_type').subscribe(data => {
      this.contactTypeTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary contact_type', error);
    });
    this.subscription.add(termSubscription);
  }

  getLanguageTerms() {
    const termSubscription = this.taxonomyService.getTermsByVocabulary('spoken_languages').subscribe(data => {
      console.log('ClientContactList getLanguageTerms', data);
      this.languageTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary spoken_languages', error);
    });
    this.subscription.add(termSubscription);
  }

  getDepartmentTerms() {
    const termSubscription = this.taxonomyService.getTermsByVocabulary('contact_department').subscribe(data => {
      this.departmentTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary contact_department', error);
    });
    this.subscription.add(termSubscription);
  }

  openAddContactForm(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog).onClose.subscribe(() => {
      this.resetForm();
    });
  }

  resetForm() {
    this.addNewUser = false;
    this.addContactForm = new FormGroup({
      username: new FormControl({value: '', disabled: false}, null),
      usernameSearch: new FormControl({value: '', disabled: false}, null),
      userSelect: new FormControl({value: '', disabled: false}, Validators.required),
      email: new FormControl('', null) // We set this validator in addNewUserChange()
    });
  }

  addNewUserChange(newValue) {
    this.addNewUser = newValue;

    if (this.addNewUser) {{
      this.addContactForm.get('email').setValidators([Validators.required]);
      this.addContactForm.get('username').setValidators([Validators.required, Validators.minLength(3)]);
      this.addContactForm.get('userSelect').setValidators(null);
    }} else {
      this.addContactForm.get('email').setValidators(null);
      this.addContactForm.get('username').setValidators(null);
      this.addContactForm.get('userSelect').setValidators(Validators.required);
    }

    this.addContactForm.get('email').updateValueAndValidity();
    this.addContactForm.get('username').updateValueAndValidity();
    this.addContactForm.get('userSelect').updateValueAndValidity();
  }

  onUserSearchChange(newValue) {
    if (newValue.replace(' ', '').length >= 3) {
      this.searchUsers(newValue);
    }
  }

  cancelAdd(ref) {
    ref.close();
    this.resetForm();
  }

  submitForm(ref) {
    let username = this.addContactForm.get('username').value;
    const email = this.addContactForm.get('email').value;
    const isNew = this.addNewUser;

    if (!isNew) {
      username = this.addContactForm.get('userSelect').value;
    }

    if (this.userAlreadyInList(username)) {
      this.toastrService.info(
        $localize`:@@contactAlreadyAdded:User is already a contact of this client`
      );
      return null;
    }

    this.contactService.addContact(this.client.id, username, email, isNew).subscribe(result =>  {
      this.toastrService.success(
        $localize`:@@contactAddedSuccess:Contact has been added.`
      );
      ref.close();
      this.contactService.reloadContacts(); // Fire the event to subscribers
    }, error => {
      console.log('Error', error);
      const translatedMessage = this.getErrorMessage(error.error.type);
      this.toastrService.error(
        translatedMessage,
        $localize`:@@contactAddedError:Failed to add contact.`
      );
    });
  }

  private searchUsers(text) {
    this.loadingUsers = true;
    this.contactService.search(text).subscribe(users => {
      console.log('found users', users);
      this.userAutocompleteOptions = users;
      this.loadingUsers = false;
    });
  }

  private userAlreadyInList(username: string) {
    const inPrimary = this.contactService.contactsArray.primary.find(contact => contact.username === username);
    const inContacts = this.contacts.find(contact => contact.username === username);

    return (inPrimary || inContacts);
  }

  typeOfContactIsUser(contact: SearchedUser | SearchedContact): boolean {
    return (contact as SearchedContact).gid === undefined;
  }

  private getErrorMessage(type) {
    switch (type) {
      case 'no_username':
        return $localize`:@@addContactErrorNoUsername:No username given`;

      case 'missing_group':
        return $localize`:@@addContactErrorMissingGroup:No valid client given`;

      case 'no_email':
        return $localize`:@@addContactErrorNoEmail:No email given`;

      case 'username_taken':
        return $localize`:@@addContactErrorUsernameTaken:Username is already taken`;

      case 'email_taken':
        return $localize`:@@addContactErrorEmailTaken:Email is already taken`;

      case 'saving_error':
        return $localize`:@@addContactErrorSave:Error while saving contact`;

      case 'add_to_group_error':
        return $localize`:@@addContactErrorAddToClient:Error while adding contact to client`;

      case 'username_not_matching':
        return $localize`:@@addContactErrorUsernameNotMatchingUser:Username not matching user`;

      default:
        return $localize`:@@unknownError:Unknown error`;
    }
  }
}
