<nb-card *ngIf="policy && policy.contracts.length > 0">
  <nb-card-header i18n="@@policies">Policies</nb-card-header>
  <nb-card-body>
    <table>
      <tr>
        <th i18n="@@policyNumber">Policy number</th>
        <th i18n="@@nameInsurer">Name insurer</th>
        <th i18n="@@description">Description</th>
        <th i18n="@@inceptionDate">Inception date</th>
        <th i18n="@@expiryDate">Expiry date</th>
        <th i18n="@@status">Status</th>
        <th i18n="@@activeInvoices">Active invoices</th>
        <th i18n="@@claims">Claims</th>
      </tr>
      <tr *ngFor="let contract of contractsFilteredByStatus()">
        <td>{{ contract.contractId }}</td>
        <td>{{ contract.insurer }}</td>
        <td>{{ contract.description }}</td>
        <td>{{ contract.inceptionDate | date: 'dd-MM-yyyy' }}</td>
        <td>{{ contract.expiryDate | date: 'dd-MM-yyyy' }}</td>
        <td>{{ contract.status | policyContractStatusFormat }}</td>
        <td class="text-right">{{ contractNumberOfInvoices(contract) }}</td>
        <td class="text-right">{{ contractNumberOfClaims(contract) }}</td>
      </tr>
    </table>
  </nb-card-body>
</nb-card>
