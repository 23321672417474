<div class="loading" *ngIf="isLoadingTasks; else finishedLoading" nbSpinner="true"
     nbSpinnerStatus="primary" nbSpinnerSize="tiny">
</div>
<ng-template #finishedLoading>
  <div class="task-list" *ngIf="filteredTasks.length > 0; else noTasks">
    <ng-container *ngFor="let task of filteredTasks">
      <app-task-list-item [task]="task"
                          [accountManagers]="accountManagers"
                          [taskStatusTerms]="taskStatusTerms"
                          [taskTypeTerms]="taskTypeTerms"
      ></app-task-list-item>
    </ng-container>
  </div>
  <ng-template #noTasks>
    <div class="empty-list-item" i18n="@@noTasksFound">
      No tasks found
    </div>
  </ng-template>
</ng-template>
