import { Injectable } from '@angular/core';
import {TaxonomyService} from '../taxonomy/taxonomy.service';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {AccountManagerService} from '../account-manager/account-manager.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TasksResolverService {

  constructor(private taxonomyService: TaxonomyService, private accountManagerService: AccountManagerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    const accountManagers = this.accountManagerService.accountManagers();
    const businessUnits = this.taxonomyService.getTermsByVocabulary('business_unit');
    const statuses = this.taxonomyService.getTermsByVocabulary('task_status');
    const types = this.taxonomyService.getTermsByVocabulary('task_type');

    return forkJoin([accountManagers, businessUnits, statuses, types]).pipe(
      map(result => {
        return {
          accountManagers: result[0],
          businessUnits: result[1],
          statuses: result[2],
          types: result[3]
        };
      })
    );
  }
}
