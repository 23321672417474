<div class="row">
  <div class="col-12 text-right mb-3">
    <button class="btn btn-light btn-xl d-flex align-items-center" title="Add address" i18n-title="@@addAddress" [disabled]="currentLock === 1" (click)="openAddDialog(addDialog)">
      <!--      <strong i18n="@@addAddress">Add address</strong>-->
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
  </div>
  <div class="col-12 col-md-6 col-xl-4" *ngFor="let address of addresses">
    <app-client-address [client]="client" [address]="address" [addressTypes]="addressTypes" (deleted)="onAddressDeleted($event)"></app-client-address>
  </div>
</div>

<ng-template #addDialog let-ref="dialogRef">
  <div class="address edit">
    <form [formGroup]="addAddressForm" class="dialog-form" novalidate (ngSubmit)="onSubmit(ref)">
      <nb-card>
        <nb-card-header i18n="@@addAddressHeader">Add address</nb-card-header>
        <nb-card-body>
          <div class="row">
            <div class="col-md-6">
              <div class="form-item">
                <label class="label" i18n="@@addressTitle">Title</label>
                <input nbInput fullWidth formControlName="title" placeholder="Title" i18n-placeholder="@@addressTitlePlaceholder">
              </div>
              <div class="form-item">
                <nb-checkbox formControlName="isPostAddress" id="is_post_address" i18n="@@addressIsPostAddressText">This address is a post address</nb-checkbox>
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressType">Adress type</label>
                <nb-select placeholder="Select address type" i18n-placeholder="@@addressTypePlaceholder"
                           formControlName="type" fullWidth>
                  <nb-option *ngFor="let type of addressTypes" value="{{type.id}}">{{type.name}}</nb-option>
                </nb-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-item">
                <label class="label" i18n="@@addressCountry">Country</label>
                <nb-select placeholder="Select country" i18n-placeholder="@@countrySelectPlaceholder"
                           formControlName="countryCode" fullWidth (selectedChange)="updateFormValidation()">
                  <nb-option value="DE" i18n="@@germany">Germany</nb-option>
                  <nb-option value="NL" i18n="@@netherlands">The Netherlands</nb-option>
                  <nb-option value="BE" i18n="@@belgium">Belgium</nb-option>
                </nb-select>
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressLine1">Address Line 1</label>
                <input nbInput fullWidth formControlName="addressLine" placeholder="Address" i18n-placeholder="@@addressLine1Placeholder">
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressLine2">Address Line 2</label>
                <input nbInput fullWidth formControlName="addressLineSecondary" placeholder="Address Line 2" i18n-placeholder="@@addressLine2Placeholder">
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressPostalCode">Postal Code</label>
                <input nbInput fullWidth formControlName="postalCode" placeholder="Postal Code" i18n-placeholder="@@postalCodePlaceholder">
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressCity">City</label>
                <input nbInput fullWidth formControlName="locality" placeholder="City" i18n-placeholder="@@cityPlaceholder">
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="form-actions text-right">
            <input type="button" nbButton ghost status="primary" (click)="cancelEdit(ref)" value="Cancel" i18n-value="@@cancel">
            <button nbButton status="primary" type="submit" [disabled]="addAddressForm.invalid" i18n="@@submit">Submit</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </form>
  </div>
</ng-template>
