<nb-card class="tasks-card">
  <nb-card-header class="d-flex justify-content-between align-items-center">
    <h1 i18n="@@Tasks">Tasks</h1>
    <div class="actions">
      <button class="btn btn-light btn-sm export-button"
              i18n-title="@@export"
              title="Export"
              (click)="export()"
              [disabled]="loadingExport"
              [nbSpinner]="loadingExport"
              nbSpinnerSize="tiny"
              nbSpinnerStatus="primary"
      >
        <nb-icon icon="cloud-download-outline"></nb-icon>
        <span class="export--label">Export excel</span>
      </button>
      <button class="btn btn-light btn-sm"
              i18n-title="@@addTask"
              title="Add task"
              (click)="openAddDialog()"
      >
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
    </div>
  </nb-card-header>
  <app-task-filter [setCurrentAccountManagerFilter]="true"></app-task-filter>
  <app-task-list></app-task-list>
<!--  <nb-tabset>-->
<!--    <nb-tab tabTitle="Open" i18n-tabTitle="@@taskOpen">-->
<!--      <app-task-list [show]="'notDone'"></app-task-list>-->
<!--    </nb-tab>-->
<!--    <nb-tab tabTitle="Done" i18n-tabTitle="@@taskDone">-->
<!--      <app-task-list [show]="'done'"></app-task-list>-->
<!--    </nb-tab>-->
<!--  </nb-tabset>-->
</nb-card>
