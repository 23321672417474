import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ClientService} from '../client/client.service';
import {AuthService} from '../auth.service';
import {IdentityClaims} from '../../models/identityClaims';

@Injectable({
  providedIn: 'root'
})
export class ClientResolverService implements Resolve<any> {

  constructor(
    private clientService: ClientService,
    private router: Router,
    private authService: AuthService
  ) {
  }

  public get identityClaims(): IdentityClaims {
    return this.authService.identityClaims;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    // const testID = {
    //   '2fCapital': 'fdc57110-49b7-424e-a455-efc8bb5e6b74',
    //   KingpanLimited: '650822ad-9473-4695-948b-984d5adb7c7c',
    //   MathBalendonck: 'e4b1fc01-be21-4b97-b858-dcbf73880d28',
    //   '2eq': '432004ef-8498-4b30-af5e-5b353b8d20bf'
    // };

    const gid = route.paramMap.get('gid');

    if (gid) {
      return this.clientService.client(gid);
    } else {
      return this.clientService.client(this.identityClaims.groups[0]);
    }

    // this.clientService.allClients()[0].subscribe(client => {
    //   return client;
    // });
  }
}
