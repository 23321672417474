import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'policyContractStatusFormat'
})
export class PolicyContractStatusFormatPipe implements PipeTransform {

  transform(status: string): string {
    // Example '0000 LOPEND' becomes 'LOPEND'
    return status.substr(5);
  }

}
