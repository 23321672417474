import {Component, Input, OnInit} from '@angular/core';
import {Task, TaskFilters} from 'src/app/models/task';
import {TaskService} from '../../../services/task/task.service';
import {AccountManagerService} from '../../../services/account-manager/account-manager.service';
import {AccountManager} from '../../../models/AccountManager';
import {TaxonomyService} from '../../../services/taxonomy/taxonomy.service';
import {environment} from '../../../../environments/environment';
import {shareReplay} from 'rxjs/operators';
import {Client} from '../../../models/client/client';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {

  @Input() client: Client;
  @Input() show: 'all' | 'notDone' | 'done' = 'all';

  tasks: Array<Task> = [];
  filteredTasks: Array<Task> = [];
  businessUnitTerms: Array<any>;
  accountManagers: Array<AccountManager>;
  taskTypeTerms: Array<any>;
  taskStatusTerms: Array<any>;

  isLoadingTasks = true;

  constructor(private taskService: TaskService,
              private taxonomyService: TaxonomyService,
              private accountManagerService: AccountManagerService) { }

  ngOnInit() {
    this.getAccountManagers();
    this.getBusinessUnitTerms();
    this.getTaskStatusTerms();
    this.getTaskTypeTerms();

    if (this.tasks.length > 0) {
      this.isLoadingTasks = false;
    } else {
      this.getTasks();
    }

    this.taskService.taskFiltersChanged$.subscribe(filters => {
      console.log('TaskFilter changed', filters);
      this.filteredTasks = this.tasks.filter(task => {
        return this.taskService.doesTaskPassFilter(task, filters);
      });
    });
  }

  private getTasks() {
    this.isLoadingTasks = true;
    this.taskService.getTasks(this.client).subscribe(tasks => {
      switch (this.show) {
        case 'done':
          this.tasks = tasks.filter(task => task.statusId === environment.taxonomyTermIds.taskStatus.done);
          break;
        case 'notDone':
          this.tasks = tasks.filter(task => task.statusId !== environment.taxonomyTermIds.taskStatus.done);
          break;
        default:
          this.tasks = tasks;
      }
      this.filteredTasks = this.tasks.filter(task => {
        return this.taskService.doesTaskPassFilter(task, this.taskService.getCurrentFilter());
      });
      this.isLoadingTasks = false;
      console.log('TASK LIST, tasks loaded with current filter', this.taskService.getCurrentFilter());
    });
    this.taskService.tasksChanged$.subscribe(_ => this.getTasks());
  }

  private getBusinessUnitTerms() {
    this.taxonomyService.getTermsByVocabulary('business_unit').pipe(shareReplay()).subscribe(data => {
      this.businessUnitTerms = data;
    });
  }

  private getAccountManagers() {
    this.accountManagerService.accountManagers().subscribe(accountManagers => {
      this.accountManagers = accountManagers;
    });
  }

  private getTaskTypeTerms() {
    this.taxonomyService.getTermsByVocabulary('task_type').subscribe(data => {
      this.taskTypeTerms = data;
    });
  }

  private getTaskStatusTerms() {
    this.taxonomyService.getTermsByVocabulary('task_status').subscribe(data => {
      this.taskStatusTerms = data;

      const currentFilter = this.taskService.getCurrentFilter();
      if (currentFilter.statuses === undefined) {
        // Set default filter to show tasks with all statuses except done
        // Note that this is the same functionality as in the task filter,
        // but this needs to be called as well here in case of when the filter is not showing (by not being toggled on for example)
        const termsToActivate = [];
        this.taskStatusTerms.forEach(status => {
          if (status.id !== environment.taxonomyTermIds.taskStatus.done) {
            termsToActivate.push(status.id);
          }
          const filter = currentFilter;
          filter.statuses = termsToActivate;
          this.taskService.setFilter(filter);
        });
      }

    });
  }

}
