import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Address} from '../../models/address';
import {environment} from '../../../environments/environment';
import {JsonApiResponseSingle} from '../../models/api/jsonapi.models';
import {AddressResponse} from '../../models/api/solarif_rest.models';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json'
    })
  };

  constructor(private httpClient: HttpClient) { }

  public addAddress(clientUuid: string, address: Address) {
    const body = {
      clientUuid,
      address
    };

    console.log('AddressService addAddress body', body);

    return this.httpClient.post<AddressResponse>(environment.drupalUrl + '/api/client/address', body, { observe: 'response' });
  }

  public updateAddress(address: Address) {
    const body = this.mapAddressBody(address);

    return this.httpClient.patch(environment.drupalUrl + '/jsonapi/client/address/' + address.uuid, body);
  }

  public deleteAddress(address: Address) {
    return this.httpClient.delete(`${environment.drupalUrl}/jsonapi/client/address/${address.uuid}`);
  }

  private mapAddressBody(address: Address) {
    return {
      data: {
        type: 'client--address',
        id: address.uuid,
        attributes: {
          title: address.title,
          field_address: {
            postal_code: address.postal_code,
            country_code: address.country_code,
            address_line1: address.address_line1,
            address_line2: address.address_line2,
            locality: address.locality,
          },
          field_is_post_address: (address.is_post_address) ? 1 : 0
        },
        relationships: {
          field_ref_type: {
            data: {
              type: 'taxonomy_term--adres_type',
              id: address.type.uuid
            }
          }
        },
      }
    };
  }
}
