import {Component, OnInit} from '@angular/core';
import {ActivityService} from '../../services/activity/activity.service';
import {PolicyApplicationActivity} from '../../models/activity/activity';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-policy-applications',
  templateUrl: './policy-applications.component.html',
  styleUrls: ['./policy-applications.component.scss']
})
export class PolicyApplicationsComponent implements OnInit {

  activities: Array<PolicyApplicationActivity>;
  dtOptions: any = {};

  constructor(
    private router: Router,
    private activityService: ActivityService
  ) { }

  ngOnInit() {
    this.fetchActivities();
    this.initDataTable();
  }

  private fetchActivities(): void {
    this.activityService.policyApplications().subscribe(activities => {
      this.activities = activities;
      console.log('act', activities);
    });
  }

  rowClickHandler(data) {
    this.router.navigate(['client/' + data['clientId']], { queryParams: { activity: data['id'] } });
  }

  initDataTable() {
    const that = this;

    // Default language strings based on https://datatables.net/plug-ins/i18n/English. Translate using Angular i18n.
    this.dtOptions = {
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      info: false,
      pageLength: 50,
      order: [[0, 'desc']],
      searchDelay: 500,
      language: {
        emptyTable: $localize`:@@datatablesEmptyTable:No data available in table.`,
        info: $localize`:@@datatablesInfo:Showing _START_ to _END_ of _TOTAL_ entries`,
        infoEmpty: $localize`:@@datatablesInfoEmpty:No results to show`,
        infoFiltered: $localize`:@@datatablesInfoFiltered:(filtered from _MAX_ total entries)`,
        infoPostFix: $localize`:@@datatablesInfoPostFix: `,
        thousands: $localize`:@@datatablesThousands:'Enter thousands separator only.:.`,
        lengthMenu: $localize`:@@datatablesLengthMenu:Show _MENU_ entries`,
        loadingRecords: $localize`:@@datatablesLoadingRecords:Loading...`,
        processing: $localize`:@@datatablesProcessing:Processing...`,
        search: $localize`:@@datatablesSearch:Search:`,
        searchPlaceholder: $localize`:@@datatablesSearchPlaceholder:Search`,
        zeroRecords: $localize`:@@datatablesZeroRecords:No matching records found`,
        paginate: {
          first: $localize`:@@datatablesPaginateFirst:First`,
          last: $localize`:@@datatablesPaginateLast:Last`,
          next: $localize`:@@datatablesPaginateNext:Next`,
          previous: $localize`:@@datatablesPaginatePrevious:Previous`,
        },
        aria: {
          sortAscending: $localize`:@@datatablesAriaSortAscending:: activate to sort column ascending`,
          sortDescending: $localize`:@@datatablesAriaSortDescending:: activate to sort column descending`,
        },
      },
      ajax: (dataTablesParameters: any, callback) => {
        const sortColumn = dataTablesParameters.order.length && dataTablesParameters.order[0].column === 0 ? 'date' : 'client';
        const sortOrder = dataTablesParameters.order.length && dataTablesParameters.order[0].dir === 'asc' ? 'asc' : 'desc';

        this.activityService.policyApplications(sortColumn, sortOrder).subscribe(activities => {
          this.activities = activities;
          callback({
            recordsTotal: activities.length,
            recordsFiltered: activities.length,
            data: activities
          });
        });
      },
      columns: [
        {
          title: $localize`:@@date:Date`,
          data: 'date',
          orderable: true,
          render(data, type, row, meta) {
            return UtilitiesService.getDisplayString(new Date(data));
          }
        },
        {
          title: $localize`:@@status:Status`,
          data: 'status',
          orderable: false
        },
        {
          title: $localize`:@@client:Client`,
          data: 'clientLabel',
          orderable: true,
          render(data, type, row, meta) {
            if (data) {
              return '<span class="routerLink">' + data + '</span>';
            } else {
              return null;
            }
          },
        },
        {
          title: $localize`:@@description:Description`,
          data: 'title',
          orderable: false,
        },
        {
          title: $localize`:@@assignee:Assignee`,
          data: 'assignee',
          orderable: false
        },

      ],
      drawCallback: _ => {
        // this.filterForm.enable();
      },
      // tslint:disable-next-line:ban-types
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        jQuery('td .routerLink', row).off('click');
        jQuery('td .routerLink', row).on('click', () => {
          self.rowClickHandler(data);
        });
        return row;
      },
    };
  }

}
