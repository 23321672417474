import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  static ISODateString(d: Date): string {
    const pad = (n) => n < 10 ? '0' + n : n;

    return d.getUTCFullYear() + '-'
      + pad(d.getUTCMonth() + 1) + '-'
      + pad(d.getUTCDate()) + 'T'
      + pad(d.getUTCHours()) + ':'
      + pad(d.getUTCMinutes()) + ':'
      + pad(d.getUTCSeconds()) + 'Z';
  }

  static getDisplayString(d: Date): string {
    const pad = (n) => n < 10 ? '0' + n : n;

    return pad(d.getUTCDate()) + '-' + pad(d.getUTCMonth() + 1) + '-' + d.getUTCFullYear()
      + ' ' + pad(d.getUTCHours()) + ':'
      + pad(d.getUTCMinutes()) + ':'
      + pad(d.getUTCSeconds());
  }

  static parseDecimalString(txt: string): number {
    const decimal = parseFloat(txt);
    return (!isNaN(decimal)) ? decimal : 0;
  }

}
