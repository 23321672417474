// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  drupalUrl: 'https://cdp.scdp.elonisas.dev', // Enter without trailing slash.
  issuerUrl: 'https://cdp.scdp.elonisas.dev', // Enter without trailing slash.
  websiteUrl: {
    nl: 'https://solarif.nl', // Enter without trailing slash.
    en: 'https://solarif.com', // Enter without trailing slash.
  },
  mainMenu: 'main',
  version: '0.1.0',
  buildDate: '1574080598339',
  taxonomyTermIds: {
    activityType: {
      acceptanceInformation: '0df75305-76cf-43b1-bc0c-7346200f8f89', // Polisacceptatie informatie ontvangen
      customerDataChecked: '8e8c3801-a29e-490f-9635-0ff374ace3ff', // Gegevens gecontroleerd
      policyApplication: '39e1a43d-e661-4757-8b3c-6f3d767b8ab2', // Aanvraag offerte
      receivedCalculation: '23e5634a-1c06-469d-9e20-bcda5c17df9c' // Calculatie ontvangen
    },
    addressType: {
      riskAddress: 'defefe2c-807b-42d1-99f5-03a5377f7f41'
    },
    taskStatus: {
      done: '2ee4d428-c867-44cd-8ce7-714afd44a4a3'
    }
  },
  socketConfig: {
    url: 'https://cmssocketapi.elonisas.nl',
    options: {
      query: 'site_url=https://cdp.scdp.elonisas.dev'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
