import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {SolarifToastrService} from './toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public authService: AuthService, public router: Router,
              private toastrService: SolarifToastrService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.canActivateProtectedRoutes$
      .pipe(tap(x => {
        if (x) {
          return true;
        }
        this.toastrService.error(
          $localize`:@@pleaseLogin:Please log in and try again.`,
          $localize`:@@accessDenied:Access denied.`
        );
        this.router.navigateByUrl('/');
        return false;
      }));
  }
}
