import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AccountManager} from "../../models/AccountManager";

@Injectable({
  providedIn: 'root'
})
export class AccountManagerService {

  private accountManagersLocalStore = new Map<string, any>();

  constructor(private httpClient: HttpClient) { }

  public accountManagers(): Observable<Array<AccountManager>> {
    const localData = this.accountManagersLocalStore.get('all');
    if (localData && localData.length > 0) {
      console.log('Getting local data for account managers', localData);
      return of(localData);
    }
    return this.httpClient.get<any>(environment.drupalUrl + '/api/account-managers?_format=json').pipe(
      map(results => {
        const mappedData = this.mappedAccountManagers(results);
        this.accountManagersLocalStore.set('all', mappedData);
        return mappedData;
      })
    );
  }

  public assignedAccountManagers(): Observable<Array<AccountManager>> {
    const localData = this.accountManagersLocalStore.get('assigned');
    if (localData && localData.length > 0) {
      return of(localData);
    }
    return this.httpClient.get<any>(environment.drupalUrl + '/api/account-managers/assigned').pipe(
      map(results => {
        const mappedData = this.mappedAccountManagers(results);
        this.accountManagersLocalStore.set('assigned', mappedData);
        return mappedData;
      })
    );
  }

  public accountManagerNames(): Observable<Array<string>> {
    return this.httpClient.get<Array<any>>(`${environment.drupalUrl}/api/account-managers`).pipe(
      map(data => {
        const names = [];
        data.forEach(manager => {
          names.push(manager.name[0].value);
        });
        return names.sort();
      })
    );
  }

  private mappedAccountManagers(results) {
    const accountManagers: Array<AccountManager> = [];
    results.forEach(result => {
      const accountManager: AccountManager = {
        uuid: result.uuid[0].value,
        username: result.name[0].value,
        email: result.mail[0] ? result.mail[0].value : '',
        fullName: result.field_full_name[0] ? result.field_full_name[0].value : ''
      };
      accountManagers.push(accountManager);
    });
    return accountManagers;
  }
}
