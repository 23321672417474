<ngx-loading-bar></ngx-loading-bar>
<nb-layout>
  <nb-layout-header fixed>
    <app-header></app-header>
  </nb-layout-header>

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
    <nb-menu [items]="items">
    </nb-menu>
    <div *ngIf="!environment.production" class="build-version">Version: {{ environment.version }}<br> Date: {{ environment.buildDate | date:'dd-MM-yyyy HH:mm:ss' }}</div>
  </nb-sidebar>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>

<!--  <nb-layout-footer fixed>-->
<!--    &lt;!&ndash; Insert footer here &ndash;&gt;-->
<!--  </nb-layout-footer>-->

</nb-layout>
