<div class="d-flex justify-content-between align-items-stretch">
  <table>
    <tr *ngIf="client.relationType.length > 0">
      <td><strong><span i18n="@@relationType">Relation type</span>:</strong></td>
      <td *ngFor="let relationType of client.relationType">{{ relationType.name }}</td>
    </tr>
    <tr *ngIf="client.clientType">
      <td><strong><span i18n="@@clientType">Client type</span>:</strong></td>
      <td>{{ client.clientType.name }}</td>
    </tr>
    <tr *ngIf="client.businessUnits">
      <td><strong><span i18n="@@businessUnit">Business unit</span>:</strong></td>
      <td>
        <span *ngFor="let businessUnit of client.businessUnits; let last = last;">{{ businessUnit.name }}<ng-container
            *ngIf="!last">, </ng-container></span>
      </td>
    </tr>
    <tr *ngIf="client.debtorNumber">
      <td><strong><span i18n="@@debtorNumber">Debtor number</span>:</strong></td>
      <td>{{ client.debtorNumber}}</td>
    </tr>
    <tr *ngIf="client.cocNumber">
      <td><strong><span i18n="@@cocNumber">COC number</span>:</strong></td>
      <td>{{ client.cocNumber }}</td>
    </tr>
    <tr *ngIf="client.legalForm && this.showLegalForm">
      <td><strong><span i18n="@@legalForm">Legal form</span>:</strong></td>
      <td>{{ client.legalForm.name}}</td>
    </tr>
    <tr *ngIf="client.branch">
      <td><strong><span i18n="@@branch">Branch</span>:</strong></td>
      <td>
        <span *ngFor="let branch of client.branch; let last = last;">{{ branch.name }}
          <ng-container *ngIf="!last">, </ng-container>
        </span>
      </td>
    </tr>
    <tr *ngIf="client.atp">
      <td><strong>ATP:</strong></td>
      <td><a href="javascript:void(0);" (click)="openAtpDialog()">{{ client.atp.title }}</a></td>
    </tr>
    <tr *ngIf="client.status">
      <td><strong><span i18n="@@status">Status</span>:</strong></td>
      <td class="text-capitalize">{{ client.status }}</td>
    </tr>
    <tr *ngIf="client.hierarchy && client.hierarchy.parents.length > 0">
      <td><strong><span i18n="@@parentClients">Parent clients</span>:</strong></td>
      <td>
        <ul class="hierarchy-list">
          <li *ngFor="let parent of client.hierarchy.parents">
            <a [routerLink]="['/client', parent.id]">{{ parent.title }}</a>
          </li>
        </ul>
      </td>
    </tr>
    <tr *ngIf="client.hierarchy && client.hierarchy.children.length > 0">
      <td><strong><span i18n="@@childClients">Child clients</span>:</strong></td>
      <td>
        <ul class="hierarchy-list">
          <li *ngFor="let child of client.hierarchy.children">
            <a href="/client/{{child.id}}">{{ child.title }}</a>
          </li>
        </ul>
      </td>
    </tr>
  </table>
  <div class="d-flex flex-column justify-content-between">
    <button class="btn btn-light btn-sm" [disabled]="currentLock === 1" (click)="openEditDialog(editDialog)">
      <nb-icon icon="edit-outline"></nb-icon>
    </button>
    <button class="btn btn-light btn-sm" (click)="openOrganizationChartDialog(organizationChartDialog)">
      <fa-icon [icon]="faSitemap"></fa-icon>
    </button>
  </div>
</div>

<ng-template #organizationChartDialog let-ref="organizationChartDialogRef">
  <app-client-organization-chart [client]="client"></app-client-organization-chart>
</ng-template>

<ng-template #editDialog let-ref="dialogRef">
  <form [formGroup]="generalInfoForm" class="dialog-form" novalidate (ngSubmit)="onSubmit(ref)">
    <nb-card>
      <nb-card-header i18n="@@clientTypeHeader">Edit general info</nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-4">
            <div class="form-item">
              <label class="label" for="relationType_select" i18n="@@relationType">Relation type</label>
              <nb-select placeholder="Select relation type" *ngIf="relationTypeTerms" id="relationType_select"
                formControlName="relationType" fullWidth i18n-placeholder="@@selectRelationTypePlaceholder">
                <nb-option *ngFor="let relationTypeTerm of relationTypeTerms" [value]="relationTypeTerm.id">
                  {{relationTypeTerm.name}}</nb-option>
              </nb-select>
            </div>
            <div class="form-item">
              <label class="label" for="clientType_select" i18n="@@clientType">Client type</label>
              <nb-select placeholder="Select client type" *ngIf="relationTypeTerms" id="clientType_select"
                formControlName="clientType" fullWidth i18n-placeholder="@@selectClientTypePlaceholder">
                <nb-option *ngFor="let clientTypeTerm of clientTypeTerms" [value]="clientTypeTerm.id">
                  {{clientTypeTerm.name}}</nb-option>
              </nb-select>
            </div>
            <div class="form-item">
              <label class="label" for="business_unit_select" i18n="@@businessUnit">Business Units</label>
              <nb-select multiple placeholder="Select business unit" i18n-placeholder="@@selectBusinessUnitPlaceholder"
                id="business_unit_select" *ngIf="businessUnitTerms" fullWidth formControlName="businessUnit">
                <nb-option [value]="businessUnit.id" *ngFor="let businessUnit of businessUnitTerms">
                  {{businessUnit.name}}</nb-option>
              </nb-select>
            </div>
            <div class="form-item" *ngIf="showLegalForm">
              <label for="legalForm_select" class="label" i18n="@@legalForm">Legal form</label>
              <nb-select placeholder="Select legal form" *ngIf="legalFormTerms" id="legalForm_select"
                formControlName="legalForm" fullWidth i18n-placeholder="@@selectLegalFormPlaceholder">
                <nb-option *ngFor="let legalFormTerm of legalFormTerms" [value]="legalFormTerm.id">{{ legalFormTerm.name
                  }}</nb-option>
              </nb-select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-item">
              <label class="label" for="debtorNumber_text" i18n="@@debtorNumber">Debtor number</label>
              <input nbInput id="debtorNumber" type="text" name="debtorNumber" id="debtorNumber_text"
                     placeholder="Debtor number" i18n-placeholder="@@debtorNumberPlaceholder" fullWidth
                     formControlName="debtorNumber" i18n-placeholder="@@debtorNumber">
            </div>
            <div class="form-item">
              <label class="label" for="cocNumber_text" i18n="@@cocNumber">COC number</label>
              <input nbInput id="cocNumber" type="text" name="cocNumber" id="cocNumber_text" placeholder="COC number"
                     i18n-placeholder="@@cocNumberPlaceholder" fullWidth formControlName="cocNumber"
                     i18n-placeholder="@@cocNumber">
            </div>
            <div class="form-item">
              <label class="label" for="branch_select" i18n="@@branch">Branch</label>
              <nb-select multiple placeholder="Select branch" i18n-placeholder="@@selectBranchPlaceholder"
                         id="branch_select" *ngIf="branchTerms" fullWidth formControlName="branch">
                <nb-option [value]="branch.id" *ngFor="let branch of branchTerms">{{branch.name}}</nb-option>
              </nb-select>
            </div>
            <div class="form-item">
              <label class="label" for="status_radio" i18n="@@status">Status</label>
              <nb-radio-group formControlName="status" id="status_radio">
                <nb-radio value="active" i18n="@@statusActive">Active</nb-radio>
                <nb-radio value="inactive" i18n="@@statusInactive">Inactive</nb-radio>
              </nb-radio-group>
            </div>
          </div>
          <div class="col-4">
            <div class="form-item">
              <label class="label" for="parent_select" i18n="@@parentClient">Parent client</label>
              <input id="parent_select" nbInput fullWidth (keyup)="onSearchParentClientChange()"
                (blur)="onSearchParentClientBlur()" formControlName="parentClient" type="text" i18n-placeholder="@@searchParentClient" placeholder="Search parent" />
            </div>
            <ul class="autocomplete"
              *ngIf="showParentClientOptions && !isLoadingParentClientOptions && parentClientOptions && parentClientOptions.length">
              <li *ngFor="let option of parentClientOptions" (mousedown)="onSelectParentClient(option, $event)">
                {{ option.title }}
              </li>
            </ul>
            <div class="loading" *ngIf="showParentClientOptions && isLoadingParentClientOptions" nbSpinner="true"
              nbSpinnerStatus="primary" nbSpinnerSize="tiny">
            </div>

          </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="text-right form-actions">
          <input type="button" nbButton ghost status="primary" (click)="cancelEdit(ref)" value="Cancel"
            i18n-value="@@cancel">
          <button nbButton status="primary" type="submit" [disabled]="generalInfoForm.invalid"
            i18n="@@submit">Submit</button>
        </div>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
