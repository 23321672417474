import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, EMPTY, Observable, of, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Task, TaskFilters, TaskPatch} from '../../models/task';
import {AuthService} from '../auth.service';
import {expand, map, reduce} from 'rxjs/operators';
import {ClientService} from '../client/client.service';
import {Client} from '../../models/client/client';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json'
    })
  };

  private identityClaims;

  private tasksChangedSource = new Subject();
  tasksChanged$ = this.tasksChangedSource.asObservable();

  private taskFiltersChangedSource = new BehaviorSubject<TaskFilters>({});
  taskFiltersChanged$ = this.taskFiltersChangedSource.asObservable();

  constructor(private httpClient: HttpClient,
              private authService: AuthService,
              private clientService: ClientService) {
    this.identityClaims = this.authService.identityClaims;
  }

  public reloadTasks() {
    this.tasksChangedSource.next();
  }

  getTasks(client: Client = null): Observable<Array<Task>> {

    const $getTasks = (apiUrl) => {
      return this.httpClient.get<any>(apiUrl).pipe(
        expand(response => {
          if (response.links && response.links.next) {
            return response.links?.next ? this.httpClient.get<any>(response.links.next.href) : EMPTY;
          }
          return EMPTY;
        }),
        reduce((accData: any, data: any) => {
          if (accData.length === 0) {
            accData = data;
          } else {
            accData.data = [...accData.data, ...data.data];
            if (accData.included && data.included) {
              accData.included = [...accData.included, ...data.included];
            } else if (!accData.included && data.included) {
              accData.included = data.included;
            }
          }
          if (accData) {
            return accData;
          }
        }, []),
        map(response => {
          const mapped: Array<Task> = [];
          response.data.forEach(responseItem => {
            mapped.push(this.mapTask(responseItem, response.included));
          });
          return mapped;

        })
      );
    };

    let url = `${environment.drupalUrl}/jsonapi/activity/task?include=field_activity_ref_business_unit,field_client,field_ref_task_status,field_ref_task_type&sort=field_datetime`;

    if (client) {
      url += `&filter[field_client.id]=${client.id}`;
      console.log(`Get tasks for client "${client.title}"`);
    }

    console.log('Get tasks url', url);

    return $getTasks(url);
  }

  getFilteredTasks() {
    return this.getTasks()
      .pipe(
        map(tasks => {
          return tasks.filter(task => this.doesTaskPassFilter(task, this.taskFiltersChangedSource.getValue()));
        })
      );
  }

  createTask(task: TaskPatch) {
    console.log('Creating task...');
    const body: any = this.buildBody(task);

    // console.log('body', body);
    const url = environment.drupalUrl + '/jsonapi/activity/task';
    // console.log('To url', url);
    return this.httpClient.post(url, body, this.httpOptions);
  }

  updateTask(taskUuid: string, task: TaskPatch) {
    console.log('Updating task...');
    const body: any = this.buildBody(task, taskUuid);

    console.log('body', body);

    return this.httpClient.patch(environment.drupalUrl + '/jsonapi/activity/task/' + taskUuid, body, this.httpOptions);
  }

  deleteTask(taskUuid: string) {
    console.log('Deleting task...');
    return this.httpClient.delete(environment.drupalUrl + '/jsonapi/activity/task/' + taskUuid);
  }

  setFilter(filters: TaskFilters) {
    if (!filters) {
      filters = {};
    }
    console.log('TaskService setFilter', filters);
    this.taskFiltersChangedSource.next(filters);
  }

  getCurrentFilter(): TaskFilters {
    return this.taskFiltersChangedSource.getValue();
  }


  doesTaskPassFilter(task: Task, filters: TaskFilters) {

    if (filters.accountManager) {
      let accountManagerPassed = false;
      if (task.assignee === filters.accountManager) {
        accountManagerPassed = true;
      } else if (filters.accountManager === '_unassigned' && task.assignee === null) {
        accountManagerPassed = true;
      }
      if (!accountManagerPassed) {
        return false;
      }
    }

    if (filters.businessUnit) {
      if (!task.businessUnitIds.includes(filters.businessUnit)) {
        return false;
      }
    }

    if (filters.expireDate && filters.expireDate.from) {
      const taskExpireDate = new Date(task.expireDate);
      if (taskExpireDate <= filters.expireDate.from) {
        return false;
      }
    }

    if (filters.expireDate && filters.expireDate.until) {
      const taskExpireDate = new Date(task.expireDate);
      const dateUntilToCompare = new Date(filters.expireDate.until); // Get a new date, so we can add +1 day
      dateUntilToCompare.setDate(dateUntilToCompare.getDate() + 1);
      if (taskExpireDate >= dateUntilToCompare) {
        return false;
      }
    }

    if (filters.statuses) {
      let statusPassed = false;
      filters.statuses.forEach(status => {
        if (status === task.statusId) {
          statusPassed = true;
        }
      });
      if (!statusPassed) {
        return false;
      }
    }

    if (filters.textSearch) {
      let textSearchPassed = false;
      if (task.longDescription.includes(filters.textSearch) || (task.memo && task.memo.includes(filters.textSearch))) {
        textSearchPassed = true;
      }
      if (!textSearchPassed) {
        return false;
      }
    }

    if (filters.types) {
      let typesPassed = false;
      filters.types.forEach(type => {
        if (type === task.typeId) {
          typesPassed = true;
        }
      });
      if (!typesPassed) {
        return false;
      }
    }

    return true; // Passed all tests
  }

  private buildBody(task: TaskPatch, taskUuid?: string): any {
    const body: any = {
      data: {
        type: 'activity--task',
        attributes: {
          field_long_description: task.description,
          field_memo: task.memo,
          title: task.title,
          field_datetime: {
            value: task.expireDate
          },
          field_polis: task.policy,
          field_dossier: task.dossier
        },
        relationships: {
          field_ref_task_type: {
            data: {
              type: 'taxonomy_term--task_type',
              id: task.typeId
            }
          },
          field_ref_task_status: {
            data: {
              type: 'taxonomy_term--task_status',
              id: task.statusId
            }
          }
        }
      }
    };

    if (taskUuid) {
      body.data.id = taskUuid;
    }

    if (task.assignee) {
      body.data.relationships.field_assignee = {
        data: {
          type: 'user--user',
          id: task.assignee
        }
      };
    }

    if (task.clientId) {
      body.data.relationships.field_client = {
        data: {
          type: 'group--relation',
          id: task.clientId
        }
      };
    }

    if (task.businessUnitIds) {
      console.log('task.businessUnitIds', task.businessUnitIds);
      body.data.relationships.field_activity_ref_business_unit = {
        data: task.businessUnitIds.map(id => ({
          type: 'taxonomy_term--business_unit',
          id
        }))
      };
    }

    return body;
  }

  private mapTask(responseItem: any, included?: any): Task {
    const task: Task = {
      assignee: null,
      businessUnitIds: [],
      clientId: undefined,
      dossier: responseItem.attributes.field_dossier,
      dateCreated: responseItem.attributes.created,
      expireDate: responseItem.attributes.field_datetime,
      dateFinished: responseItem.attributes.field_date_finished,
      id: responseItem.attributes.drupal_internal__id,
      longDescription: (responseItem.attributes.field_long_description) ? responseItem.attributes.field_long_description.value : null,
      memo: (responseItem.attributes.field_memo) ? responseItem.attributes.field_memo.value : null,
      // short_description: responseItem.attributes.field_short_description.processed ?? null,
      policy: responseItem.attributes.field_polis,
      statusId: undefined,
      title: responseItem.attributes.title,
      typeId: undefined,
      uuid: responseItem.id
    };

    if (responseItem.relationships.field_client && responseItem.relationships.field_client.data) {
      task.clientId = responseItem.relationships.field_client.data.id;
    }

    const businessUnits = [];
    if (responseItem.relationships.field_activity_ref_business_unit && responseItem.relationships.field_activity_ref_business_unit.data) {
      responseItem.relationships.field_activity_ref_business_unit.data.forEach(item => businessUnits.push(item.id));
    }
    task.businessUnitIds = businessUnits;

    if (responseItem.relationships.field_ref_task_status && responseItem.relationships.field_ref_task_status.data) {
      task.statusId = responseItem.relationships.field_ref_task_status.data.id;
    }

    if (responseItem.relationships.field_ref_task_type && responseItem.relationships.field_ref_task_type.data) {
      task.typeId = responseItem.relationships.field_ref_task_type.data.id;
    }

    if (responseItem.relationships.field_assignee && responseItem.relationships.field_assignee.data) {
      task.assignee = responseItem.relationships.field_assignee.data.id;
    }

    if (included) {
      included.forEach(inclusion => {
        switch (inclusion.type) {
          case 'group--relation':
            if (inclusion.id === task.clientId) {
              task.client = this.clientService.mapClientResource(inclusion);
            }
            break;
        }
      });
    }

    // console.log('Mapped task', task);
    return task;
  }
}
