<nb-card>
  <nb-card-header class="d-flex justify-content-between align-items-baseline" i18n="@@primaryContactHeader">Primary contact</nb-card-header>
  <div class="d-flex flex-column" *ngIf="contacts !== null">
    <!--    <nb-list-item >-->
    <app-client-contact-list-item *ngFor="let contact of contacts" [contact]="contact"
                                  [client]="client"
                                  [departmentTerms]="departmentTerms" [languageTerms]="languageTerms"
                                  [contactTypeTerms]="contactTypeTerms"></app-client-contact-list-item>
    <!--    </nb-list-item>-->
  </div>
  <div class="d-flex flex-column" *ngIf="contacts == null">
    <app-client-contact-list-item></app-client-contact-list-item>
    <app-client-contact-list-item></app-client-contact-list-item>
    <app-client-contact-list-item></app-client-contact-list-item>
  </div>
</nb-card>
