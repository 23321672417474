import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {ActivityService} from '../../../services/activity/activity.service';
import {TaxonomyService} from 'src/app/services/taxonomy/taxonomy.service';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {ClientActivityDialogComponent} from './client-activity-dialog/client-activity-dialog.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {Activity} from '../../../models/activity/activity';
import {Client} from '../../../models/client/client';
import {patterns} from '../../../validators/patterns';
import {ActivityTaxonomyTerm} from '../../../models/terms/taxonomyTerm';

@Component({
  selector: 'app-client-activities',
  templateUrl: './client-activities.component.html',
  styleUrls: ['./client-activities.component.scss']
})
export class ClientActivitiesComponent implements OnInit, OnDestroy, OnChanges {

  @Input() openActivityId?: string;
  @Input() activities: Array<Activity>;
  @Input() client: Client;

  filterIcons = new Map();
  filtersModel = [];
  businessUnitFilterModel = null;
  filteredActivities: Array<Activity> | void;
  console = console;
  activityTypeTerms: Array<ActivityTaxonomyTerm>;
  businessUnitTerms = [];
  allowedActivityTypeTerms: Array<ActivityTaxonomyTerm>;

  constructor(private dialogService: NbDialogService,
              private toastrService: SolarifToastrService,
              private activityService: ActivityService,
              private taxonomyService: TaxonomyService) {
  }

  ngOnInit() {
    this.getBusinessUnitTerms();
    this.getActivityTypeTerms();
    this.createActivityFilters();
    this.filteredActivities = this.activities;

    this.allowedActivityTypeTerms = this.activityTypeTerms.filter(activityTerm => {
      return activityTerm.userCanCreate;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.activities;
    this.activities = currentItem.currentValue;
    this.filteredActivities = this.activities;
    this.createActivityFilters();
    this.filterChange();
  }

  createActivityFilters() {
    const self = this;
    this.activities.forEach(activity => {
      const activityTypeId = activity.type_id;
      if (!(activityTypeId in this.filtersModel) && activity.icon !== null) {
        self.filtersModel['id-' + activityTypeId] = true;
        self.filterIcons.set('id-' + activityTypeId, activity.icon);
      }
    });
  }

  ngOnDestroy() {
    /*if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }*/
  }

  private getBusinessUnitTerms() {
    this.taxonomyService.getTermsByVocabulary('business_unit').subscribe(data => {
      this.businessUnitTerms = data;
      this.businessUnitFilterModel = null;
    });
  }

  private getActivityTypeTerms() {
    this.activityTypeTerms = this.activityService.activityTypeTerms;
  }

  setBusinessUnitFilter(businessUnit) {
    if (this.businessUnitFilterModel === businessUnit.termId) {
      this.businessUnitFilterModel = null;
    } else {
      this.businessUnitFilterModel = businessUnit.termId;
    }
    this.filterChange();
  }

  filterChange() {
    const self = this;
    this.filteredActivities = this.activities.filter(activity => {
      if (!self.filtersModel['id-' + activity.type_id]) {
        return false;
      }
      if ((activity.business_unit_ids || []).length === 0) {
        return false;
      }
      if (this.businessUnitFilterModel === null) {
        return true;
      } else {
        for (const businessUnitID of activity.business_unit_ids) {
          if (this.businessUnitFilterModel.toString() === businessUnitID) {
            return true;
          }
        }
      }
      return false;
    });
  }

  openAddDialog() {
    this.dialogService.open(ClientActivityDialogComponent, {
      context: {
        client: this.client,
        businessUnitTerms: this.businessUnitTerms,
        allowedActivityTypeTerms: this.allowedActivityTypeTerms,
        actionType: 'add'
      },
    });
  }
}
