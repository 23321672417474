<app-client-checking-alert [client]="client" *ngIf="client.isMarkedForChecking"></app-client-checking-alert>

<div class="top-row d-flex justify-content-between align-items-baseline">

  <div class="title-container d-flex flex-row align-items-center">

    <ng-container *ngIf="mode == 'view'">
      <h1 id="client-title">{{ client.title }}</h1>
    </ng-container>
    <ng-container *ngIf="mode == 'edit'">
      <form [formGroup]="clientForm" novalidate (ngSubmit)="onSubmit()">
        <div class="form-item">
          <input id="client--title--edit" nbInput fullWidth formControlName="title" placeholder="Name"
                 i18n-placeholder="@@clientTitlePlaceholder">
        </div>
      </form>
    </ng-container>

    <div class="edit-actions_title">
      <button *ngIf="mode == 'view'" class="btn btn-light btn-sm d-inline" [disabled]="currentLock === 1" (click)="setMode()">
        <nb-icon icon="edit-outline"></nb-icon>
      </button>
      <button *ngIf="mode == 'edit'" class="btn btn-light btn-sm d-inline" (click)="setMode()">
        <nb-icon icon="close-outline"></nb-icon>
      </button>
    </div>

  </div>

</div>

<div class="row">

  <div class="col-12 col-lg-8">
    <nb-card>
      <nb-card-body>
        <nb-tabset>
          <nb-tab tabTitle="Base information" i18n-tabTitle="@@baseInformationTabTitle">
            <app-client-general-info [client]="client" (changed)="onGeneralInfoChanged()"></app-client-general-info>
          </nb-tab>
          <nb-tab tabTitle="Contact information" *ngIf="client.contactInfo"
                  i18n-tabTitle="@@clientContactInformation">
            <app-client-contact-info [contactInfo]="client.contactInfo"
                                     [clientId]="client.id"></app-client-contact-info>
          </nb-tab>
          <nb-tab tabTitle="Addresses" *ngIf="client.addresses" i18n-tabTitle="@@addressesTabTitle">
            <app-client-addresses [client]="client" [addresses]="client.addresses"></app-client-addresses>
          </nb-tab>
        </nb-tabset>
      </nb-card-body>
    </nb-card>
    <app-client-policy-contracts [clientId]="client.id"></app-client-policy-contracts>
    <nb-card>
        <nb-tabset class="client-activities-tasks-tabset">
          <nb-tab tabTitle="Activities" i18n-tabTitle="@@activities">
            <div class="loading--activities"
                 *ngIf="isLoadingActivities; else finishedLoadingActivities"
                 nbSpinner="true"
                 nbSpinnerStatus="primary"
                 nbSpinnerSize="tiny">
            </div>
            <ng-template #finishedLoadingActivities>
              <app-client-activities [activities]="activities"
                                     [client]="client"
                                     [openActivityId]="openActivityId"
                                     *ngIf="activities">
              </app-client-activities>
            </ng-template>
          </nb-tab>
          <nb-tab tabTitle="Tasks" i18n-tabTitle="@@tasks">
            <div class="task--actions">
              <nb-toggle [(checked)]="showTaskFilters"

                         i18n="@@showFilters"
              >Show filters</nb-toggle>
              <button class="btn btn-light btn-sm"
                      i18n-title="@@addTask"
                      title="Add task"
                      (click)="openAddTaskDialog()"
              >
                <nb-icon icon="plus-outline"></nb-icon>
              </button>
            </div>
            <app-task-filter *ngIf="showTaskFilters"></app-task-filter>
            <app-task-list [client]="client"></app-task-list>
          </nb-tab>
        </nb-tabset>
    </nb-card>

  </div>

  <div class="col-12 col-lg-4">
    <app-client-account-manager [client]="client"></app-client-account-manager>
    <app-client-contacts-primary-list *ngIf="client.contacts && client.contacts.primary.length > 0"
                                      [contacts]="client.contacts.primary"
                                      [client]="client"
    ></app-client-contacts-primary-list>
    <app-client-contacts-list [contacts]="client.contacts.normal"
                              [client]="client"
    ></app-client-contacts-list>
    <app-client-memo [client]="client"></app-client-memo>
  </div>
</div>
