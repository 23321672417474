<h3 i18n="@@calculation">Calculation</h3>
<table>
  <tr>
    <td><span i18n="@@productId" class="label">Product ID:</span></td>
    <td><span class="small">{{this.calculation.product_id}}</span></td>
  </tr>
</table>
<br>
<div class="row">
  <div class="col-6">
    <h4 i18n="@@components">Components</h4>
    <table class="table__components" *ngFor="let component of this.calculation.components | keyvalue: keepOriginalOrder; let i = index">
      <tr>
        <td><strong class="small">Component {{i+1}}</strong></td>
        <td><span class="label">Premium</span></td>
        <td><span class="small">{{component.value.premium}}</span></td>
      </tr>
      <tr>
        <td></td>
        <td><span class="label">Tax</span></td>
        <td><span class="small">{{component.value.tax}}</span></td>
      </tr>
    </table>
  </div>
  <div class="col-6">
    <h4>Totals</h4>
    <table class="table__totals">
      <tr>
        <td><span class="label">Costs</span></td>
        <td><span class="small">{{this.calculation.totals.costs}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Drawup costs</span></td>
        <td><span class="small">{{this.calculation.totals.drawup_costs}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Payment Term</span></td>
        <td><span class="small">{{this.calculation.totals.payment_term}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Payment terms</span></td>
        <td><small *ngFor="let payment_term of this.calculation.totals.payment_terms">{{payment_term}}</small></td>
      </tr>
      <tr>
        <td><span class="label">Percentage taxable premium</span></td>
        <td><span class="small">{{this.calculation.totals.percentage_taxable_premium}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Taxrate over premium</span></td>
        <td><span class="small">{{this.calculation.totals.taxrate_over_premium}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Premium</span></td>
        <td><span class="small">{{this.calculation.totals.premium}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Tax over costs</span></td>
        <td><span class="small">{{this.calculation.totals.tax_over_costs}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Tax over drawup costs</span></td>
        <td><span class="small">{{this.calculation.totals.tax_over_drawup_costs}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Tax over premium</span></td>
        <td><span class="small">{{this.calculation.totals.tax_over_premium}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Total price</span></td>
        <td><span class="small">{{this.calculation.totals.total_price}}</span></td>
      </tr>
      <tr>
        <td><span class="label">Total tax</span></td>
        <td><span class="small">{{this.calculation.totals.total_tax}}</span></td>
      </tr>
    </table>
  </div>
</div>
