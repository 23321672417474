<div class="task-filter">
  <div class="filter--row">
    <div class="form-item business-units full-width">
      <button
        *ngFor="let businessUnit of businessUnitTerms"
        (click)="setBusinessUnitFilter(businessUnit)"
        size="small"
        [status]="this.filters.businessUnit === businessUnit.id ? 'primary' : 'basic'"
        nbButton>{{businessUnit.name}}</button>
    </div>
  </div>
  <div class="filter--row">
    <div class="form-item status">
      <label for="status" class="label small" i18n="@@status">Status</label>
      <nb-select id="status"
                 [(selected)]="selectedStatus"
                 (selectedChange)="onStatusChange()"
                 placeholder="Select status"
                 multiple
                 i18n-placeholder="@@statusPlaceholder"
      >
        <nb-option *ngFor="let status of statusTerms" [value]="status.id">
          <span class="status--name">{{status.name}}</span>
        </nb-option>
      </nb-select>
    </div>
    <div class="form-item types">
      <label for="types" class="label small" i18n="@@taskTypes">Types</label>
      <nb-select id="types"
                 [(selected)]="selectedTypes"
                 (selectedChange)="onTypeChange()"
                 placeholder="Select task type"
                 multiple
                 i18n-placeholder="@@taskTypePlaceholder"
      >
        <nb-option *ngFor="let type of taskTypeTerms" [value]="type.id">
          <nb-icon icon="{{ type.icon }}" title="{{type.name}}"></nb-icon>
          <span class="type--name">{{type.name}}</span>
        </nb-option>
      </nb-select>
    </div>
    <div class="form-item employee">
      <label for="assignee" class="label small" i18n="@@employee">Employee</label>
      <nb-select id="assignee"
                 [(selected)]="selectedAccountManager"
                 (selectedChange)="onAccountManagerChange()"
                 placeholder="Select employee"
                 i18n-placeholder="@@taskEmployeePlaceholder"
      >
        <nb-option value="_all">
          <span i18n="@@all">All</span>
        </nb-option>
        <nb-option value="_unassigned">
          <span i18n="@@unassigned">Unassigned</span>
        </nb-option>
        <nb-option *ngFor="let accountManager of accountManagers" [value]="accountManager.uuid">
          {{ (accountManager.fullName) ? accountManager.fullName : accountManager.username }}
        </nb-option>
      </nb-select>
    </div>
  </div>
  <div class="filter--row">
    <div class="form-item expire-date-from">
      <label for="taskExpireDateFrom" class="label small" i18n="@@taskExpireDateFrom">Expire date from</label>
      <input nbInput
             fullWidth
             id="taskExpireDateFrom"
             [owlDateTime]="dt1"
             [owlDateTimeTrigger]="dt1"
             placeholder="Expire date from"
             [(ngModel)]="expireDateFrom"
             (dateTimeChange)="onExpireDateFromChange()"
             i18n-placeholder="@@taskFilterExpireDateFromPlaceholder"
      >
      <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
    </div>
    <div class="form-item expire-date-until">
      <label for="taskExpireDateUntil" class="label small" i18n="@@taskExpireDateUntil">Expire date until</label>
      <input nbInput
             fullWidth
             id="taskExpireDateUntil"
             [owlDateTime]="dt2"
             [owlDateTimeTrigger]="dt2"
             placeholder="Expire date until"
             [(ngModel)]="expireDateUntil"
             (dateTimeChange)="onExpireDateUntilChange()"
             i18n-placeholder="@@taskFilterExpireDateUntilPlaceholder"
      >
      <owl-date-time #dt2 [pickerType]="'calendar'"></owl-date-time>
    </div>
    <div class="form-item text-search">
      <label for="textSearch" class="label small" i18n="@@search">Search</label>
      <input nbInput
             name="textSearch"
             placeholder="Type at least 3 letters"
             i18n-placeholder="@@searchTask"
             [(ngModel)]="textSearch"
             id="textSearch"
             (keyup)="onTextSearch()"
      >
    </div>
  </div>
  <div class="actions">
    <button class="btn btn-light btn-sm d-inline"
            (click)="clearFilter()"
            i18n="@@clearFilter"
    >Clear filter</button>
  </div>
</div>
