<div class="d-flex flex-row align-items-center">
  <h1 i18n="@@clients">Clients</h1>
  <div class="add-client-actions">
    <button class="btn btn-light btn-sm d-inline" title="Add new client" i18n-title="@@addNewClientButton"
            (click)="openAddDialog(addDialog)" *ngIf="canAddCompany">
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
  </div>
</div>
<nb-card>
  <nb-card-body>
    <form [formGroup]="filterForm" class="custom-dt-filters" (ngSubmit)="onFilterSubmit()">
      <div class="filters">
        <div class="filter-group">
          <div class="filter-group-title">
            <strong i18n="@@filterTitleClient">Client filters</strong>
          </div>
          <div class="filter">
            <label for="titleSearch" class="label small" i18n="@@title">Title</label>
            <input nbInput name="titleSearch" id="titleSearch" formControlName="title">
          </div>
          <div class="filter">
            <label for="businessUnit" class="label small" i18n="@@businessUnit">Business unit</label>
            <nb-select id="businessUnit" name="businessUnit" formControlName="businessUnit">
              <nb-option value="" selected i18n="@@filterAllBusinessUnits">All units</nb-option>
              <nb-option *ngFor="let businessUnit of businessUnitTerms" value="{{ businessUnit.termId }}">
                {{businessUnit.name}}
              </nb-option>
            </nb-select>
          </div>
          <div class="filter">
            <label for="relationType" class="label small" i18n="@@relationType">Relation type</label>
            <nb-select id="relationType" name="relationType" formControlName="relationType">
              <nb-option value="" selected i18n="@@filterAllRelationTypes">All types</nb-option>
              <nb-option *ngFor="let relationType of relationTypeTerms" value="{{ relationType.termId }}">
                {{relationType.name}}
              </nb-option>
            </nb-select>
          </div>
          <div class="filter">
            <label for="clientType" class="label small" i18n="@@clientType">Client type</label>
            <nb-select id="clientType" name="clientType" formControlName="clientType">
              <nb-option value="" selected i18n="@@filterAllClientTypes">All types</nb-option>
              <nb-option *ngFor="let clientType of clientTypeTerms" value="{{ clientType.termId }}">
                {{clientType.name}}
              </nb-option>
            </nb-select>
          </div>
          <div class="filter">
            <label for="status" class="label small" i18n="@@status">Status</label>
            <nb-select id="status" name="status" formControlName="status">
              <nb-option value="" selected i18n="@@filterStatusAll">All</nb-option>
              <nb-option value="active" i18n="@@statusActive">Active</nb-option>
              <nb-option value="inactive" i18n="@@statusInactive">Inactive</nb-option>
            </nb-select>
          </div>
          <div class="filter">
            <label for="markedForChecking" class="label small" i18n="@@markedForChecking">Marked for checking</label>
            <nb-select id="markedForChecking" name="markedForChecking" formControlName="markedForChecking">
              <nb-option value="" selected i18n="@@filterMarkedForCheckingAll">All</nb-option>
              <nb-option value="true" i18n="@@markedForCheckingYes">Yes</nb-option>
              <nb-option value="false" i18n="@@markedForCheckingNo">No</nb-option>
            </nb-select>
          </div>
          <div class="filter">
            <label for="accountManager" class="label small" i18n="@@accountManager">Account manager</label>
            <nb-select id="accountManager" name="accountManager" formControlName="accountManager">
              <nb-option value="" selected i18n="@@filterAccountManagerAll">All</nb-option>
              <nb-option value="__none__" selected i18n="@@filterAccountManagerNone">None</nb-option>
              <nb-option *ngFor="let manager of accountManagerNames" value="{{manager}}">{{manager}}</nb-option>
            </nb-select>
          </div>
        </div>
        <div class="filter-group">
          <div class="filter-group-title">
            <strong i18n="@@filterTitleContact">Contact filters</strong>
          </div>
          <div class="filter">
            <label for="memberSearch" class="label small" i18n="@@search">Search</label>
            <input nbInput name="memberSearch" id="memberSearch" formControlName="memberSearch">
          </div>
        </div>
      </div>
      <div class="actions">
        <button nbButton type="submit" class="submit" i18n="@@filter">Filter</button>
        <button nbButton type="button" status="basic" i18n="@@export" (click)="onExportClick()">Export</button>
      </div>
    </form>
    <table datatable [dtOptions]="dtOptions" class="row-border hover">
    </table>
  </nb-card-body>
</nb-card>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header i18n="@@deleteClient">Delete client</nb-card-header>
    <nb-card-body i18n="@@deleteClientConfirm">Are you sure you want to delete the selected client? This action <em>cannot</em>
      be undone!
    </nb-card-body>
    <nb-card-footer>
      <!--      <button nbButton (click)="cancelDeleteNode(ref)">Cancel</button>-->
      <!--      <button nbButton status="danger" (click)="deleteNode(ref)">Confirm</button>-->
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #addDialog let-ref="dialogRef">
  <form [formGroup]="addForm" novalidate (ngSubmit)="onAddSubmit(ref)">
    <nb-card class="address edit">
      <nb-card-header i18n="@@addClientHeader">Add new client</nb-card-header>
      <nb-card-body>
        <div class="form-item">
          <label class="label" i18n="@@clientTitleLabel" for="clientTitle">Name</label>
          <input nbInput fullWidth formControlName="title" id="clientTitle" placeholder="Title"
                 i18n-placeholder="@@clientTitlePlaceholder">
        </div>
        <div class="form-item">
          <label class="label" for="clientType_select" i18n="@@clientType">Client type</label>
          <nb-select placeholder="Select client type" *ngIf="clientTypeTerms" id="clientType_select"
                     formControlName="clientType" fullWidth i18n-placeholder="@@selectClientTypePlaceholder">
            <nb-option *ngFor="let clientTypeTerm of clientTypeTerms"
                       [value]="clientTypeTerm.id">{{clientTypeTerm.name}}</nb-option>
          </nb-select>
        </div>
        <div class="form-item">
          <label class="label" for="relationType_select" i18n="@@relationType">Relation type</label>
          <nb-select placeholder="Select relation type" *ngIf="relationTypeTerms" id="relationType_select"
                     formControlName="relationType" fullWidth i18n-placeholder="@@selectRelationTypePlaceholder">
            <nb-option *ngFor="let relationTypeTerm of relationTypeTerms"
                       [value]="relationTypeTerm.id">{{relationTypeTerm.name}}</nb-option>
          </nb-select>
        </div>
        <div class="form-item">
          <label class="label" for="businessUnit_select" i18n="@@businessUnit">Business unit</label>
          <nb-select placeholder="Select business unit" *ngIf="businessUnitTerms" id="businessUnit_select"
                     formControlName="businessUnit" fullWidth i18n-placeholder="@@selectBusinessUnitPlaceholder">
            <nb-option *ngFor="let businessUnitTerm of businessUnitTerms"
                       [value]="businessUnitTerm.id">{{businessUnitTerm.name}}</nb-option>
          </nb-select>
        </div>
        <div class="form-item">
          <label class="label" for="account_manager_select" i18n="@@accountManager">Account manager</label>
          <nb-select placeholder="Select account manager" *ngIf="allAccountManagers" id="account_manager_select"
                     formControlName="accountManager" fullWidth i18n-placeholder="@@selectAccountManagerPlaceholder">
            <nb-option *ngFor="let accountManager of allAccountManagers"
                       [value]="accountManager.uuid">
              <div *ngIf="accountManager.fullName else noFullName" class="user-name">{{ accountManager.fullName }}</div>
              <ng-template #noFullName><div *ngIf="accountManager.username" class="user-name">{{ accountManager.username }}</div></ng-template>
            </nb-option>
          </nb-select>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="form-actions text-right">
          <input type="button" nbButton ghost status="primary" (click)="cancelAdd(ref)" value="Cancel"
                 i18n-value="@@cancel">
          <button nbButton status="primary" type="submit" [disabled]="addForm.invalid" i18n="@@submit">Submit
          </button>
        </div>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
