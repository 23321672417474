import {Component, Input, OnInit} from '@angular/core';
import {Client} from '../../../models/client/client';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Task, TaskPatch} from '../../../models/task';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {TaxonomyService} from '../../../services/taxonomy/taxonomy.service';
import {TaskService} from '../../../services/task/task.service';
import {AccountManagerService} from '../../../services/account-manager/account-manager.service';
import {AccountManager} from '../../../models/AccountManager';
import {BehaviorSubject, of} from 'rxjs';
import {flatMap, map, tap} from 'rxjs/operators';
import {ClientService} from '../../../services/client/client.service';
import {environment} from '../../../../environments/environment';
import {TaskConfirmDeleteComponent} from '../task-confirm-delete/task-confirm-delete.component';
import {DateTimeAdapter} from 'ng-pick-datetime';
import {AuthService} from '../../../services/auth.service';
import {UtilitiesService} from '../../../services/utilities/utilities.service';

@Component({
  selector: 'app-task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss']
})
export class TaskDialogComponent implements OnInit {

  @Input() task?: Task;
  @Input() actionType: 'add' | 'edit';
  @Input() client: Client;
  @Input() businessUnitTerms: Array<any>;
  @Input() accountManagers: Array<AccountManager>;
  @Input() taskTypeTerms: Array<any>;
  @Input() taskStatusTerms: Array<any>;

  loading = true;

  form: FormGroup;
  businessUnitFormGroup: FormGroup;
  isSaving: boolean;

  isLoadingClientOptions = false;
  showClientOptions = false;
  clientSearchTerm$ = new BehaviorSubject<string>('');
  clientOptions$ = this.clientSearchTerm$.pipe(
    tap(() => this.showClientOptions = true),
    tap(() => this.isLoadingClientOptions = true),
    flatMap(searchTerm => searchTerm.length >= 3 ? this.clientService.search(searchTerm) : of([])),
    map((x: any) => x ? x.slice(0, 8) : []),
    tap(() => this.isLoadingClientOptions = false),
  );
  clientOptions = [];

  private readonly identityClaims;

  constructor(protected ref: NbDialogRef<TaskDialogComponent>,
              private toastrService: SolarifToastrService,
              private dialogService: NbDialogService,
              private taskService: TaskService,
              private taxonomyService: TaxonomyService,
              private accountManagerService: AccountManagerService,
              private clientService: ClientService,
              private dateTimeAdapter: DateTimeAdapter<any>,
              private authService: AuthService) {
    dateTimeAdapter.setLocale('nl');
    this.identityClaims = this.authService.identityClaims;
  }

  ngOnInit() {
    if (!this.businessUnitTerms) {
      this.getBusinessUnitTerms();
    }
    if (!this.accountManagers) {
      this.getAccountManagers();
    }
    if (!this.taskTypeTerms) {
      this.getTaskTypeTerms();
    }
    if (!this.taskStatusTerms) {
      this.getTaskStatusTerms();
    }

    this.clientOptions$.subscribe(x => this.clientOptions = x);

    // this.createForm();
    this.checkLoading();
    this.isSaving = false;
  }

  private checkLoading() {
    this.loading = true;

    if (this.businessUnitTerms != null && this.accountManagers != null && this.taskStatusTerms != null && this.taskTypeTerms != null) {
      this.loading = false;
      this.createForm();
    }

  }

  private dataChanged() {
    this.checkLoading();
  }

  private createForm() {
    this.addBusinessUnitsToForm();

    this.form = new FormGroup({
      assignee: new FormControl('', []),
      client: new FormControl('', []),
      clientId: new FormControl(null, []),
      dossier: new FormControl('', []),
      expireDate: new FormControl(new Date(), Validators.required),
      description: new FormControl(null, Validators.required),
      memo: new FormControl(null, []),
      policy: new FormControl('', []),
      businessUnits: this.businessUnitFormGroup,
      title: new FormControl(null, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      type: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
    });

    if (this.client) {
      this.form.get('client').setValue(this.client.title);
      this.form.get('clientId').setValue(this.client.id);
    }

    if (this.identityClaims && this.identityClaims.uuid) {
      const accountManager = this.accountManagers.filter(manager => manager.uuid === this.identityClaims.uuid)[0];
      if (accountManager) {
        this.form.get('assignee').setValue(accountManager.uuid);
      }
    }

    if (this.task) {
      console.log('Dialog has task', this.task);
      this.form.get('assignee').setValue(this.task.assignee || '');
      this.form.get('expireDate').setValue(this.task.expireDate);
      this.form.get('description').setValue(this.task.longDescription);
      this.form.get('dossier').setValue(this.task.dossier);
      this.form.get('policy').setValue(this.task.policy);
      this.form.get('memo').setValue(this.task.memo);
      this.form.get('title').setValue(this.task.title);
      this.form.get('type').setValue(this.task.typeId);
      this.form.get('status').setValue(this.task.statusId);
      this.form.get('clientId').setValue(this.task.clientId);
      // Get client data
      if (this.task.clientId && !this.client) {
        this.clientService.client(this.task.clientId, null, null).subscribe(client => {
          this.form.get('client').setValue(client.title);
        });
      } else if (this.task.clientId && this.client) {
        this.form.get('client').setValue(this.client.title);
      }
      // Disable status field for done tasks
      if (this.task.statusId === environment.taxonomyTermIds.taskStatus.done && this.task.dateFinished) {
        this.form.controls['status'].disable();
      }
    }
  }

  onSearchClientChange() {
    const searchTerm = this.form.get('client').value;
    console.log('onSearchClientChange', searchTerm);
    this.clientSearchTerm$.next(searchTerm);
  }

  onSearchClientBlur() {
    if (this.showClientOptions) {
      this.form.controls['client'].setValue('');
      this.form.controls['clientId'].setValue(null);
      this.showClientOptions = false;
    }
  }

  onSelectClient(client, ev) {
    ev.stopPropagation();
    this.form.controls['client'].setValue(client.title);
    this.form.controls['clientId'].setValue(client.id);
    this.showClientOptions = false;
  }

  onSubmit() {
    this.isSaving = true;

    const businessUnitsFormValue = this.form.get('businessUnits').value;
    const businessUnitsChecked = [];
    for (const [key, value] of Object.entries(businessUnitsFormValue)) {
      if (value) {
        const termChecked = this.businessUnitTerms.filter(term => term.name === key)[0];
        if (termChecked) {
          businessUnitsChecked.push(termChecked);
        }
      }
    }
    const businessUnitIds = businessUnitsChecked.map(term => term.id);

    const data: TaskPatch = {
      assignee: this.form.get('assignee').value,
      clientId: this.form.get('clientId').value,
      expireDate: UtilitiesService.ISODateString(new Date(this.form.get('expireDate').value)),
      description: this.form.get('description').value,
      memo: this.form.get('memo').value,
      title: this.form.get('title').value,
      typeId: this.form.get('type').value,
      statusId: this.form.get('status').value,
      dossier: this.form.get('dossier').value,
      policy: this.form.get('policy').value,
      businessUnitIds
    };

    // if (this.client) {
    //   data.clientId = this.client.id;
    // }

    const req = this.task ? this.taskService.updateTask(this.task.uuid, data) :
      this.taskService.createTask(data);
    req.subscribe(result => {
      this.toastrService.success(
        $localize`:@@taskSaveSuccess:Task has been saved.`,
        $localize`:@@saved:Saved`
      );

      this.taskService.reloadTasks();

      this.ref.close();
      this.isSaving = false;
    }, error => {
      this.isSaving = false;
      this.toastrService.error(
        $localize`:@@taskAddFailure:Task could not be saved.`
      );
    });
  }

  confirmDelete() {
    this.ref.close();
    this.dialogService.open(TaskConfirmDeleteComponent, {
      context: {
        task: this.task
      },
    });
  }

  cancelDialog() {
    if (!this.isSaving) {
      this.ref.close();
    }
  }

  private addBusinessUnitsToForm() {
    const fb = new FormBuilder();
    this.businessUnitFormGroup = fb.group({});

    for (const businessUnitTerm of this.businessUnitTerms) {
      this.businessUnitFormGroup.addControl(businessUnitTerm.name, new FormControl(false));
    }
    if (this.task) {
      for (const businessUnitTerm of this.businessUnitTerms) {
        const isChecked = this.task.businessUnitIds.includes(businessUnitTerm.id);
        this.businessUnitFormGroup.get(businessUnitTerm.name).setValue(isChecked);
      }
    }
  }

  private getAccountManagers() {
    this.accountManagerService.accountManagers().subscribe(accountManagers => {
      this.accountManagers = accountManagers;
      this.dataChanged();
    });
  }

  private getBusinessUnitTerms() {
    this.taxonomyService.getTermsByVocabulary('business_unit').subscribe(data => {
      this.businessUnitTerms = data;
      this.addBusinessUnitsToForm();
      this.dataChanged();
      console.log('TaskDialog businessUnitTerms', this.businessUnitTerms);
    });
  }

  private getTaskTypeTerms() {
    this.taxonomyService.getTermsByVocabulary('task_type').subscribe(data => {
      this.taskTypeTerms = data;
      this.dataChanged();
    });
  }

  private getTaskStatusTerms() {
    this.taxonomyService.getTermsByVocabulary('task_status').subscribe(data => {
      this.taskStatusTerms = data;
      this.dataChanged();
    });
  }

}
