import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-client-contact-list-item-empty',
  templateUrl: './client-contact-list-item-empty.component.html',
  styleUrls: ['./client-contact-list-item-empty.component.scss']
})
export class ClientContactListItemEmptyComponent implements OnInit {

  @Input() text = 'No contacts';

  constructor() { }

  ngOnInit() {
  }

}
