import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {LockingService} from '../../../services/locking/locking.service';
import {Socket} from 'ngx-socket-io';
import {Subscription} from 'rxjs';
import {ClientService} from '../../../services/client/client.service';
import {patterns} from '../../../validators/patterns';

@Component({
  selector: 'app-client-contact-info',
  templateUrl: './client-contact-info.component.html',
  styleUrls: ['./client-contact-info.component.scss']
})
export class ClientContactInfoComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line:variable-name
  component_name = 'client-contact-info';
  currentLock = 0;

  @Input() clientId;
  @Input() contactInfo;

  contactForm: FormGroup;
  get email() { return this.contactForm.get('email'); }
  get website() { return this.contactForm.get('website'); }

  private subscription: Subscription;

  constructor(private toastrService: SolarifToastrService,
              private httpClient: HttpClient,
              private dialogService: NbDialogService,
              private lockingService: LockingService,
              private clientService: ClientService,
              private socket: Socket) {
  }

  ngOnInit() {
    this.resetForm();
    this.subscription = this.socket.fromEvent<any>(this.component_name).subscribe(currentLock => this.currentLock = currentLock);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private resetForm() {
    this.contactForm = new FormGroup({
      email: new FormControl(this.contactInfo.email, Validators.pattern(patterns.email)),
      phone: new FormControl(this.contactInfo.phone),
      website: new FormControl(this.contactInfo.website, Validators.pattern(patterns.url))
    });
  }

  openEditDialog(dialog: TemplateRef<any>) {
    this.addLock();
    this.dialogService.open(dialog).onClose.subscribe(() => {
      this.resetForm();
      this.removeLock();
    });
  }

  cancelEdit(ref: NbDialogRef<any>) {
    this.resetForm();
    ref.close();
  }

  onSubmit(ref: NbDialogRef<any>) {

    // this.toastrService.show('Changes have been saved', 'Saved', {icon: 'checkmark-outline', status: 'success'});

    // path = solarif-crm.localhost/jsonapi/group/relation/uuid

    const updatedInfo = {
      clientId: this.clientId,
      email: this.contactForm.get('email').value,
      phone: this.contactForm.get('phone').value,
      website: this.contactForm.get('website').value,
    };

    this.clientService.updateContactInfo(updatedInfo).subscribe(result => {
      this.toastrService.success(
        $localize`:@@contactInfoUpdateSuccess:Contact info has been updated.`
      );

      this.contactInfo.email = this.contactForm.get('email').value;
      this.contactInfo.phone = this.contactForm.get('phone').value;
      this.contactInfo.website = this.contactForm.get('website').value;
      ref.close();
    }, error => {
      this.toastrService.error(
        $localize`:@@contactInfoUpdateFailure:Contact info could not be updated.`
      );
    });

    // Asdf.
  }

  addLock() {
    this.lockingService.addLock(this.clientId, this.component_name);
  }

  removeLock() {
    this.lockingService.removeLock(this.clientId, this.component_name);
  }
}
