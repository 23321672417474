<nb-card class="atp-dialog" accent="none">
  <nb-card-header class="d-flex justify-content-between align-items-baseline">
    <div>ATP</div>
    <div class="icons d-inline-flex">
      <button *ngIf="mode == 'view'" class="btn btn-light btn-sm" (click)="setMode()"><nb-icon icon="edit-outline"></nb-icon></button>
      <button *ngIf="mode == 'view'" class="btn btn-sm" (click)="close()"><nb-icon icon="close-outline"></nb-icon></button>
    </div>
  </nb-card-header>
  <nb-list *ngIf="mode == 'view'; else editMode">
    <nb-list-item>
      <div class="row">
        <div class="col-1 text-center">
          <nb-icon icon="award-outline"></nb-icon>
        </div>
        <div class="col-11">
          {{ atp.title }}
        </div>
      </div>
    </nb-list-item>
    <nb-list-item>
      <div class="row">
        <div class="col-1 text-center">
          <nb-icon icon="person-outline"></nb-icon>
        </div>
        <div class="col-11">
          {{ formatDisplayName() }}
        </div>
      </div>
    </nb-list-item>
    <nb-list-item *ngIf="atp.phoneNumber && atp.phoneNumber.length > 0">
      <div class="row">
        <div class="col-1 text-center">
          <nb-icon icon="phone-outline"></nb-icon>
        </div>
        <div class="col-11">
            <span class="phone-number" *ngFor="let phoneNumber of atp.phoneNumber; let last = last;"><a
              href="tel:{{phoneNumber}}">{{ phoneNumber }}</a>
              <ng-container *ngIf="!last">, </ng-container>
            </span>
        </div>
      </div>
    </nb-list-item>
    <nb-list-item *ngIf="atp.emailAddress && atp.emailAddress.length > 0">
      <div class="row">
        <div class="col-1 text-center">
          <nb-icon icon="at-outline"></nb-icon>
        </div>
        <div class="col-11">
            <span class="email-address" *ngFor="let emailAddress of atp.emailAddress; let last = last;"><a
              href="mailto:{{emailAddress}}">{{ emailAddress }}</a>
              <ng-container *ngIf="!last">, </ng-container>
            </span>
        </div>
      </div>
    </nb-list-item>
    <nb-list-item *ngIf="atp.website">
      <div class="row">
        <div class="col-1 text-center">
          <nb-icon icon="globe-2-outline"></nb-icon>
        </div>
        <div class="col-11">
          <a href="{{ atp.website }}">{{ atp.website }}</a>
        </div>
      </div>
    </nb-list-item>
    <nb-list-item>
      <div class="row">
        <div class="col-1 text-center">
          <nb-icon icon="message-square-outline"></nb-icon>
        </div>
        <div class="col-11">
          {{ atp.memo }}
        </div>
      </div>
    </nb-list-item>
  </nb-list>
  <ng-template #editMode>
    <nb-card-body>
      <form [formGroup]="atpEditForm" class="dialog-form">
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <label class="label" for="title" i18n="@@atpTitleLabel">Title</label>
                <input id="title" nbInput fullWidth formControlName="title" placeholder="Title"
                       i18n-placeholder="@@atpTitlePlaceholder">
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-item">
                <label class="label" for="firstName" i18n="@@firstName">First name</label>
                <input nbInput fullWidth id="firstName" placeholder="First Name" i18n-placeholder="@@firstName"
                       formControlName="firstName">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-item">
                <label class="label" for="insertion" i18n="@@insertion">Insertion</label>
                <input nbInput fullWidth id="insertion" placeholder="Insertion" i18n-placeholder="@@insertion"
                       formControlName="insertion">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-item">
                <label class="label" for="lastName" i18n="@@lastName">Last name</label>
                <input nbInput fullWidth id="lastName" placeholder="Last Name" i18n-placeholder="@@lastName"
                       formControlName="lastName">
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-item multi email-array">
                <label class="label" for="emailAddressArrayInput" i18n="@@emailAddressesLabel">Email addresses</label>
                <div formArrayName="emailAddressArray"
                     *ngFor="let item of emailAddressFormData.controls; let i = index;">
                  <div [formGroupName]="i">
                    <input nbInput fullWidth type="text" id="emailAddressArrayInput" placeholder="Email address"
                           formControlName="email" i18n-placeholder="@@emailAddressPlaceholder">
                  </div>
                </div>
                <button class="add-more btn d-block m-auto" (click)="addAdditionalEmailAddress()">
                  <nb-icon icon="plus-outline"></nb-icon>
                </button>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-item multi phone-array">
                <label class="label" for="phoneNumberArrayInput" i18n="@@phoneNumberLabel">Phone numbers</label>
                <div formArrayName="phoneNumberArray"
                     *ngFor="let item of phoneNumberFormData.controls; let i = index;">
                  <div [formGroupName]="i">
                    <input nbInput fullWidth type="text" id="phoneNumberArrayInput" placeholder="Phone number"
                           formControlName="phone" i18n-placeholder="@@phoneNumberPlaceholder">
                  </div>
                </div>
                <button class="add-more btn d-block m-auto" (click)="addAdditionalPhoneNumber()">
                  <nb-icon icon="plus-outline"></nb-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <label class="label" for="website" i18n="@@website">Website</label>
                <input nbInput fullWidth id="website" placeholder="Website" i18n-placeholder="@@website"
                       formControlName="website">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <label class="label" i18n="@@memo" for="memo">Memo</label>
<!--                <ckeditor fullWidth [editor]="Editor" name="body" id="memo" formControlName="memo"></ckeditor>-->
                <textarea name="memo" formControlName="memo" nbInput fullWidth id="memo" rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>
      </form>
    </nb-card-body>
  </ng-template>
  <nb-card-footer *ngIf="mode == 'edit'">
    <div class="align-right form-actions">
      <input type="button" nbButton ghost status="primary" (click)="cancelEdit()" value="Cancel" i18n-value="@@cancel">
      <button nbButton status="primary" type="submit" [disabled]="atpEditForm.invalid" (click)="submitForm()" i18n="@@submit">Submit</button>
    </div>
  </nb-card-footer>
</nb-card>
