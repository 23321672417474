import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, of} from 'rxjs';
import {tap, map, flatMap} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Client} from '../../../models/client/client';
import {TaxonomyService} from '../../../services/taxonomy/taxonomy.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {ClientService} from '../../../services/client/client.service';
import {AtpDialogComponent} from '../../atp/atp-dialog/atp-dialog.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {LockingService} from '../../../services/locking/locking.service';
import {Socket} from 'ngx-socket-io';
import {faSitemap} from '@fortawesome/free-solid-svg-icons';
import {TaxonomyTerm} from '../../../models/terms/taxonomyTerm';

@Component({
  selector: 'app-client-general-info',
  templateUrl: './client-general-info.component.html',
  styleUrls: ['./client-general-info.component.scss']
})
export class ClientGeneralInfoComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:variable-name
  component_name = 'client-general-info';

  faSitemap = faSitemap;

  // tslint:disable-next-line:variable-name
  private _client: Client;
  get client() {
    return this._client;
  }
  @Input()
  set client(newClient: Client) {
    this._client = newClient;
    this.initiateClientData();
  }

  @Output()
  changed = new EventEmitter<Client>();

  generalInfoForm: FormGroup;
  console = console;
  currentLock = 0;
  private subscription: Subscription;

  branchTerms: Array<TaxonomyTerm> = [];
  selectedBranches = [];

  businessUnitTerms: Array<TaxonomyTerm> = [];
  selectedBusinessUnits = [];

  relationTypeTerms: Array<TaxonomyTerm> = [];
  selectedRelationType = '';

  clientTypeTerms: Array<TaxonomyTerm> = [];
  selectedClientType = '';

  legalFormTerms: Array<TaxonomyTerm> = [];
  selectedLegalForm = '';

  zakelijkTermId = '080d4c12-9222-46da-a30c-3601f9f1e5b0';
  showLegalForm = false;

  isLoadingParentClientOptions = false;
  showParentClientOptions = false;
  parentClientSearchTerm$ = new BehaviorSubject<string>('');
  parentClientOptions$ = this.parentClientSearchTerm$.pipe(
      tap(() => this.showParentClientOptions = true),
      tap(() => this.isLoadingParentClientOptions = true),
      flatMap(searchTerm => searchTerm.length >= 3 ? this.clientService.search(searchTerm) : of([])),
      map((x: any) => x ? x.slice(0, 8) : []),
      tap(() => this.isLoadingParentClientOptions = false),
    );
  parentClientOptions = [];


  constructor(private dialogService: NbDialogService,
              private taxonomyService: TaxonomyService,
              private httpClient: HttpClient,
              private toastrService: SolarifToastrService,
              private clientService: ClientService,
              private lockingService: LockingService,
              private socket: Socket) {
  }

  ngOnInit() {
    this.toggleShowLegalForm();
    this.getBranchTerms();
    this.getBusinessUnitTerms();
    this.getRelationTypeTerms();
    this.getClientTypeTerms();
    this.getLegalFormTerms();

    this.resetForm();

    this.parentClientOptions$.subscribe(x => this.parentClientOptions = x);

    this.subscription = this.socket.fromEvent<any>(this.component_name).subscribe(currentLock => this.currentLock = currentLock);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initiateClientData() {
    this.fetchHierarchy();
  }

  openEditDialog(dialog: TemplateRef<any>) {
    this.resetForm();
    this.addLock();
    this.dialogService.open(dialog).onClose.subscribe(() => {
      this.removeLock();
    });
  }

  openOrganizationChartDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  onSubmit(ref: NbDialogRef<any>) {
    const branches = this.generalInfoForm.get('branch').value;
    const updateBranches = [];
    branches.forEach(term => {
      updateBranches.push({type: 'taxonomy_term--branch', id: term});
    });

    const businessUnits = this.generalInfoForm.get('businessUnit').value;
    const updateBusinessUnits = [];
    businessUnits.forEach(term => {
      updateBusinessUnits.push({type: 'taxonomy_term--business_unit', id: term});
    });

    const updateRelationType = [];
    updateRelationType.push({type: 'taxonomy_term--relatie_type', id: this.generalInfoForm.get('relationType').value});

    const updateClientType = {type: 'taxonomy_term--client_type', id: this.generalInfoForm.get('clientType').value};

    const updateLegalForm = [];

    if (this.generalInfoForm.get('legalForm').value) {
      updateLegalForm.push({type: 'taxonomy_term--rechtsvorm', id: this.generalInfoForm.get('legalForm').value});
    }

    const body = {
      data: {
        type: 'group--relation',
        id: this.client.id,
        attributes: {
          field_debtor_number: this.generalInfoForm.get('debtorNumber').value,
          field_coc: this.generalInfoForm.get('cocNumber').value,
          field_status: this.generalInfoForm.get('status').value
        },
        relationships: {
          field_ref_branch: {
            data: updateBranches,
          },
          field_ref_business_unit: {
            data: updateBusinessUnits,
          },
          field_ref_relation_type: {
            data: updateRelationType
          },
          field_ref_client_type: {
            data: updateClientType
          },
          field_ref_legal_form: {
            data: updateLegalForm
          }
        }
      }
    };
    this.httpClient.patch(environment.drupalUrl + '/jsonapi/group/relation/' + this.client.id, body).pipe(
      flatMap(() => this.clientService.updateClientParent(this.client, this.generalInfoForm.get('parentClientId').value))
    ).subscribe(result => {
      this.toastrService.success(
        $localize`:@@generalInfoUpdateSuccess:Information has been updated`,
        $localize`:@@saved:Saved`,
      );
      this.clientService.client(this.client.id).subscribe(response => {
        this.client = response;
        this.resetForm();
        this.toggleShowLegalForm();
        this.fetchHierarchy();
        this.changed.emit(this.client);
      });
      ref.close();
    }, error => {
      this.toastrService.error(
        $localize`:@@generalInfoUpdateFailure:Information could not be updated`,
        $localize`:@@errorSaving:Error`,
      );
    });
  }

  cancelEdit(ref: NbDialogRef<any>) {
    ref.close();
  }

  openAtpDialog() {
    this.dialogService.open(AtpDialogComponent, {
      context: {
        atp: this.client.atp
      }
    });
  }

  addLock() {
    this.lockingService.addLock(this.client.id, this.component_name);
  }

  removeLock() {
    this.lockingService.removeLock(this.client.id, this.component_name);
  }

  onSearchParentClientChange() {
    const searchTerm = this.generalInfoForm.get('parentClient').value;
    this.parentClientSearchTerm$.next(searchTerm);
  }

  onSearchParentClientBlur() {
    if (this.showParentClientOptions) {
      this.generalInfoForm.controls['parentClient'].setValue('');
      this.generalInfoForm.controls['parentClientId'].setValue(null);
      this.showParentClientOptions = false;
    }
  }

  onSelectParentClient(client, ev) {
    ev.stopPropagation();
    this.generalInfoForm.controls['parentClientId'].setValue(client.id);
    this.generalInfoForm.controls['parentClient'].setValue(client.title);
    this.showParentClientOptions = false;
  }


  private resetForm() {
    const hasParents = this.client && this.client.hierarchy && this.client.hierarchy.parents && this.client.hierarchy.parents.length;
    this.generalInfoForm = new FormGroup({
      debtorNumber: new FormControl(this.client.debtorNumber),
      cocNumber: new FormControl(this.client.cocNumber),
      relationType: new FormControl(this.selectedRelationType, [Validators.required]),
      clientType: new FormControl(this.selectedClientType, [Validators.required]),
      legalForm: new FormControl(this.selectedLegalForm),
      branch: new FormControl(this.selectedBranches),
      businessUnit: new FormControl(this.selectedBusinessUnits),
      status: new FormControl(this.client.status, [Validators.required]),
      parentClient: new FormControl(hasParents ? this.client.hierarchy.parents[0].title : ''),
      parentClientId: new FormControl(hasParents ? this.client.hierarchy.parents[0].id : null),
    });

    if (this.client.clientType) {
      this.selectedClientType = this.client.clientType.id;
      this.generalInfoForm.get('clientType').setValue(this.client.clientType.id);
    }

    if (this.client.legalForm) {
      this.selectedClientType = this.client.legalForm.id;
      this.generalInfoForm.get('legalForm').setValue(this.client.legalForm.id);
    }

    if (this.client.relationType) {
      this.client.relationType.forEach(relation => {
        this.selectedRelationType = relation.id;
        this.generalInfoForm.get('relationType').setValue(relation.id);
      });
    }

    if (this.client.branch) {
      this.selectedBranches = [];
      this.client.branch.forEach(branch => {
        this.selectedBranches.push(branch.id);
      });
      this.generalInfoForm.get('branch').setValue(this.selectedBranches);
    }

    if (this.client.businessUnits) {
      this.selectedBusinessUnits = [];
      this.client.businessUnits.forEach(branch => {
        this.selectedBusinessUnits.push(branch.id);
      });
      this.generalInfoForm.get('businessUnit').setValue(this.selectedBusinessUnits);
    }

  }

  private getClientTypeTerms() {
    this.taxonomyService.getTermsByVocabulary('client_type').subscribe(data => {
      this.clientTypeTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary client type', error);
    });
  }

  private toggleShowLegalForm() {
    if (this.client.clientType) {
      this.showLegalForm = this.client.clientType.id === this.zakelijkTermId;
    }
  }

  private getLegalFormTerms() {
    this.taxonomyService.getTermsByVocabulary('rechtsvorm').subscribe(data => {
      this.legalFormTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary legal form terms', error);
    });
  }

  private getRelationTypeTerms() {
    this.taxonomyService.getTermsByVocabulary('relatie_type').subscribe(data => {
      this.relationTypeTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary relation type', error);
    });
  }

  private getBranchTerms() {
    this.taxonomyService.getTermsByVocabulary('branch').subscribe(data => {
      this.branchTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary branch', error);
    });
  }

  private getBusinessUnitTerms() {
    this.taxonomyService.getTermsByVocabulary('business_unit').subscribe(data => {
      this.businessUnitTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary business unit', error);
    });
  }

  private fetchHierarchy() {
    console.log('fetchHierarchy');
    this.clientService.hierarchy(this.client.id).subscribe(hierarchy => {
      this.client.hierarchy = hierarchy;
      console.log('Set hierarchy', this.client.hierarchy);
    });
  }

}
