import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { Socket } from 'ngx-socket-io';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class LockingService {
  currentUser;
  constructor(private socket: Socket, private authService: AuthService) {
    this.currentUser = this.authService.identityClaims;
  }

  getLockStatus(objecID: string, componentName: string) {
    this.socket.emit('getLock', {
      user_id: this.currentUser.sub,
      object_id: objecID, component_name: componentName,
      site_url: environment.drupalUrl
    });
  }

  addLock(objecID: string, componentName: string) {
    this.socket.emit('addLock', {
      user_id: this.currentUser.sub, object_id: objecID,
      component_name: componentName,
      site_url: environment.drupalUrl
    });
  }

  removeLock(objecID: string, componentName: string) {
    this.socket.emit('removeLock', {
      user_id: this.currentUser.sub,
      object_id: objecID, component_name: componentName,
      site_url: environment.drupalUrl
    });
  }

  joinRooms(objecID: string, componentList: any) {
    this.socket.emit('joinRooms', {
      user_id: this.currentUser.sub,
      object_id: objecID, component_list: componentList,
      site_url: environment.drupalUrl
    });
  }

  joinRoom(objecID: string, componentName: string) {
    this.socket.emit('joinRooms', {
      user_id: this.currentUser.sub,
      object_id: objecID, component_list: [componentName],
      site_url: environment.drupalUrl
    });
  }
}
