import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../auth.service';
import {TaxonomyService} from '../taxonomy/taxonomy.service';
import {Activity, PolicyApplicationActivity} from '../../models/activity/activity';
import {JsonApiResponseMany} from '../../models/api/jsonapi.models';
import {Reply} from '../../models/activity/reply';
import {ActivityPatch} from '../../models/activity/activity-patch';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      'Accept': 'application/vnd.api+json'
    })
  };

  activityTypeTerms;

  private activitiesChangedSource = new Subject();
  activitiesChanged$ = this.activitiesChangedSource.asObservable();

  private identityClaims;


  constructor(private httpClient: HttpClient,
              private taxonomyService: TaxonomyService,
              private authService: AuthService) {
    this.identityClaims = this.authService.identityClaims;

    this.taxonomyService.getTermsByVocabulary('activiteit_type').subscribe(data => {
      this.activityTypeTerms = data;
    });
  }

  public reloadActivities() {
    this.activitiesChangedSource.next();
  }

  public policyApplications(sortColumn?: 'client' | 'date', sortOrder?: 'asc' | 'desc'): Observable<Array<PolicyApplicationActivity>> {

    const filter = `filter[field_ref_tax_type_activiteit.id]=${environment.taxonomyTermIds.activityType.policyApplication}`;
    const include = 'include=field_client,field_ref_status,field_assignee';
    const sort = 'sort=' + (sortOrder === 'asc' ? '' : '-') + (sortColumn === 'client' ? 'field_client.label' : 'field_datetime');
    const url = `${environment.drupalUrl}/jsonapi/activity/activiteit?${include}&${filter}&${sort}`;

    return this.httpClient.get<JsonApiResponseMany>(url)
      .pipe(
        map(res => {
          return res.data.map(responseActivity => {
            const attrs = responseActivity.attributes;
            const rels = responseActivity.relationships;
            if (!rels || !rels.field_client || !rels.field_client.data) {
              return null;
            }
            const client = res.included.find(x => x.id === rels.field_client.data.id);
            if (!client) {
              return null;
            }

            const status = rels.field_ref_status.data ? res.included.find(x => x.id === rels.field_ref_status.data.id) : undefined;
            const assignee = rels.field_assignee.data ? res.included.find(x => x.id === rels.field_assignee.data.id) : undefined;

            return {
              id: responseActivity.id,
              title: attrs.title,
              clientId: client.id,
              clientLabel: client.attributes.label,
              date: new Date(attrs.field_datetime),
              status: status ? status.attributes.name : '',
              assignee: assignee ? assignee.attributes.display_name : '',
            };
          }).filter(x => x !== null);
        })
      );
  }

  public activities(clientId: string): Observable<Array<Activity>> {
    const url = `${environment.drupalUrl}/api/group/${clientId}/activities?_format=json`;
    // console.log('Get activities url', url);
    return this.httpClient.get<any>(url)
      .pipe(
        tap(activities => {
          if (activities.activities.length > 0) {
            const outcome = 'found';
          } else {
            const outcome = 'noresults';
          }
        }),
        map(response => {
          const activities = [];
          response.activities.forEach(responseActivity => {

            const date = new Date(responseActivity.date);
            const timezoneCorrected = this.adjustForTimezone(date);

            const activity: Activity = {
              id: responseActivity.id,
              title: responseActivity.title,
              assignee: responseActivity.assignee,
              business_unit_ids: responseActivity.business_unit_ids,
              short_description: responseActivity.short_description,
              long_description: responseActivity.long_description,
              time_spent: (responseActivity.time_spent) ? responseActivity.time_spent : 0,
              type: responseActivity.type,
              type_id: responseActivity.type_id,
              type_uuid: responseActivity.type_uuid,
              icon: responseActivity.icon,
              date: timezoneCorrected.toISOString(),
              current_activity_id: responseActivity.entity_id,
              previous_activity_id: responseActivity.prev_activity,
              form_submission_data: responseActivity.form_submission_data,
              referenced_data_object: JSON.parse(responseActivity.ref_data),
              children: responseActivity.children
            };

            activities.push(activity);
          });
          console.log('ActivityService activities response', activities);
          return activities;
        })
      );
  }

  public patchActivity(activityUuid: string, activity: ActivityPatch) {
    const body: any = {
      data: {
        id: activityUuid,
        type: 'activity--activiteit',
        attributes: {
          field_long_description: activity.description,
          field_time_spend: activity.timeSpent || 0,
          title: activity.title,
          field_datetime: activity.date,
        },
        relationships: {
          field_ref_tax_type_activiteit: {
            data: {
              type: 'taxonomy_term--activiteit_type',
              id: activity.typeId
            }
          }
        }
      }
    };

    if (activity.businessUnitIds) {
      body.data.relationships.field_activity_ref_business_unit = {
        data: activity.businessUnitIds.map(id => ({
          type: 'taxonomy_term--business_unit',
          id
        }))
      };
    }

    return this.httpClient.patch(environment.drupalUrl + '/jsonapi/activity/activiteit/' + activityUuid, body, this.httpOptions);
  }

  public postActivity(activity: ActivityPatch): Observable<any> {
    const body: any = {
      data: {
        type: 'activity--activiteit',
        attributes: {
          field_long_description: activity.description,
          field_time_spend: activity.timeSpent || 0,
          title: activity.title,
          field_datetime: activity.date,
        },
        relationships: {
          field_assignee: {
            data: {
              type: 'user--user',
              id: this.identityClaims.uuid
            }
          },
          field_client: {
            data: {
              type: 'group--relation',
              id: activity.clientId
            }
          },
          field_ref_tax_type_activiteit: {
            data: {
              type: 'taxonomy_term--activiteit_type',
              id: activity.typeId
            }
          }
        }
      }
    };

    if (activity.businessUnitIds) {
      body.data.relationships.field_activity_ref_business_unit = {
        data: activity.businessUnitIds.map(id => ({
          type: 'taxonomy_term--business_unit',
          id
        }))
      };
    }

    return this.httpClient.post(environment.drupalUrl + '/jsonapi/activity/activiteit', body, this.httpOptions);
  }

  public postReply(reply: Reply, clientId: string): Observable<any> {
    const body = {
      data: {
        type: 'activity--activiteit',
        attributes: {
          field_long_description: reply.message,
          title: reply.title,
          field_datetime: reply.datetime,
          field_time_spend: reply.timeSpent || 0,
        },
        relationships: {
          field_assignee: {
            data: {
              type: 'user--user',
              id: this.identityClaims.uuid
            },
          },
          field_client: {
            data: {
              type: 'group--relation',
              id: clientId
            }
          },
          field_ref_previous_activity: {
            data: {
              type: 'activity--activiteit',
              id: reply.previousActivityId
            }
          },
          field_ref_tax_type_activiteit: {
            data: {
              type: 'taxonomy_term--activiteit_type',
              id: reply.type
            }
          }
        }
      }
    };

    return this.httpClient.post(environment.drupalUrl + '/jsonapi/activity/activiteit', body, this.httpOptions);
  }

  adjustForTimezone(date: Date): Date {
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
}

}
