import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  nodes = new Map<string, Observable<any>>();
  menus = new Map<string, Observable<any>>();
  user;
  private mainMenu = null;

  constructor(private http: HttpClient, private location: Location ) { }

  getNodes(): Observable<any> {
    console.log('getting nodes');
    const nodes = this.http.get<any>(environment.drupalUrl + '/api/nodes?_format=hal_json').pipe(
      publishReplay(1),
      refCount(),
      take(1)
    );
    console.log(nodes);
    return nodes;
  }

  getNodeByTypeAndId(type, uuid): Observable<any> {
    // sample uuid d3d303dd-69f6-4ee7-98cb-f74ccfbd8aba
    console.log('getting node');
    const node = this.http.get<any>(environment.drupalUrl + '/jsonapi/node/' + type + '/' + uuid).pipe(
      publishReplay(1),
      refCount(),
      take(1)
    );
    console.log(node);
    return node;
  }

  getNode(path = null): Observable<any> {
    if (!path) {
      path = this.location.path();
    }

    let node;
    if (!this.nodes.get(path)) {
      node = this.http.get<any>('/api/').pipe(
          publishReplay(1),
          refCount(),
          take(1)
      );
      this.nodes.set(path, node);
    }

    return this.nodes.get(path);
  }

  getMenu(menuName): Observable<any> {
    if (!this.menus.get(menuName)) {
      const menu = this.http.get<any>(environment.drupalUrl + '/entity/menu/' + menuName + '/tree?_format=json').pipe(
          publishReplay(1),
          refCount(),
          take(1)
      );
      this.menus.set(menuName, menu);
    }

    return this.menus.get(menuName);
  }

    mainMenuDataProvider() {
        return new Promise((resolve, reject) => {
            this.http
                .get(environment.drupalUrl + '/entity/menu/' + environment.mainMenu + '/tree?_format=json')
                .pipe(
                    map(res => res) // or any other operator
                )
                .subscribe(response => {
                    //this.menus.set(environment.mainMenu, response);
                    this.mainMenu = response;
                    resolve(true);
                });
        });
    }

  getMainMenu() {
      return this.mainMenu;
  }

  getCurrentUserData() {
      let user = this.http.get<any>(environment.drupalUrl + '/user/1?_format=json', {}).subscribe((user) => {
          console.log('user subscribe', user);
          }
      );
      console.log('getCurrentUserData in service', user);
      this.user = user;
      return this.user;
  }

    /*getMainMenuDataProvider(menuName = 'main') {
        return new Promise((resolve, reject) => {
            this.http
                .get(environment.drupalUrl + '/api/menu_items/' + menuName + '?_format=json')
                .pipe(
                    map(res => res) // or any other operator
                )
                .subscribe(response => {
                    this.mainMenu = response;
                    resolve(true);
                })
        })
    }*/
}
