import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMenuItem} from '@nebular/theme';
import {Subscription} from 'rxjs';
import {AuthService} from './services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../environments/environment';
import {SolarifToastrService} from './services/toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'solarif-portal';
  items: NbMenuItem[] = [
    {
      title: $localize`:@@clientsMenuItem:Clients`,
      link: '/client/list',
      icon: 'list-outline'
    },
    {
      title: $localize`:@@PolicyApplicationMenuItem:Policy application`,
      url: `${environment.drupalUrl}/webform/polisaanvraagformulier`,
      // link: 'policy_application',
      icon: 'file-add-outline',
      target: '_blank',
    },
    {
      title: $localize`:@@policyApplicationsMenuItem:Policy applications`,
      url: `/policy-applications`,
      icon: 'file-text-outline'
    },
    {
      title: $localize`:@@Tasks:Tasks`,
      url: `/tasks`,
      icon: 'file-text-outline'
    }
  ];

  environment = environment;
  status: string;
  action: string;
  subscription = new Subscription();

  constructor(private authService: AuthService,
              private toastrService: SolarifToastrService,
              private route: ActivatedRoute) {
    // localStorage.removeItem('id_token_claims_obj');
    this.authService.runInitialLoginSequence();


    this.subscription = this.route.queryParams.subscribe(params => {
      this.status = params.status;
      this.action = params.action;
      if (params.action && params.status) {
        if (params.status === 'success' && params.action === 'profile_updated') {
          this.toastrService.success(
            $localize`:@@accountSaved:The changes to your account have been saved.`,
          );
          this.authService.silentRefresh();
          // console.log('identityClaims after saving:', this.authService.userProfile);
        }
      }
    });

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
