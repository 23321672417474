import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Address} from '../../../models/address';
import {Client} from '../../../models/client/client';
import {LockingService} from '../../../services/locking/locking.service';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AddressService} from '../../../services/address/address.service';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {TaxonomyService} from '../../../services/taxonomy/taxonomy.service';
import {patterns} from '../../../validators/patterns';
import {TaxonomyTerm} from '../../../models/terms/taxonomyTerm';

@Component({
  selector: 'app-client-addresses',
  templateUrl: './client-addresses.component.html',
  styleUrls: ['./client-addresses.component.scss']
})
export class ClientAddressesComponent implements OnInit, OnDestroy {

  @Input()
  client: Client;
  @Input()
  addresses: Array<Address>;

  addAddressForm: FormGroup;
  zipcodeControl: AbstractControl;

  addressTypes: Array<TaxonomyTerm>;

  subscription = new Subscription();
  lockingSubscription = new Subscription();
  currentLock = 0;
  private componentName = 'client-addresses';

  constructor(private addressService: AddressService,
              private lockingService: LockingService,
              private toastrService: SolarifToastrService,
              private taxonomyService: TaxonomyService,
              private dialogService: NbDialogService) { }

  ngOnInit() {
    this.getAddressTypes();
    this.resetForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.lockingSubscription.unsubscribe();
  }

  openAddDialog(dialog: TemplateRef<any>) {
    this.addLock();
    this.dialogService.open(dialog).onClose.subscribe(() => {
      this.resetForm();
      this.removeLock();
    });
  }

  cancelEdit(ref: NbDialogRef<any>) {
    this.resetForm();
    ref.close();
  }

  onSubmit(ref: NbDialogRef<any>) {
    const address: Address = {
      address_line1: this.addAddressForm.get('addressLine').value,
      address_line2: this.addAddressForm.get('addressLineSecondary').value,
      country_code: this.addAddressForm.get('countryCode').value,
      is_post_address: this.addAddressForm.get('isPostAddress').value || false,
      locality: this.addAddressForm.get('locality').value,
      postal_code: this.addAddressForm.get('postalCode').value,
      title: this.addAddressForm.get('title').value,
      type: {
        uuid: this.addAddressForm.get('type').value,
        name: this.addressTypes.filter(type => type.id === this.addAddressForm.get('type').value)[0].name
      }
    };

    this.addressService.addAddress(this.client.id, address).subscribe(result => {
      console.log('ClientAddressesComponent onSubmit Succces:', result);
      address.uuid = result.body.address_uuid;
      this.addresses.push(address);
      this.toastrService.success(
        $localize`:@@addressAddSuccess:Address has been added.`
      );
      ref.close();
    }, error => {
      console.log('ClientAddressesComponent onSubmit Error:', error);
      this.toastrService.error(
        $localize`:@@addressAddFailure:Address info could not be added.`
      );
    });
  }

  updateFormValidation() {
    this.setPostalCodeValidation(this.addAddressForm.get('countryCode').value);
  }

  onAddressDeleted(uuid: string) {
    const toRemove = this.addresses.filter(item => {
      return item.uuid === uuid;
    });

    const index = this.addresses.indexOf(toRemove[0]);

    this.addresses.splice(index, 1);
  }

  private resetForm() {
    this.addAddressForm = new FormGroup({
      title: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      countryCode: new FormControl('', Validators.required),
      postalCode: new FormControl(''),
      addressLine: new FormControl(''),
      addressLineSecondary: new FormControl(''),
      isPostAddress: new FormControl(''),
      locality: new FormControl('')
    });

    this.addAddressForm.get('postalCode').valueChanges.subscribe(() => {
      this.addAddressForm.get('postalCode').patchValue(this.addAddressForm.get('postalCode').value.toUpperCase(), {emitEvent: false});
    });
  }

  private setPostalCodeValidation(countryCode: string) {
    this.zipcodeControl = this.addAddressForm.get('postalCode');

    if (countryCode === 'NL') {
      this.zipcodeControl.setValidators([Validators.pattern(patterns.zipCodes.nl)]);
    } else if (countryCode === 'DE') {
      this.zipcodeControl.setValidators([Validators.pattern(patterns.zipCodes.de)]);
    } else if (countryCode === 'BE') {
      this.zipcodeControl.setValidators([Validators.pattern(patterns.zipCodes.be)]);
    }
    this.zipcodeControl.updateValueAndValidity();
  }

  private getAddressTypes() {
    this.taxonomyService.getTermsByVocabulary('adres_type').subscribe(terms => {
      this.addressTypes = terms;
    });
  }

  private addLock() {
    this.lockingService.addLock(this.client.id, this.componentName);
  }

  private removeLock() {
    this.lockingService.removeLock(this.client.id, this.componentName);
  }
}
