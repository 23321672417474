import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {EMPTY, Observable} from 'rxjs';
import {User} from '../../models/user';
import {expand, map, reduce} from 'rxjs/operators';
import {JsonApiResponseMany} from '../../models/api/jsonapi.models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  public search(text: string): Observable<Array<User>> {
    console.log(`Search users: ${environment.drupalUrl}/jsonapi/user/user?filter[name][operator]=CONTAINS&filter[name][value]=${text}`);
    return this.httpClient.get<JsonApiResponseMany>(`${environment.drupalUrl}/jsonapi/user/user?filter[name][operator]=CONTAINS&filter[name][value]=${text}`)
      .pipe(
        expand((response) => {
          if (response && response.links && response.links.next) {
            console.log(`users() NEXT`, response.links.next.href);
            return this.httpClient.get<any>(response.links.next.href);
          } else {
            // console.log(`getTermsByVocabulary ${vocabulary} END`);
            return EMPTY;
          }
        }),
        map(response => {
          const users: Array<User> = [];

          response.data.forEach(item => {

            if (item.attributes.mail === null) {
              return;
            }

            const user: User = {
              email: item.attributes.mail,
              fullName: item.attributes.field_full_name,
              realXsAccountId: item.attributes.field_realxs_account_id,
              username: item.attributes.name,
              uuid: item.id

            };
            users.push(user);
          });

          return users;
        }),
        reduce((acc, x: any) =>
          acc.concat(x), []
        ),
      );
  }
}
