import {Component, Input, OnInit} from '@angular/core';
import {Policy, PolicyContract} from '../../../models/policy-contract';
import {PolicyService} from '../../../services/policy/policy.service';

@Component({
  selector: 'app-client-policy-contracts',
  templateUrl: './client-policy-contracts.component.html',
  styleUrls: ['./client-policy-contracts.component.scss']
})
export class ClientPolicyContractsComponent implements OnInit {

  @Input() clientId;

  policy: Policy = null;

  constructor(private policyService: PolicyService) { }

  ngOnInit() {
    this.policyService.getClientPolicy(this.clientId).subscribe(result => {
      this.policy = result;
      console.log('this.policy', this.policy);
    });
  }

  contractNumberOfClaims(contract: PolicyContract): number {
    return this.policy.claims.filter(claim => claim.contractId === contract.contractId).length;
  }

  contractNumberOfInvoices(contract: PolicyContract): number {
    return this.policy.invoices.filter(invoice => invoice.contractId === contract.contractId && invoice.status === '0001 Active').length;
  }

  contractsFilteredByStatus(): Array<PolicyContract> {
    return this.policy.contracts.filter(contract => contract.status !== '0090 Conversie');
  }

}
