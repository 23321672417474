import {Component, Input, OnInit} from '@angular/core';
import {TaxonomyService} from '../../../services/taxonomy/taxonomy.service';
import {AccountManagerService} from '../../../services/account-manager/account-manager.service';
import {AccountManager} from '../../../models/AccountManager';
import {TaskFilters} from '../../../models/task';
import {TaskService} from '../../../services/task/task.service';
import {AuthService} from '../../../services/auth.service';
import {DateTimeAdapter} from 'ng-pick-datetime';
import {environment} from '../../../../environments/environment';
import {TaxonomyTerm} from '../../../models/terms/taxonomyTerm';

@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss']
})
export class TaskFilterComponent implements OnInit {

  @Input() setCurrentAccountManagerFilter = false;

  accountManagers: Array<AccountManager> = [];
  businessUnitTerms: Array<TaxonomyTerm> = [];
  taskTypeTerms: Array<TaxonomyTerm> = [];
  statusTerms: Array<TaxonomyTerm> = [];

  textSearch: string;
  selectedAccountManager: string;
  selectedTypes: Array<string>;
  selectedStatus: Array<string>;
  expireDateFrom: Date;
  expireDateUntil: Date;

  filters: TaskFilters;

  private readonly identityClaims;

  constructor(private taxonomyService: TaxonomyService,
              private taskService: TaskService,
              private accountManagerService: AccountManagerService,
              private dateTimeAdapter: DateTimeAdapter<any>,
              private authService: AuthService) {
    this.identityClaims = this.authService.identityClaims;
    // dateTimeAdapter.setLocale('nl');
  }

  ngOnInit() {
    this.resetFilterValues();
    this.getAccountManagers();
    this.getBusinessUnitTerms();
    this.getTaskTypeTerms();
    this.getTaskStatusTerms();
  }

  clearFilter() {
    this.resetFilterValues();
    this.taskService.setFilter(null);
  }

  onTextSearch(): void {
    if (this.textSearch.length >= 3) {
      this.filters.textSearch = this.textSearch;
      this.setFilter();
    } else {
      this.filters.textSearch = null;
      this.setFilter();
    }
  }

  onAccountManagerChange() {
    if (this.selectedAccountManager === '_all') {
      this.filters.accountManager = null;
      this.selectedAccountManager = '';
    } else {
      this.filters.accountManager = this.selectedAccountManager;
    }
    this.setFilter();
  }

  onStatusChange() {
    if (this.selectedStatus.length === 0) {
      this.filters.statuses = null;
    } else {
      this.filters.statuses = this.selectedStatus;
    }
    this.setFilter();
  }

  onTypeChange() {
    if (this.selectedTypes.length === 0) {
      this.filters.types = null;
    } else {
      this.filters.types = this.selectedTypes;
    }
    this.setFilter();
  }

  onExpireDateFromChange() {
    if (!this.filters.expireDate) {
      this.filters.expireDate = {};
    }
    this.filters.expireDate.from = this.expireDateFrom;
    this.setFilter();
  }

  onExpireDateUntilChange() {
    if (!this.filters.expireDate) {
      this.filters.expireDate = {};
    }
    this.filters.expireDate.until = this.expireDateUntil;
    this.setFilter();
  }

  setBusinessUnitFilter(businessUnit) {
    if (this.filters.businessUnit === businessUnit.id) { // Toggle off on second press
      this.filters.businessUnit = null;
    } else {
      this.filters.businessUnit = businessUnit.id;
    }
    this.setFilter();
  }

  private setFilter() {
    this.taskService.setFilter(this.filters);
  }

  private resetFilterValues() {
    this.textSearch = null;
    this.selectedAccountManager = null;
    this.selectedTypes = [];
    this.selectedStatus = [];
    this.expireDateFrom = null;
    this.expireDateUntil = null;

    this.filters = {};
  }

  private getAccountManagers() {
    this.accountManagerService.accountManagers().subscribe(accountManagers => {
      this.accountManagers = accountManagers;
      if (this.setCurrentAccountManagerFilter) {
        this.setCurrentUserAsAccountManagerFilter();
      }
    });
  }

  private getBusinessUnitTerms() {
    this.taxonomyService.getTermsByVocabulary('business_unit').subscribe(data => {
      this.businessUnitTerms = data;
    });
  }

  private getTaskTypeTerms() {
    this.taxonomyService.getTermsByVocabulary('task_type').subscribe(data => {
      this.taskTypeTerms = data;
    });
  }

  private getTaskStatusTerms() {
    this.taxonomyService.getTermsByVocabulary('task_status').subscribe(data => {
      this.statusTerms = data;

      // Set default filter to show tasks with all statuses except done
      const termsToActivate = [];
      this.statusTerms.forEach(status => {
        if (status.id !== environment.taxonomyTermIds.taskStatus.done) {
          termsToActivate.push(status.id);
        }
        this.selectedStatus = termsToActivate;
        this.onStatusChange();
      });
    });
  }

  private setCurrentUserAsAccountManagerFilter() {
    if (this.identityClaims && this.identityClaims.uuid) {
        const accountManager = this.accountManagers.filter(manager => manager.uuid === this.identityClaims.uuid)[0];
        if (accountManager) {
          this.selectedAccountManager = accountManager.uuid;
          this.filters.accountManager = accountManager.uuid;
          this.setFilter();
        }
    }
  }
}
