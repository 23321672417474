import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {PolicyContractResponse, PolicyResponse} from '../../models/api/solarif_rest.models';
import {catchError, map} from 'rxjs/operators';
import {Policy, PolicyClaim, PolicyContract, PolicyInvoice} from '../../models/policy-contract';
import {UtilitiesService} from '../utilities/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(private httpClient: HttpClient) { }

  private static formatContractDate(date: number): Date {

    const dateString = date.toString();

    if (dateString.length === 0) {
      return null;
    }

    const year = dateString.substr(0, 4);
    const month = dateString.substr(4, 2);
    const day = dateString.substr(6, 2);

    // console.log('Contact date formatted', `${day}/${month}/${year}`);
    // console.log('New Date', new Date(`${year}-${month}-${day}`));

    return new Date(`${year}-${month}-${day}` );
  }

  public getClientPolicy(clientId): Observable<Policy> {
    const url = `${environment.drupalUrl}/api/client/${clientId}/policy`;

    return this.httpClient.get<PolicyResponse>(url)
      .pipe(
        map(response => {
          if (!response) {
            return;
          }
          console.log('PolicyService response', response);
          const policy: Policy = {
            clientId: response.cdp_uuid,
            claims: [],
            clientName: response.name,
            contracts: [],
            invoices: [],
            realXsId: response.realxs_id
          };
          const claims: Array<PolicyClaim> = [];
          const contracts: Array<PolicyContract> = [];
          const invoices: Array<PolicyInvoice> = [];

          response.claims.forEach(item => {
            const claim: PolicyClaim = {
              allocation: UtilitiesService.parseDecimalString(item.allocation),
              claimId: item.claim_id,
              contractId: item.contract_id,
              disputed: UtilitiesService.parseDecimalString(item.disputed),
              excess: UtilitiesService.parseDecimalString(item.excess),
              outstanding: UtilitiesService.parseDecimalString(item.outstanding),
              settlement: UtilitiesService.parseDecimalString(item.settlement),
              status: item.status
            };
            claims.push(claim);
          });

          response.contracts.forEach(item => {
            const contract: PolicyContract = {
              dateRenewed: PolicyService.formatContractDate(item.date_renewed),
              description: item.description,
              expiryDate: PolicyService.formatContractDate(item.expiry_date),
              inceptionDate: PolicyService.formatContractDate(item.inception_date),
              insurer: item.name_insurer,
              nextRenewalDate: PolicyService.formatContractDate(item.next_renewal_date),
              status: item.status,
              contractId: item.contract_id
            };

            contracts.push(contract);
          });

          response.invoices.forEach(item => {
            const invoice: PolicyInvoice = {
              amount: UtilitiesService.parseDecimalString(item.invoice_amount),
              balance: UtilitiesService.parseDecimalString(item.balance),
              contractId: item.contract_id,
              invoiceId: item.invoice_id,
              status: item.status
            };

            invoices.push(invoice);
          });

          policy.claims = claims;
          policy.contracts = contracts;
          policy.invoices = invoices;

          return policy;
        }),
        catchError(this.handleError<any>('Get contracts for client: ' + clientId))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: better job of transforming error for user consumption
      console.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
