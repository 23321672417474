import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {NbDialogRef} from '@nebular/theme';

import {ClientService} from '../../../../services/client/client.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-export-dialog',
  templateUrl: './client-export-dialog.component.html',
  styleUrls: ['./client-export-dialog.component.scss']
})
export class ClientExportDialogComponent implements OnInit {

  filterForm: FormGroup;
  url?: string;
  sub: Subscription;

  public get canSave() {
    return !!this.url;
  }

  constructor(
    protected ref: NbDialogRef<ClientExportDialogComponent>,
    private clientService: ClientService,
  ) {
  }

  ngOnInit() {
    this.url = null;
    const params = new HttpParams()
      .set('length', '10000000')
      .set('search', this.filterForm.get('title').value)
      .set('businessUnit', this.filterForm.get('businessUnit').value)
      .set('relationType', this.filterForm.get('relationType').value)
      .set('clientType', this.filterForm.get('clientType').value)
      .set('status', this.filterForm.get('status').value)
      .set('markedForChecking', this.filterForm.get('markedForChecking').value)
      .set('accountManager', this.filterForm.get('accountManager').value);

    this.sub = this.clientService.clientsExcelExport(params)
      .subscribe(url => {
        this.url = url;
      });
  }

  onDownloadClick() {
    window.location.href = this.url;
    this.ref.close();
  }

  onCancelClick() {
    this.sub.unsubscribe();
    this.ref.close();
  }
}
