import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuardService as AuthGuard} from './services/auth-guard.service';
import {LoginComponent} from './components/login/login.component';
import {AddPageComponent} from './components/add-page/add-page.component';
import {AdminDashboardComponent} from './components/admin/admin-dashboard/admin-dashboard.component';
import {ContentOverviewComponent} from './components/admin/content-overview/content-overview.component';
import {EditPageComponent} from './components/admin/edit-page/edit-page.component';
import {NodeResolverService} from './services/resolver/node-resolver.service';
import {ClientComponent} from './components/client/client.component';
import {ClientResolverService} from './services/resolver/client-resolver.service';
import {ClientListComponent} from './components/client/client-list/client-list.component';
import { PolicyApplicationsComponent } from './components/policy-applications/policy-applications.component';
import {TasksComponent} from './components/task/tasks.component';
import {TasksResolverService} from './services/resolver/tasks-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    // TODO: redirect to authenticated user
    path: 'client',
    component: ClientComponent,
    resolve: {client: ClientResolverService}
  },
  {path: 'client/list', component: ClientListComponent, canActivate: [AuthGuard]},
  {path: 'client/list/inactive', component: ClientListComponent, canActivate: [AuthGuard]},
  {path: 'client/:gid', component: ClientComponent, resolve: {
    client: ClientResolverService}, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {path: 'policy-applications', component: PolicyApplicationsComponent, canActivate: [AuthGuard]},
  {path: 'tasks', component: TasksComponent, canActivate: [AuthGuard], resolve: {referencedData: TasksResolverService}, runGuardsAndResolvers: 'always' },
  {
    path: 'admin', canActivate: [AuthGuard], children: [
      {path: '', component: AdminDashboardComponent},
      {path: 'content/page/add', component: AddPageComponent},
      {path: 'content/page/:uuid/edit', component: EditPageComponent, resolve: {node: NodeResolverService}},
      {path: 'content/list', component: ContentOverviewComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
