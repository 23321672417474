<form [formGroup]="form" class="dialog-form" (ngSubmit)="onSubmit()" novalidate>
    <nb-card>
      <nb-card-header *ngIf="actionType === 'add'" i18n="@@addActivity">Add activity</nb-card-header>
      <nb-card-header *ngIf="actionType === 'edit'" i18n="@@editActivity">Edit activity</nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-12">
            <div class="form-item title">
              <label class="label" for="newActivityTitle" i18n="@@title">Title</label>
              <input nbInput fullWidth id="newActivityTitle" formControlName="title">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-item business-units" formGroupName="businessUnits">
              <label class="label" for="newActivityTitle" i18n="@@businessUnit">Business unit</label>
              <div style="clear:both">
                <nb-checkbox *ngFor="let item of form.get('businessUnits').value | keyvalue" [formControlName]="item.key" [ngModel]="item.value">
                    {{item.key}}
                </nb-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-item type">
              <label i18n="@@activityType" class="label">Activity type</label>
              <nb-select id="type" formControlName="type" fullWidth placeholder="Select activity type"
                         i18n-placeholder="@@activityTypePlaceholder">
                <nb-option *ngFor="let type of allowedActivityTypeTerms" [value]="type.id">
                  <nb-icon *ngIf="type.icon" icon="{{ type.icon }}"></nb-icon>{{ type.name }}
                </nb-option>
              </nb-select>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-item time-spend">
              <label class="label" for="time-spent" i18n="@@timeSpent">Time spent</label>
              <input nbInput fullWidth id="time-spent" formControlName="timeSpent">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-item">
              <label for="newActivityDate" class="label" i18n="@@date">Date</label>
              <input nbInput fullWidth id="newActivityDate" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" formControlName="dateTime" placeholder="Date Time">
              <owl-date-time #dt1></owl-date-time>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-item description">
              <label class="label" for="description" i18n="@@description">Description</label>
              <textarea name="description" formControlName="description" nbInput fullWidth id="description" rows="3"></textarea>
            </div>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="form-actions text-right">
          <input type="button" nbButton ghost status="primary" [disabled]="isSaving" (click)="cancelDialog()" value="Cancel" i18n-value="@@cancel">
          <button nbButton status="primary" type="submit" [disabled]="isSaving || form.invalid" i18n="@@submit">Submit</button>
        </div>
      </nb-card-footer>
    </nb-card>
  </form>
