<form *ngIf="form && !loading" [formGroup]="form" class="dialog-form" (ngSubmit)="onSubmit()" novalidate>
  <nb-card>
    <nb-card-header *ngIf="actionType === 'add'" i18n="@@addTask">Add task</nb-card-header>
    <nb-card-header *ngIf="actionType === 'edit'" class="d-flex justify-content-between">
      <span i18n="@@editTask">Edit task</span>
      <button class="btn btn-light btn-sm" (click)="confirmDelete()">
        <nb-icon class="text-danger" icon="trash-2-outline"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="form-item title">
            <label class="label" for="newTask" i18n="@@title">Title</label>
            <input nbInput
                   fullWidth
                   id="newTask"
                   formControlName="title"
                   [status]="(form.controls.title.invalid) ? 'danger' : 'basic'"
            >
          </div>
          <div class="client-section">
            <div class="form-item client">
              <label class="label" for="client_select" i18n="@@client">Client</label>
              <input id="client_select" nbInput fullWidth (keyup)="onSearchClientChange()"
                     (blur)="onSearchClientBlur()" formControlName="client" type="text" i18n-placeholder="@@searchClient" placeholder="Search client" />
            </div>
            <div class="client-search-results">
              <ul class="autocomplete"
                  *ngIf="showClientOptions && !isLoadingClientOptions && clientOptions && clientOptions.length">
                <li *ngFor="let option of clientOptions" (mousedown)="onSelectClient(option, $event)">
                  {{ option.title }}
                </li>
              </ul>
              <div class="loading" *ngIf="showClientOptions && isLoadingClientOptions" nbSpinner="true"
                   nbSpinnerStatus="primary" nbSpinnerSize="tiny">
              </div>
            </div>
          </div>
          <div class="form-item business-units" formGroupName="businessUnits">
            <label class="label" for="" i18n="@@businessUnits">Business units</label>
            <div class="checkboxes">
              <div class="checkox" *ngFor="let item of form.get('businessUnits').value | keyvalue">
                <nb-checkbox [formControlName]="item.key" [checked]="item.value">
                  {{item.key}}
                </nb-checkbox>
              </div>
            </div>
          </div>
          <div class="form-item">
            <label for="policy" class="label" i18n="@@policy">Policy</label>
            <input nbInput
                   fullWidth
                   id="policy"
                   formControlName="policy"
            >
          </div>
          <div class="form-item">
            <label for="dossier" class="label" i18n="@@dossier">Dossier</label>
            <input nbInput
                   fullWidth
                   id="dossier"
                   formControlName="dossier"
            >
          </div>
          <div class="form-item description">
            <label class="label" for="description" i18n="@@description">Description</label>
            <textarea name="description"
                      formControlName="description"
                      nbInput
                      fullWidth
                      id="description"
                      rows="3"
                      [status]="(form.controls.description.invalid) ? 'danger' : 'basic'"
            ></textarea>
          </div>
          <div class="form-item memo">
            <label class="label" for="memo" i18n="@@memo">Memo</label>
            <textarea name="memo" formControlName="memo" nbInput fullWidth id="memo" rows="3"></textarea>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-item status">
            <label i18n="@@taskStatus" class="label">Task status</label>
            <nb-select id="status"
                       formControlName="status"
                       fullWidth
                       placeholder="Select task status"
                       [status]="(form.controls.status.invalid) ? 'danger' : 'basic'"
                       i18n-placeholder="@@taskStatusPlaceholder"
            >
              <nb-option *ngFor="let status of taskStatusTerms" [value]="status.id">
                {{ status.name }}
              </nb-option>
            </nb-select>
          </div>
          <div class="form-item">
            <label i18n="@@taskType" class="label">Task type</label>
            <nb-select id="type" formControlName="type" fullWidth placeholder="Select task type"
                       [status]="(form.controls.type.invalid) ? 'danger' : 'basic'"
                       i18n-placeholder="@@taskTypePlaceholder"
            >
              <nb-option *ngFor="let type of taskTypeTerms" [value]="type.id">
                <nb-icon *ngIf="type.icon" icon="{{ type.icon }}"></nb-icon>{{ type.name }}
              </nb-option>
            </nb-select>
          </div>
          <div class="form-item" *ngIf="task && task.dateCreated">
            <label for="dateCreated" class="label" i18n="@@dateCreated">Date created</label>
            <input nbInput
                   fullWidth
                   id="dateCreated"
                   readonly
                   disabled
                   [value]="task.dateCreated | date: 'd-M-yyyy HH:mm'"
            >
          </div>
          <div class="form-item">
            <label for="taskExpireDate" class="label" i18n="@@expireDate">Expire date</label>
            <input nbInput
                   fullWidth
                   id="taskExpireDate"
                   [owlDateTime]="dt1"
                   [owlDateTimeTrigger]="dt1"
                   formControlName="expireDate"
                   placeholder="Date Time"
                   [status]="(form.controls.expireDate.invalid) ? 'danger' : 'basic'"
            >
            <owl-date-time #dt1 pickerType="both"></owl-date-time>
          </div>
          <div class="form-item employee">
            <label i18n="@@employee" class="label">Employee</label>
            <nb-select id="assignee"
                       formControlName="assignee"
                       fullWidth
                       placeholder="Select employee"
                       i18n-placeholder="@@taskEmployeePlaceholder"
            >
              <nb-option value="">
                <span i18n="@@unassigned">Unassigned</span>
              </nb-option>
              <nb-option *ngFor="let accountManager of accountManagers" [value]="accountManager.uuid">
                {{ accountManager.fullName || accountManager.username }}
              </nb-option>
            </nb-select>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="form-actions text-right">
        <input type="button" nbButton ghost status="primary" [disabled]="isSaving" (click)="cancelDialog()" value="Cancel" i18n-value="@@cancel">
        <button nbButton status="primary" type="submit" [disabled]="isSaving || form.invalid" i18n="@@submit">Submit</button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
