import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {Address} from '../../../../models/address';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {Subscription} from 'rxjs';
import {SolarifToastrService} from '../../../../services/toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {LockingService} from '../../../../services/locking/locking.service';
import {Socket} from 'ngx-socket-io';
import {Client} from '../../../../models/client/client';
import {AddressService} from '../../../../services/address/address.service';
import {TaxonomyService} from '../../../../services/taxonomy/taxonomy.service';
import {patterns} from '../../../../validators/patterns';
import {TaxonomyTerm} from '../../../../models/terms/taxonomyTerm';

@Component({
  selector: 'app-client-address',
  templateUrl: './client-address.component.html',
  styleUrls: ['./client-address.component.scss']
})
export class ClientAddressComponent implements OnInit, OnDestroy {

  componentName = 'client-address';

  @Input() client: Client;
  @Input() address: Address;
  @Output() deleted = new EventEmitter<string>();

  editAddressForm: FormGroup;
  zipcodeControl: AbstractControl;
  countryControl: AbstractControl;
  subscription = new Subscription();
  lockingSubscription = new Subscription();
  currentLock = 0;

  isUnsupportedCountry = false;
  private supportedCountryCodes = [
    'NL', 'DE', 'BE'
  ];

  @Input() addressTypes: Array<TaxonomyTerm>;

  constructor(private addressService: AddressService,
              private toastrService: SolarifToastrService,
              private dialogService: NbDialogService,
              private lockingService: LockingService,
              private taxonomyService: TaxonomyService,
              private socket: Socket) {
  }

  ngOnInit() {

    this.resetForm();

    this.zipcodeControl = this.editAddressForm.get('postalCode');
    this.countryControl = this.editAddressForm.get('countryCode');

    this.setPostalCodeValidation(this.editAddressForm.controls.countryCode.value);

    const changeSubscription = this.countryControl.valueChanges.subscribe(value => {
      this.setPostalCodeValidation(value);
    });

    this.subscription.add(changeSubscription);
    // this.lockingSubscription = this.socket.fromEvent<any>(this.componentName).subscribe(currentLock => this.currentLock = currentLock);

  }

  ngOnDestroy(): void {
    this.removeLock();
    this.subscription.unsubscribe();
    this.lockingSubscription.unsubscribe();
  }

  private resetForm() {
    let countryCode;
    if (this.supportedCountryCodes.includes(this.address.country_code)) {
     countryCode = this.address.country_code;
    } else {
      if (this.address.country_code !== undefined || this.address.country_code !== null) {
        this.isUnsupportedCountry = true;
      }
      countryCode = null;
    }

    this.editAddressForm = new FormGroup({
      title: new FormControl(this.address.title, Validators.required),
      type: new FormControl(this.address.type.uuid, Validators.required),
      countryCode: new FormControl(countryCode, Validators.required),
      postalCode: new FormControl(this.address.postal_code),
      addressLine: new FormControl(this.address.address_line1),
      addressLineSecondary: new FormControl(this.address.address_line2),
      isPostAddress: new FormControl(this.address.is_post_address),
      locality: new FormControl(this.address.locality)
    });

    if (this.isUnsupportedCountry) {
      this.editAddressForm.disable();
    }
  }

  onCountryUpdated(): void {
    this.setPostalCodeValidation(this.editAddressForm.controls.countryCode.value);
  }

  private setPostalCodeValidation(countryCode: string) {
    console.log('setPostalCodeValidation', countryCode);
    switch (countryCode) {
      case 'NL':
        this.zipcodeControl.enable();
        this.zipcodeControl.setValidators([Validators.pattern(patterns.zipCodes.nl)]);
        break;

      case 'DE':
        this.zipcodeControl.enable();
        this.zipcodeControl.setValidators([Validators.pattern(patterns.zipCodes.de)]);
        break;

      case 'BE':
        this.zipcodeControl.enable();
        this.zipcodeControl.setValidators([Validators.pattern(patterns.zipCodes.be)]);
        break;

      default:
        console.log('should not be valid');
        this.editAddressForm.controls.countryCode.setErrors({'incorrect': true});
        // this.editAddressForm.controls.countryCode.updateValueAndValidity();
    }
    this.zipcodeControl.updateValueAndValidity();
  }

  openEditDialog(dialog: TemplateRef<any>) {
    this.addLock();
    this.dialogService.open(dialog).onClose.subscribe(() => {
      this.resetForm();
      this.removeLock();
    });
  }

  cancelEdit(ref: NbDialogRef<any>) {
    this.resetForm();
    ref.close();
  }

  onSubmit(ref: NbDialogRef<any>) {

    const address: Address = {
      address_line1: this.editAddressForm.get('addressLine').value,
      address_line2: this.editAddressForm.get('addressLineSecondary').value,
      country_code: this.editAddressForm.get('countryCode').value,
      is_post_address: this.editAddressForm.get('isPostAddress').value || false,
      locality: this.editAddressForm.get('locality').value,
      postal_code: this.editAddressForm.get('postalCode').value,
      title: this.editAddressForm.get('title').value,
      uuid: this.address.uuid,
      type: {
        uuid: this.editAddressForm.get('type').value,
        name: this.addressTypes.filter(type => type.id === this.editAddressForm.get('type').value)[0].name
      }

    };

    const patchSubscription = this.addressService.updateAddress(address).subscribe(result => {
      this.toastrService.success(
        $localize`:@@addressUpdateSuccess:Address has been updated.`
      );

      this.address.title = address.title;
      this.address.country_code = address.country_code;
      this.address.postal_code = address.postal_code;
      this.address.address_line1 = address.address_line1;
      this.address.address_line2 = address.address_line2;
      this.address.locality = address.locality;
      this.address.is_post_address = address.is_post_address;
      this.address.type.uuid = address.type.uuid;
      this.address.type.name = address.type.name;

      ref.close();
    }, error => {
      this.toastrService.error(
        $localize`:@@addressUpdateFailure:Address info could not be updated.`
      );
    });

    this.subscription.add(patchSubscription);

  }

  confirmDelete(ref, deleteConfirm: TemplateRef<any>) {
    ref.close();
    this.dialogService.open(deleteConfirm);
  }

  cancelDelete(ref) {
    ref.close();
  }

  deleteAddress(ref) {
    const deleteSubscription = this.addressService.deleteAddress(this.address).subscribe(result => {
      this.toastrService.success(
        $localize`:@@addressDeleteSuccess:Address deleted.`
      );
      this.deleted.emit(this.address.uuid);
      ref.close();
    }, error => {
      this.toastrService.error(
        $localize`:@@addressDeleteFailure:Unable to delete address.`
      );
    });
    this.subscription.add(deleteSubscription);
  }

  private addLock() {
    this.lockingService.addLock(this.client.id, this.componentName);
  }

  private removeLock() {
    this.lockingService.removeLock(this.client.id, this.componentName);
  }
}

