import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatOptionValue'
})
export class FormatOptionValuePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return value.toLowerCase().trim().replace(/\s/g, '-');
  }

}
