import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {NbToastrService} from '@nebular/theme';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-page',
  templateUrl: './add-page.component.html',
  styleUrls: ['./add-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddPageComponent implements OnInit {

  pageData = {title: null, body: null};
  @ViewChild('form') form: any;

  public Editor = ClassicEditor;

  constructor(private http: HttpClient, private toastrService: NbToastrService,  private router: Router) {
  }

  ngOnInit() {
  }

  createPage() {
    const node = {
      data: {
        type: 'node--page',
        attributes: {
          title: this.form.value.title,
          body: {
            value: this.form.value.body,
            format: 'full_html'
          }
        }
      },
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
      })
    };

    this.http.post(environment.drupalUrl + '/jsonapi/node/page', node, httpOptions).subscribe(result => {
      this.toastrService.show('Node has been saved.', 'Saved', {status: 'success', icon: 'checkmark-outline'});
      this.router.navigateByUrl('/admin/content/list');
    }, error => {
      this.toastrService.show('Node could not be saved.', 'Error', {status: 'danger', icon: 'close-outline'});
      console.log(error);
    });
  }

}
