import {Injectable} from '@angular/core';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {Client} from '../../models/client/client';
import {HttpClient, HttpParams} from '@angular/common/http';
import {forkJoin, from, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, expand, flatMap, map, mergeMap, reduce, tap} from 'rxjs/operators';
import {JsonApiResponseMany, JsonApiResponseSingle, Resource} from '../../models/api/jsonapi.models';
import {Address} from '../../models/address';
import {AuthService} from '../auth.service';
import {IdentityClaims} from '../../models/identityClaims';
import {ATP} from '../../models/atp';
import {ContactService} from '../contact/contact.service';
import {ActivityService} from '../activity/activity.service';
import {Activity} from '../../models/activity/activity';
import {ClientHierarchy} from '../../models/client/client-hierarchy';
import {TaxonomyTerm} from '../../models/terms/taxonomyTerm';
import {ClientContact} from '../../models/client/clientcontact';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private includeTypes = [
    'field_ref_address',
    'field_ref_atp',
    'field_ref_business_unit',
    'field_ref_branch',
    'field_ref_child_clients',
    'field_ref_client_type',
    'field_ref_legal_form',
    'field_ref_postaddress',
    'field_ref_relation_type',
    'field_ref_segment',
    'field_ref_accountmanager',
    'field_ref_accountmanager.field_bus_unit_accountmanager',
    'field_ref_assignee',
    'field_ref_address.field_ref_type'
  ];

  private fieldsToInclude = ['fields[taxonomy_term--client_type]=name'];

  constructor(private httpClient: HttpClient,
              private authService: AuthService,
              private contactService: ContactService,
              private activityService: ActivityService
  ) {
  }

  public get identityClaims(): IdentityClaims {
    return this.authService.identityClaims;
  }

  public clients(params?: HttpParams): Observable<any> {
    return this.httpClient.get<any>(environment.drupalUrl + '/api/group?_format=json', {params});
  }


  public client(clientId: string, includeTypes = this.includeTypes, fieldsToInclude = this.fieldsToInclude): Observable<Client> {

    const url = ClientService.buildClientUrl(includeTypes, fieldsToInclude, clientId);
    console.log(`Get client url - `, url);
    return this.httpClient.get<JsonApiResponseSingle>(url)
      .pipe(
        tap(client => {
          const outcome = client ? `fetched` : `did not find`;
          // console.log(`${outcome} client id=${clientId}`);
        }),
        map(response => {
          let client = this.mapClientResource(response.data);
          client = this.mapClientInclusions(response, client);
          return client;
        }),
        catchError(this.handleError<any>('client'))
      );
  }

  public clientsExcelExport(params?: HttpParams) {

    interface ExcelResponse {
      url: string;
    }

    return this.clients(params).pipe(
      flatMap(response => {
        if (!response.data) {
          return of(null);
        }

        const headers = [
          $localize`:@@title:Title`,
          $localize`:@@email:E-mail`,
          $localize`:@@businessUnit:Business unit`,
          $localize`:@@relationType:Relation type`,
          $localize`:@@clientType:Client type`,
          $localize`:@@markedForChecking:Marked for checking`,
          $localize`:@@status:Status`,
          $localize`:@@contracts:Contracts`,
          $localize`:@@accountManager:Account manager`,
        ];

        console.log('Excel export get clients response data', response.data);

        const rows = response.data.map(row => [
          row.label,
          row.email,
          row.business_unit,
          row.relation_type,
          row.client_type,
          row.marked_for_checking,
          row.status,
          row.contract_count,
          row.account_manager
        ]);
        const data = {
          filename: 'Klantoverzicht',
          data: [headers, ...rows]
        };
        return this.httpClient.post<ExcelResponse | null>(environment.drupalUrl + '/api/excel/create', data);
      }),
      map((response) => {
        if (!response || !response.url) {
          return false;
        }
        return response.url;
      })
    );
  }

  public contacts(client: Client): Observable<{ normal: Array<ClientContact>, primary: Array<ClientContact> }> {
    return this.contactService.contacts(client);
  }

  public activities(clientId: string): Observable<Array<Activity>> {
    return this.activityService.activities(clientId);
  }

  public add(title: string, clientType: string, relationType: string, businessUnitUuid: string, accountManager: string = null) {

    const accountManagerUuid = accountManager || this.identityClaims.uuid;

    const addAccountManager$ = () => {
      const body = {
        data: {
          type: 'client--accountmanager',
          attributes: {
            title: `Account manager ${accountManagerUuid} for business unit ${businessUnitUuid}`,
          },
          relationships: {
            field_bus_unit_accountmanager: {
              data: {
                type: 'user--user',
                id: accountManagerUuid
              }
            },
            field_business_unit: {
              data: {
                type: 'taxonomy_term--business_unit',
                id: businessUnitUuid
              }
            },
            uid: {
              data: {
                type: 'user--user',
                id: this.identityClaims.uuid
              }
            }
          }
        }
      };
      return this.httpClient.post(environment.drupalUrl + '/jsonapi/client/accountmanager/', body);
    };

    const createClient$ = (accountManagerResponse) => {
      const clientGroupData = {
        data: {
          type: 'group--relation',
          attributes: {
            label: title,
            field_marked_for_checking: false
          },
          relationships: {
            field_ref_client_type: {
              data: {
                type: 'taxonomy_term--client_type',
                id: clientType
              }
            },
            field_ref_relation_type: {
              data: {
                type: 'taxonomy_term--relatie_type',
                id: relationType
              }
            },
            field_ref_business_unit: {
              data: {
                type: 'taxonomy_term--business_unit',
                id: businessUnitUuid
              }
            },
            field_ref_accountmanager: {
              data: [
                {
                  type: 'client--accountmanager',
                  id: accountManagerResponse.data.id
                }
              ]
            },
            uid: {
              data: {
                type: 'user--user',
                id: this.identityClaims.uuid
              }
            }
          }

        }
      };
      return this.httpClient.post<any>(environment.drupalUrl + '/jsonapi/group/relation', clientGroupData);
    };
    return addAccountManager$().pipe(
      flatMap(response => createClient$(response))
    );
  }

  public updateTitle(clientId: string, title: string) {
    const body = {
      data: {
        type: 'group--relation',
        id: clientId,
        attributes: {
          label: title,
        }
      }
    };

    return this.httpClient.patch(environment.drupalUrl + '/jsonapi/group/relation/' + clientId, body);
  }

  public updateContactInfo(contactInfo: { clientId: string, email: string, phone: string, website: string }): Observable<any> {
    const body = {
      data: {
        type: 'group--relation',
        id: contactInfo.clientId,
        attributes: {
          field_email: contactInfo.email,
          field_phone: contactInfo.phone,
          field_website: contactInfo.website,
        }
      }
    };

    return this.httpClient.patch(environment.drupalUrl + '/jsonapi/group/relation/' + contactInfo.clientId, body);
  }


  public updateClientParent(client: Client, newParentId: string) {
    const id = client.id;
    const oldParentId = client.hierarchy && client.hierarchy.parents.length ? client.hierarchy.parents[0].id : null;
    console.log('updateClientParent', {id, oldParentId, newParentId});

    if (oldParentId === newParentId) {
      return of({});
    }

    const updateParentClient$ = (parentId: string, childClients: any[]) => {
      const body = {
        data: {
          type: 'group--relation',
          id: parentId,
          relationships: {
            field_ref_child_clients: {
              data: childClients,
            },
          }
        }
      };
      return this.httpClient.patch(environment.drupalUrl + '/jsonapi/group/relation/' + parentId, body);
    };

    const url = (groupId: string) => environment.drupalUrl + '/jsonapi/group/relation/' + groupId;
    const maybeGetOldParent$ = oldParentId ? this.httpClient.get(url(oldParentId)) : of({});
    const maybeGetNewParent$ = newParentId ? this.httpClient.get(url(newParentId)) : of({});

    return maybeGetOldParent$.pipe(
      flatMap((res: any) => {
        if (!oldParentId) {
          return of({});
        }

        let clients = res.data.relationships.field_ref_child_clients.data;
        clients = clients.filter(x => x.id !== id);
        return updateParentClient$(oldParentId, clients);
      })
    ).pipe(flatMap(() => {
      return maybeGetNewParent$.pipe(
        flatMap((res: any) => {
          if (!newParentId) {
            return of({});
          }

          const clients = res.data.relationships.field_ref_child_clients.data;

          clients.forEach((client, key) => {
            if(client.id == 'missing') {
              clients.splice(key, 1);
            }
          });

          clients.push({type: 'group--relation', id});
          return updateParentClient$(newParentId, clients);
        })
      );
    }));
  }

  public addAndAssignAccountManager(client: Client, accountManagerUuid: string, businessUnitUuid: string) {

    const addAccountManager$ = () => {
      const body = {
        data: {
          type: 'client--accountmanager',
          attributes: {
            title: `Account manager ${accountManagerUuid} for business unit ${businessUnitUuid}`,
          },
          relationships: {
            field_bus_unit_accountmanager: {
              data: {
                type: 'user--user',
                id: accountManagerUuid
              }
            },
            field_business_unit: {
              data: {
                type: 'taxonomy_term--business_unit',
                id: businessUnitUuid
              }
            }
          }
        }
      };

      return this.httpClient.post(environment.drupalUrl + '/jsonapi/client/accountmanager/', body);
    };

    const updateClient$ = (relationUuid: string) => {

      const currentAccountManagers = client.accountManagers.map(x => ({
        type: 'client--accountmanager',
        id: x.relationUuid
      }));

      const body = {
        data: {
          type: 'group--relation',
          id: client.id,
          relationships: {
            field_ref_accountmanager: {
              data: [
                ...currentAccountManagers,
                {
                  type: 'client--accountmanager',
                  id: relationUuid
                }
              ]
            },
          }
        }
      };
      return this.httpClient.patch(environment.drupalUrl + '/jsonapi/group/relation/' + client.id, body);
    };

    return addAccountManager$().pipe(
      flatMap((res: any) => updateClient$(res.data.id))
    );
  }

  public updateAssignedAccountManager(relationUuid: string, accountManagerUuid: string, businessUnitUuid: string) {

    const body = {
      data: {
        type: 'client--accountmanager',
        id: relationUuid,
        relationships: {
          field_bus_unit_accountmanager: {
            data: {
              type: 'user--user',
              id: accountManagerUuid
            }
          },
          field_business_unit: {
            data: {
              type: 'taxonomy_term--business_unit',
              id: businessUnitUuid
            }
          }
        }
      }
    };

    return this.httpClient.patch(environment.drupalUrl + '/jsonapi/client/accountmanager/' + relationUuid, body);
  }

  public unassignAccountManager(client: Client, relationUuid: string) {

    const deleteAccountManager$ = () => {
      return this.httpClient.delete(environment.drupalUrl + '/jsonapi/client/accountmanager/' + relationUuid);
    };

    const updateClient$ = () => {

      const accountManagers = client.accountManagers.map(x => ({
        type: 'client--accountmanager',
        id: x.relationUuid
      })).filter(x => x.id !== relationUuid);

      const body = {
        data: {
          type: 'group--relation',
          id: client.id,
          relationships: {
            field_ref_accountmanager: {
              data: accountManagers
            },
          }
        }
      };
      return this.httpClient.patch(environment.drupalUrl + '/jsonapi/group/relation/' + client.id, body);
    };

    return deleteAccountManager$().pipe(
      flatMap(updateClient$)
    );
  }

  public hierarchy(clientId: string): Observable<ClientHierarchy> {
    return this.httpClient.get<ClientHierarchy>(`${environment.drupalUrl}/api/group/${clientId}/hierarchy/`);
  }

  public search(text: string): Observable<Array<Client>> {
    return this.httpClient.get<JsonApiResponseMany>(`${environment.drupalUrl}/jsonapi/group/relation?filter[label][operator]=CONTAINS&filter[label][value]=${text}`)
      .pipe(
        // expand((response) => {
        //   if (response && response.links && response.links.next) {
        //     console.log(`client search() NEXT`, response.links.next.href);
        //     return this.httpClient.get<any>(response.links.next.href);
        //   } else {
        //     // console.log(`getTermsByVocabulary ${vocabulary} END`);
        //     return EMPTY;
        //   }
        // }),
        map(response => {
          const clients: Array<Client> = [];
          response.data.forEach(item => {
            const client = this.mapClientResource(item);
            clients.push(client);
          });

          console.log('Found in search', clients);

          return clients;
        }),
        // reduce((acc, x: any) =>
        //   acc.concat(x), []
        // ),
      );
  }

  public mapClientResource(response: Resource) {
    // console.log('mapClientResource', response.data);
    const client: Client = {
      addresses: [],
      cocNumber: response.attributes.field_coc,
      contactInfo: {
        email: response.attributes.field_email,
        phone: response.attributes.field_phone,
        website: (response.attributes.field_website) ? response.attributes.field_website.uri : null,
      },
      debtorNumber: response.attributes.field_debtor_number,
      lastEdit: new Date(response.attributes.changed),
      id: response.id,
      internal_id: response.attributes.drupal_internal__id,
      isMarkedForChecking: (response.attributes.field_marked_for_checking === true),
      status: response.attributes.field_status,
      title: response.attributes.label,
      legalForm: null,
      branch: [],
      businessUnits: [],
      relationType: [],
      clientType: null,
      memo: (response.attributes.field_memo) ? response.attributes.field_memo.value : null,
    };
    return client;
  }

  private mapClientInclusions(response: JsonApiResponseSingle, client: Client) {

    client.accountManagers = client.accountManagers || [];

    if (response.included) {
      response.included.forEach(inclusion => {
        switch (inclusion.type) {
          case 'client--accountmanager':
            const businessUnitId = inclusion.relationships.field_business_unit.data.id;
            const accountManagerId = inclusion.relationships.field_bus_unit_accountmanager.data.id;
            const businessUnitTerm = response.included.find(x => x.id === businessUnitId);
            const accountManager = response.included.find(x => x.id === accountManagerId);

            if (businessUnitTerm && accountManager) {
              client.accountManagers.push({
                uuid: accountManager.id,
                email: accountManager.attributes.mail,
                username: accountManager.attributes.name,
                fullName: accountManager.attributes.field_full_name,
                businessUnit: businessUnitTerm.attributes.name,
                relationUuid: inclusion.id,
              });
            }

            break;

          case 'client--address':
            if (inclusion.attributes.field_address !== null) {

              const address: Address = {
                uuid: inclusion.id,
                title: inclusion.attributes.title,
                langcode: inclusion.attributes.field_address.langcode,
                country_code: inclusion.attributes.field_address.country_code,
                administrative_area: inclusion.attributes.field_address.administrative_area,
                dependent_locality: inclusion.attributes.field_address.dependent_locality,
                postal_code: inclusion.attributes.field_address.postal_code,
                locality: inclusion.attributes.field_address.locality,
                sorting_code: inclusion.attributes.field_address.sorting_code,
                address_line1: inclusion.attributes.field_address.address_line1,
                address_line2: inclusion.attributes.field_address.address_line2,
                is_post_address: inclusion.attributes.field_is_post_address,
                type: {
                  uuid: null,
                  name: null
                }
              };

              if (inclusion.relationships.field_ref_type.data) {
                address.type = {
                  uuid: inclusion.relationships.field_ref_type.data.id
                };
              }

              client.addresses.push(address);
            }
            break;

          case 'taxonomy_term--adres_type':
            client.addresses.map(address => {
              if (address.type && address.type.uuid === inclusion.id) {
                address.type.name = inclusion.attributes.name;
              }
            });
            break;

          case 'atp--atp':
            const atp: ATP = {
              contactFirstName: inclusion.attributes.field_contact_first_name,
              contactInsertion: inclusion.attributes.field_contact_insertion,
              contactLastName: inclusion.attributes.field_contact_last_name,
              emailAddress: inclusion.attributes.field_emailaddress,
              id: inclusion.id,
              memo: inclusion.attributes.field_memo.replace(/<[^>]*>?/gm, ''),
              phoneNumber: inclusion.attributes.field_phonenumber,
              title: inclusion.attributes.title,
              website: inclusion.attributes.field_website,
            };
            client.atp = atp;
            break;

          case 'taxonomy_term--branch':
            const branchTerm: TaxonomyTerm = {
              id: inclusion.id,
              type: inclusion.type,
              weight: inclusion.attributes.weight,
              name: inclusion.attributes.name,
              status: inclusion.attributes.status,
              termId: inclusion.attributes.drupal_internal__tid
            };
            client.branch.push(branchTerm);
            break;

          case 'taxonomy_term--business_unit':
            const businessUnit: TaxonomyTerm = {
              id: inclusion.id,
              type: inclusion.type,
              weight: inclusion.attributes.weight,
              name: inclusion.attributes.name,
              status: inclusion.attributes.status,
              termId: inclusion.attributes.drupal_internal__tid
            };
            client.businessUnits.push(businessUnit);
            break;

          case 'taxonomy_term--client_type':
            const clientTypeTerm: TaxonomyTerm = {
              id: inclusion.id,
              type: inclusion.type,
              weight: inclusion.attributes.weight,
              name: inclusion.attributes.name,
              status: inclusion.attributes.status,
              termId: inclusion.attributes.drupal_internal__tid
            };
            client.clientType = clientTypeTerm;
            break;

          case 'taxonomy_term--rechtsvorm':
            const legalForm: TaxonomyTerm = {
              id: inclusion.id,
              type: inclusion.type,
              weight: inclusion.attributes.weight,
              name: inclusion.attributes.name,
              status: inclusion.attributes.status,
              termId: inclusion.attributes.drupal_internal__tid
            };
            client.legalForm = legalForm;
            break;

          case 'taxonomy_term--relatie_type':
            const relationTerm: TaxonomyTerm = {
              id: inclusion.id,
              type: inclusion.type,
              weight: inclusion.attributes.weight,
              name: inclusion.attributes.name,
              status: inclusion.attributes.status,
              termId: inclusion.attributes.drupal_internal__tid
            };
            client.relationType.push(relationTerm);
            break;
        }
      });
    }
    return client;
  }

  private static buildClientUrl(includeTypes: Array<string>, fieldsToInclude: Array<string>, id: string = null) {
    const destination = environment.drupalUrl;
    const pathToSource = 'jsonapi/group/relation/';

    // Build url
    let url = `${destination}/${pathToSource}`;

    if (id !== null) {
      url += `${id}/`;
    }

    if (includeTypes && includeTypes.length > 0 && fieldsToInclude && fieldsToInclude.length > 0) {
      url += `?include=${includeTypes.join(',')}&${fieldsToInclude.join('&')}`;
    }

    return url;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
