import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ProductResponse} from '../../models/api/solarif_rest.models';
import {Product} from '../../models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) { }

  public products(): Observable<Array<Product>> {
    const url = `${environment.drupalUrl}/api/product`;

    return this.httpClient.get<ProductResponse>(url)
      .pipe(
        map(response => {
          let data = [];
          const products: Array<Product> = [];

          data = JSON.parse(response.data);

          if (data['error']) {
            console.log('ProductService unable to receive products. Response: ', data);
          }

          data.forEach(item => {
            const product: Product = {
              branchIdentifier: item.branch_identifier,
              businessChannels: item.business_channels,
              description: item.description,
              productId: item.product_id,
              productIdentifier: item.product_identifier,
              productLink: item.product_link
            };
            products.push(product);
          });

          return products;
        }),
        catchError(this.handleError<any>('Get products'))
      );
  }

  public selectProducts(activityId: string, products: Array<string>, selectedAddressUuid: string, userUuid: string): Observable<HttpResponse<Object>> {

    const url = `${environment.drupalUrl}/api/product?_format=json`;

    const body = {
      activityId,
      products,
      selectedAddressUuid,
      userUuid
    };

    console.log('selectProducts uuid', userUuid);

    return this.httpClient.post(url, body, { observe: 'response' });
  }

  public makeQuotation(
    activityId: string,
    businessAddressUuid: string,
    riskAddressUuid: string,
    calculationActivityId: string,
    product: string,
    userUuid: string
  ): Observable<HttpResponse<Object>> {
    const url = `${environment.drupalUrl}/api/quotation`;

    const body = {
      activityId,
      businessAddressUuid,
      riskAddressUuid,
      calculationActivityId,
      product,
      userUuid
    };

    return this.httpClient.post(url, body, { observe: 'response' });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
