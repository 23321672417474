<div class="task-list-item" *ngIf="task">
  <div class="section-left">
    <div class="user-picture initials d-flex" [title]="assigneeName">
      {{ getInitials(assigneeName) }}
    </div>
    <div class="info-left">
      <div class="title" (click)="openEditDialog()">
        {{task.title}}
      </div>
      <div class="subtitle">
        <div class="type">
          <nb-icon *ngIf="type.icon" [icon]="type.icon"></nb-icon>{{type.name}}
        </div>
        <div class="seperator" *ngIf="task.clientId && task.client">
          -
        </div>
        <div class="client">
          <span *ngIf="task.clientId && task.client">
            <a href="client/{{task.client.id}}" class="client">{{ task.client.title }}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
<!--  <div class="section-middle">-->

<!--  </div>-->
  <div class="section-right">
<!--    <div class="info-left">-->

<!--    </div>-->
    <div class="info-right">
      <div class="status">
        {{status}}
      </div>
      <div class="date">
        {{task.expireDate | taskDate}}
      </div>
<!--      <ng-template #dateFinished>-->
        <div class="date-finished" *ngIf="task.dateFinished">
          {{task.dateFinished | taskDate}}
        </div>
<!--      </ng-template>-->
    </div>
    <div class="actions" *ngIf="!viewOnly">
      <button class="btn btn-light btn-sm d-inline" (click)="openEditDialog()">
        <nb-icon icon="edit-outline"></nb-icon>
      </button>
    </div>
  </div>
</div>
