import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

interface ExcelResponse { url: string; }

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor(private httpClient: HttpClient) { }

  export(headers: Array<any>, rows: Array<any>, filename: string): Observable<ExcelResponse | null> {

    const data = {
      filename,
      data: [headers, ...rows]
    };
    console.log(`Exporting data to ${filename}`, data);
    return this.httpClient.post<ExcelResponse | null>(environment.drupalUrl + '/api/excel/create', data);
  }
}
