<nb-card>
  <nb-card-header class="d-flex justify-content-between align-items-baseline">
    <span i18n="@@memo">Memo</span>
    <button class="btn btn-light btn-sm" (click)="openEditDialog(editDialog)">
      <nb-icon icon="edit-outline"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body>
    {{ client.memo }}
  </nb-card-body>
</nb-card>

<ng-template #editDialog let-ref="dialogRef">
  <form [formGroup]="memoForm" class="dialog-form" (ngSubmit)="onSubmit(ref)" novalidate>
    <nb-card>
      <nb-card-header i18n="@@editMemo">Edit memo</nb-card-header>
      <nb-card-body>
        <div class="form-item">
          <label class="label" for="memo" i18n="@@memo">Memo</label>
          <textarea nbInput fullWidth name="memo" formControlName="memo" id="memo" cols="30" rows="10"></textarea>
          <div *ngIf="memo.invalid && memo.errors.maxlength"
               class="alert alert-danger">
            <small class="label" i18n="@@errorMemoLengthMax">Memo text is to long</small>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="form-actions text-right">
          <input type="button" nbButton ghost status="primary" (click)="cancelEdit(ref)" value="Cancel"
                 i18n-value="@@cancel">
          <button nbButton status="primary" type="submit" [disabled]="memoForm.invalid" i18n="@@submit">
            Submit
          </button>
        </div>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
