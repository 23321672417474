import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NbMenuBag, NbMenuItem, NbMenuService, NbSidebarService} from '@nebular/theme';
import {AuthService} from '../../../services/auth.service';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {AuthConfig} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {

  loggedInMenu = [
    // {
    //   title: this.i18n({value: 'My profile', id: 'myProfileMenuItem'}),
    //   icon: 'eye-outline',
    //   data: {
    //     id: 'client'
    //   }
    // },
    // {
    //   title: 'Edit profile',
    //   icon: 'edit-2-outline',
    //   data: {
    //     id: 'edit-profile'
    //   }
    // },
    {
      title: $localize`:@@logout:Log out`,
      icon: 'log-out-outline',
      data: {
        id: 'logout'
      }
    }];

  loggedOutMenu = [{
    title: $localize`:@@login:Log in`,
    icon: 'log-in-outline',
    data: {
      id: 'login'
    }
  }];

  isAuthenticated: Observable<boolean>;
  username?: string;

  constructor(private sidebarService: NbSidebarService,
              private authService: AuthService,
              private nbMenuService: NbMenuService,
              private authConfig: AuthConfig,
              private router: Router) {
    this.isAuthenticated = this.authService.isAuthenticated$;
    this.isAuthenticated.subscribe(isAuth => {
     if (isAuth) {
       // @ts-ignore
       this.username = this.authService.identityClaims.name;
     }
   });
  }

  ngOnInit() {
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === 'user-menu'),
      )
      .subscribe((item: NbMenuBag) => {
        switch (item.item.data.id) {
          case 'logout':
            this.authService.logout();
            break;
          case 'login':
            this.authService.login();
            break;
          // case 'edit-profile':
          //   window.location.href =
          //     environment.drupalUrl + '/user/edit?redirectUri=' + window.location + '&clientId=' + this.authConfig.clientId;
          //   break;
          case 'client':
            this.router.navigate(['client']).then( e => {
            });
            break;
        }

      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

}
