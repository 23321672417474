<nb-card>
  <nb-card-header class="d-flex justify-content-between align-items-baseline">
    <span i18n="@@contactsHeader">Other contacts</span>
    <button class="btn btn-light btn-sm" (click)="openAddContactForm(addContact)">
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
  </nb-card-header>
  <div class="d-flex flex-column" *ngIf="contacts.length > 0 else noContacts">
    <!--    <nb-list-item >-->
    <app-client-contact-list-item *ngFor="let contact of contacts" [contact]="contact"
                                  [departmentTerms]="departmentTerms" [languageTerms]="languageTerms"
                                  [client]="client"
                                  [contactTypeTerms]="contactTypeTerms">
    </app-client-contact-list-item>
    <!--    </nb-list-item>-->
  </div>
  <ng-template #noContacts>
    <app-client-contact-list-item-empty></app-client-contact-list-item-empty>
  </ng-template>
</nb-card>

<ng-template #addContact let-ref="dialogRef">
  <nb-card class="edit-dialog">
    <nb-card-header i18n="@@addContactHeader" class=" d-flex justify-content-between align-items-baseline">
      Add contact
    </nb-card-header>
    <nb-card-body>
      <nb-toggle [ngModel]="addNewUser" (ngModelChange)="addNewUserChange($event)" status="basic">New user</nb-toggle>
      <form [formGroup]="addContactForm" class="dialog-form">
        <div class="form-item" *ngIf="addNewUser">
          <label class="label" for="username" i18n="@@contactUsername">Username</label>
          <input nbInput fullWidth id="username" placeholder="Username" i18n-placeholder="@@contactUsernamePlaceholder"
                 formControlName="username">
        </div>
        <div *ngIf="username.invalid && (username.dirty || username.touched) && username.errors.required"
             class="alert alert-danger">
          <small class="label" i18n="@@errorUsernameRequired">Username is required</small>
        </div>
        <div class="form-item" *ngIf="addNewUser">
          <label class="label" for="email" i18n="@@email">Email</label>
          <input nbInput fullWidth id="email" placeholder="Email" i18n-placeholder="@@contactEmail"
                 formControlName="email">
        </div>
        <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required"
             class="alert alert-danger">
          <small class="label" i18n="@@errorEmailRequired">Email is required</small>
        </div>
        <div class="form-item" *ngIf="!addNewUser">
          <label class="label" for="usernameSearch" i18n="@@searchUsername">Search username</label>
          <input nbInput fullWidth id="usernameSearch" placeholder="Type at least 3 letters to start searching" i18n-placeholder="@@startTypingUsername"
                 (ngModelChange)="onUserSearchChange($event)"
                 formControlName="usernameSearch">
        </div>
        <div class="form-item" *ngIf="!addNewUser">
          <label for="userSelect" class="label"i18n="@@selectUser">Select user</label> - <small class="label"><span i18n="@@found">Found</span>: {{userAutocompleteOptions.length}}</small>
          <nb-select
            fullWidth
            id="userSelect" placeholder="Select user"
            formControlName="userSelect"
            [nbSpinner]="loadingUsers" nbSpinnerStatus="primary" nbSpinnerSize="tiny"
            [disabled]="userAutocompleteOptions.length === 0">
            <nb-option *ngFor="let user of userAutocompleteOptions" [value]="user.username">
              <ng-container *ngIf="user.first_name || user.last_name; else showUsernameOnly">
                <ng-container *ngIf="user.insertion; else noInsertion">
                  <span>{{ user.first_name }} {{user.insertion}} {{ user.last_name }}</span>
                  <span style="margin: 0 4px;"> - </span>
                  <span style="font-weight: 400">{{ user.username }}</span>
                </ng-container>
                <ng-template #noInsertion>
                  <span>{{ user.first_name }} {{ user.last_name }}</span>
                  <span style="margin: 0 4px;"> - </span>
                  <span style="font-weight: 400">{{ user.username }}</span>
                </ng-template>
              </ng-container>
              <ng-template #showUsernameOnly>
                <span style="font-weight: 400">{{ user.username }}</span>
              </ng-template>
            </nb-option>
          </nb-select>
          <div *ngIf="userSelect.invalid && (userSelect.dirty || userSelect.touched) && userSelect.errors.required"
               class="alert alert-danger">
            <small class="label" i18n="@@errorSelectUserRequired">Selecting a user is required</small>
          </div>
        </div>
      </form>
    </nb-card-body>
    <nb-card-footer>
      <div class="text-right form-actions">
        <input type="button" nbButton ghost status="primary" (click)="cancelAdd(ref)" value="Cancel" i18n-value="@@cancel">
        <button nbButton status="primary" type="submit" [disabled]="addContactForm.invalid" (click)="submitForm(ref)" i18n="@@submit">Submit</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
