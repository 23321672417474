import {Injectable} from '@angular/core';
import {
  Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {RestApiService} from '../rest/rest-api.service';

@Injectable()
export class NodeResolverService implements Resolve<any> {
  constructor(private apiService: RestApiService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.apiService.getNodeByTypeAndId('page', route.paramMap.get('uuid')).pipe(
      take(1),
      map(data => {
        if (data) {
          return data;
        } else {
          this.router.navigate(['/404']);
          return null;
        }
      }),
    );
  }
}
