import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, Subject, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ClientContactResponse} from '../../models/api/solarif_rest.models';
import {flatMap, map, mergeMap, tap} from 'rxjs/operators';
import {Client} from '../../models/client/client';
import {ClientContact} from '../../models/client/clientcontact';
import {SearchedContact, SearchedUser} from '../../models/client/searched-contact';
import {ContactTypeTaxonomyTerm, TaxonomyTerm} from '../../models/terms/taxonomyTerm';
import {ClientContactPatch} from '../../models/client/client-contact-patch';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private contactsChangedSource = new Subject();
  contactsChanged$ = this.contactsChangedSource.asObservable();

  contactsArray = {
    primary: [],
    normal: [],
    unfiltered: [],
  };

  constructor(private httpClient: HttpClient) {
  }

  public reloadContacts() {
    this.contactsChangedSource.next();
  }

  public contacts(client: Client): Observable<{
    normal: Array<ClientContact>,
    primary: Array<ClientContact>,
    unfiltered: Array<ClientContact>
  }> {
    const url = `${environment.drupalUrl}/api/group/${client.id}/memberships?_format=json`;

    const contacts = {
      primary: [],
      normal: [],
      unfiltered: [],
    };

    console.log('Get contacts from url:', url);

    return this.httpClient.get<Array<ClientContactResponse>>(url)
      .pipe(
        tap(clientResponse => {
          const outcome = clientResponse ? `fetched` : `did not find`;
          // console.log(`${outcome} contacts from client id=${clientId}`);
        }),
        map(response => {

          response.forEach(responseContact => {
            const contact = this.mapContactResponse(responseContact);

            if (!contact.primary) {
              contacts.normal.push(contact);
            } else {
              contacts.primary.push(contact);
            }
          });
          this.contactsArray = contacts;
          return contacts;
        })
      );
  }

  public userExists(username: string) {
    const url = `${environment.drupalUrl}/api/user/exists/${username}`;

    return this.httpClient.get<{exists: boolean}>(url).pipe(map(response => {
      return response.exists;
    }));
  }

  public addContact(clientId: string, username: string, email: string, isNew: boolean) {
    const url = `${environment.drupalUrl}/api/group/${clientId}/memberships`;

    const body = {
      username,
      email,
      isNew,
      requestType: 'add'
    };

    return this.httpClient.post(url, body);
  }

  public deleteContact(clientId, userId: number) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
      })
    };

    const body = {
      userId,
      requestType: 'delete'
    };

    const url = `${environment.drupalUrl}/api/group/${clientId}/memberships`;

    return this.httpClient.post(url, body, httpOptions);
  }

  public search(text: string): Observable<Array<SearchedUser>> {
    console.log('Searching for users containing', text);
    return this.httpClient.post<any>(`${environment.drupalUrl}/api/search-member`, {text})
      .pipe(
        map(data => {
          console.log('Found these contacts', data.contacts);
          const foundMembers: Array<SearchedUser> = [];
          data.contacts.group_members.forEach(member => {
            if (member) {
              const foundMember: SearchedContact = {
                client: member.client,
                first_name: member.first_name,
                full_name: member.full_name,
                gid: member.gid,
                id: member.id,
                insertion: member.insertion,
                internal_id: member.internal_id,
                last_name: member.last_name,
                user_id: member.user_id,
                username: member.username
              };
              foundMembers.push(foundMember);
            }
          });
          data.contacts.users.forEach(member => {
            if (member) {
              const foundMember: SearchedUser = {
                id: member.id,
                user_id: member.user_id,
                username: member.username,
                first_name: member.first_name,
                full_name: member.full_name,
                insertion: member.insertion,
                last_name: member.last_name
              };
              foundMembers.push(foundMember);
            }
          });
          return foundMembers;
        })
      );
  }

  private mapContactResponse(response: ClientContactResponse) {
    let typeTerms = [];
    if (response.contact_type) {
      const typeTerm: ContactTypeTaxonomyTerm = {
        id: response.contact_type.uuid[0].value,
        type: 'contact_type',
        weight: response.contact_type.weight[0].value,
        name: response.contact_type.name[0].value,
        icon: (response.contact_type.field_icon[0]) ? response.contact_type.field_icon[0].value : null
      };
      typeTerms.push(typeTerm);
    } else {
      typeTerms = null;
    }

    let departmentTerms = [];
    if (response.department) {
      const typeTerm: TaxonomyTerm = {
        id: response.department.uuid[0].value,
        type: 'contact_type',
        weight: response.department.weight[0].value,
        name: response.department.name[0].value
      };
      departmentTerms.push(typeTerm);
    } else {
      departmentTerms = null;
    }

    const languageTerms: Array<TaxonomyTerm> = [];

    if (response.languages.length > 0) {
      response.languages.forEach(selectedLanguage => {

        // A deleted language term results in an array item of null
        if (selectedLanguage) {
          const languageTerm: TaxonomyTerm = {
            id: selectedLanguage.uuid[0].value,
            name: selectedLanguage.name[0].value,
            weight: selectedLanguage.weight[0].value,
            type: 'spoken_language'
          };
          languageTerms.push(languageTerm);
        }
      });
    }

    const phoneNumbers: Array<any> = [];
    if (response.phone) {
      response.phone.forEach(phoneNumber => {
        phoneNumbers.push(phoneNumber);
      });
    }

    const emailAddresses: Array<any> = [];
    if (response.primair_email) {
      response.primair_email.forEach(email => {
        emailAddresses.push(email);
      });
    }

    const contact: ClientContact = {
      id: response.id,
      internal_id: response.internal_id,
      user_id: response.user_id,
      user_uuid: response.user_uuid,
      username: response.username,
      firstName: response.first_name,
      insertions: response.insertion,
      lastName: response.last_name,
      contactFunction: response.contact_function,
      gender: response.gender,
      memo: response.memo,
      mobilePhoneNumber: response.mobile_phonenumber,
      phoneNumber: phoneNumbers,
      primaryEmailAddress: emailAddresses,
      department: departmentTerms,
      contactType: typeTerms,
      languages: languageTerms,
      tags: response.tags,
      secondaryEmailAddress: response.secundair_email,
      skypeAccount: response.skype_account,
      status: response.status,
      primary: response.primary_contact
    };
    return contact;
  }

  updateContact(contact: ClientContactPatch): Observable<any> {
    console.log('ClientContactPatch', contact);
    const memberPatch$ = () => {
      const memberBody = {
        data: {
          type: 'group_content--relation-group_membership',
          id: contact.id,
          attributes: {
            field_primair_email: contact.emailAddresses,
            field_skype_account: contact.skypeAccount,
            field_function: contact.contactFunction,
            field_email: contact.secondaryEmail,
            field_status: contact.status,
            field_primary_contact: contact.primary
          },
          relationships: {
            field_ref_contact_type: {
              data: contact.relationTypes
            },
            field_ref_contact_department: {
              data: contact.departments
            }
          }
        }
      };
      return this.httpClient.patch(environment.drupalUrl + '/jsonapi/group_content/relation-group_membership/' + contact.id, memberBody);
    };

    const userPatch$ = () => {
      const userBody = {
        data: {
          type: 'user--user',
          id: contact.userId,
          attributes: {
            mail: contact.primaryEmail,
            field_last_name: contact.lastName,
            field_first_name: contact.firstName,
            field_insertion: contact.insertions,
            field_phone: contact.phoneNumbers,
            field_gender: contact.gender
          },
          relationships: {
            field_ref_spoken_languages: {
              data: contact.languages
            }
          }
        }
      };
      // console.log('userBody', userBody);
      return this.httpClient.patch(environment.drupalUrl + '/jsonapi/user/user/' + contact.userId, userBody);
    };

    const getUserByMail$ = this.httpClient.get<any>(`${environment.drupalUrl}/jsonapi/user/user?filter[mail]=${contact.primaryEmail}`);

    return getUserByMail$.pipe(
      flatMap(response => {
        let mailIsTaken = false;
        if (response.data && response.data.length > 0) {
          response.data.forEach(user => {
            if (user.id !== contact.userId) {
              mailIsTaken = true;
            }
          });
        }
        if (mailIsTaken) {
          return of({
            error: {
              message: 'Mail is taken',
              type: 'mailTaken'
            }
          });
        } else {
          return userPatch$().pipe(
            flatMap(memberPatch$)
          );
        }
      })
    );

    // return memberPatch$().pipe(
    //   flatMap(userPatch$)
    // );
  }
}
