import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {ATP} from '../../../models/atp';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {patterns} from '../../../validators/patterns';

@Component({
  selector: 'app-atp-dialog',
  templateUrl: './atp-dialog.component.html',
  styleUrls: ['./atp-dialog.component.scss']
})
export class AtpDialogComponent implements OnInit {

  @Input()
  atp: ATP;
  mode = 'view';
  atpEditForm: FormGroup;
  public Editor = ClassicEditor;
  subscription = new Subscription();
  originalFormState;

  constructor(protected dialogRef: NbDialogRef<any>, private http: HttpClient, private toastrService: SolarifToastrService) {
  }

  createPhoneNumber(phoneNumber = null) {
    return new FormGroup({
      phone: new FormControl(phoneNumber)
    });
  }

  addAdditionalPhoneNumber(phoneNumber = null) {
    (this.atpEditForm.get('phoneNumberArray') as FormArray).push(this.createPhoneNumber(phoneNumber));
  }

  createEmailAddress(emailAddress = null) {
    return new FormGroup({
      email: new FormControl(emailAddress, Validators.pattern(patterns.email))
    });
  }

  addAdditionalEmailAddress(emailAddress = null) {
    (this.atpEditForm.get('emailAddressArray') as FormArray).push(this.createEmailAddress(emailAddress));
  }

  get emailAddressFormData() {
    return this.atpEditForm.get('emailAddressArray') as FormArray;
  }

  get phoneNumberFormData() {
    return this.atpEditForm.get('phoneNumberArray') as FormArray;
  }


  ngOnInit() {

    this.atpEditForm = new FormGroup({
      title: new FormControl(this.atp.title, Validators.required),
      firstName: new FormControl(this.atp.contactFirstName, Validators.required),
      insertion: new FormControl(this.atp.contactInsertion),
      lastName: new FormControl(this.atp.contactLastName, Validators.required),
      website: new FormControl(this.atp.website, [Validators.pattern(patterns.url)]),
      memo: new FormControl(this.atp.memo)
    });

    // If there's any phone numbers, loop through them and create the correct amount of inputs.
    if (this.atp.phoneNumber && this.atp.phoneNumber.length > 0) {
      let i = 0;
      this.atp.phoneNumber.forEach(phoneNumber => {
        // During the first iteration the formArray has to be initialized.
        if (i === 0) {
          this.atpEditForm.addControl('phoneNumberArray', new FormArray([this.createPhoneNumber(phoneNumber)]));
        } else {
          this.addAdditionalPhoneNumber(phoneNumber);
        }
        i++;
      });
    } else {
      // No phone numbers, add an empty control to the array.
      this.atpEditForm.addControl('phoneNumberArray', new FormArray([this.createPhoneNumber()]));
    }

    // If there's any email addresses, loop through them and create the correct amount of inputs.
    if (this.atp.emailAddress && this.atp.emailAddress.length > 0) {
      let i = 0;
      this.atp.emailAddress.forEach(emailAddress => {
        // During the first iteration the formArray has to be initialized.
        if (i === 0) {
          this.atpEditForm.addControl('emailAddressArray', new FormArray([this.createEmailAddress(emailAddress)]));
        } else {
          this.addAdditionalEmailAddress(emailAddress);
        }
        i++;
      });
    } else {
      // No email addresses, add an empty control to the array.
      this.atpEditForm.addControl('emailAddressArray', new FormArray([this.createEmailAddress()]));
    }
    this.originalFormState = this.atpEditForm.value;
  }

  formatDisplayName(): string {
    return this.atp.contactFirstName + (this.atp.contactInsertion ? ' ' + this.atp.contactInsertion + ' ' : ' ') + this.atp.contactLastName;
  }

  close() {
    this.dialogRef.close();
  }

  setMode() {
    if (this.mode === 'view') {
      this.mode = 'edit';
    } else {
      this.mode = 'view';
    }
  }

  cancelEdit() {

    // Remove the phone number and email controls, we'll add these again manually.
    this.atpEditForm.removeControl('phoneNumberArray');
    this.atpEditForm.removeControl('emailAddressArray');

    // Loop through the phoneNumbersArray to add them to the form again.
    let i = 0;
    this.originalFormState.phoneNumberArray.forEach(phoneNumber => {
      if (i === 0) {
        this.atpEditForm.addControl('phoneNumberArray', new FormArray([this.createPhoneNumber(phoneNumber.phone)]));
      } else {
        this.addAdditionalPhoneNumber(phoneNumber.phone);
      }
      i++;
    });

    i = 0;
    this.originalFormState.emailAddressArray.forEach(emailAddress => {
      if (i === 0) {
        this.atpEditForm.addControl('emailAddressArray', new FormArray([this.createEmailAddress(emailAddress.email)]));
      } else {
        this.addAdditionalEmailAddress(emailAddress.email);
      }
      i++;
    });

    this.atpEditForm.setValue(this.originalFormState);
    this.setMode();
  }

  submitForm() {

    const phoneNumberArray = this.atpEditForm.get('phoneNumberArray').value;
    const phoneNumbers = [];
    if (phoneNumberArray) {
      phoneNumberArray.forEach(phoneNumber => {
        if (phoneNumber.phone) {
          phoneNumbers.push(phoneNumber.phone);
        }
      });
    }

    const emailAddressArray = this.atpEditForm.get('emailAddressArray').value;
    const emailAddresses = [];
    if (emailAddressArray) {
      emailAddressArray.forEach(emailAddress => {
        if (emailAddress.email) {
          emailAddresses.push(emailAddress.email);
        }
      });
    }


    const body = {
      data: {
        type: 'atp--atp',
        id: this.atp.id,
        attributes: {
          title: this.atpEditForm.get('title').value,
          field_contact_last_name: this.atpEditForm.get('lastName').value,
          field_contact_first_name: this.atpEditForm.get('firstName').value,
          field_contact_insertion: this.atpEditForm.get('insertion').value,
          field_website: this.atpEditForm.get('website').value,
          field_phonenumber: phoneNumbers,
          field_emailaddress: emailAddresses,
          field_memo: this.atpEditForm.get('memo').value,
        }
      }
    };

    const patchSubscription = this.http.patch(environment.drupalUrl + '/jsonapi/atp/atp/' + this.atp.id, body).subscribe(
      result => {
        this.toastrService.success(
          $localize`:@@atpInformationUpdated:ATP information has been updated.`
        );
        this.atp.title = this.atpEditForm.get('title').value;
        this.atp.contactFirstName = this.atpEditForm.get('firstName').value;
        this.atp.contactInsertion = this.atpEditForm.get('insertion').value;
        this.atp.contactLastName = this.atpEditForm.get('lastName').value;
        this.atp.website = this.atpEditForm.get('website').value;
        this.atp.phoneNumber = phoneNumbers;
        this.atp.emailAddress = emailAddresses;
        this.atp.memo = this.atpEditForm.get('memo').value;
        this.setMode();
      }, error => {
        this.toastrService.error(
          $localize`:@@atpUpdateFailure:ATP information could not be updated.`
        );
      }
    );
    this.subscription.add(patchSubscription);
  }

}
