<div class="card">
  <nb-card>
    <nb-card-header i18n="@@export">Export</nb-card-header>
    <nb-card-body [nbSpinner]="!canSave" nbSpinnerStatus="primary">
      <p *ngIf="!canSave" i18n="@@clientExportInProgress">Client export is being prepared...</p>
      <p *ngIf="canSave" i18n="@@clientExportReady">Client export is ready.</p>
    </nb-card-body>
    <nb-card-footer>
      <div class="card--actions">
        <button nbButton [disabled]="!canSave" status="primary" (click)="onDownloadClick()" i18n="@@download">Download</button>
        <button nbButton status="basic" (click)="onCancelClick()" i18n="@@cancel">Cancel</button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>