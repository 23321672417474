<nb-list>
  <nb-list-item>
    <div class="overview-item row" [ngClass]="{ 'reply': activity.previous_activity_id }">
      <div class="col-1 d-flex justify-content-center align-items-center">
        <nb-icon *ngIf="activity.previous_activity_id else noReply" icon="corner-down-right-outline"></nb-icon>
        <ng-template #noReply>
          <span title="{{activity.type}}"><nb-icon *ngIf="activity.icon" icon="{{ activity.icon }}"></nb-icon></span>
        </ng-template>
      </div>
      <div class="col-7 d-flex flex-column justify-content-center">
        <div>
          {{ activity.title }}
        </div>
        <div class="assignee label" *ngIf="activity.assignee">
          <span i18n="@@createdBy">Created by:</span> {{ activity.assignee }}
        </div>
      </div>
      <div class="col-2 text-right justify-content-right align-items-center">
        <small class="time">{{ activity.date | date: 'd MMMM y, H:mm:ss' }}</small>
      </div>
      <div class="col-1 text-right justify-content-right align-items-center">
        <button class="btn btn-light btn-sm d-inline" (click)="openEditDialog(dialog)">
          <nb-icon icon="edit-outline"></nb-icon>
        </button>
      </div>
      <div class="col-1 text-right justify-content-right align-items-center">
        <button class="btn btn-light btn-sm d-inline" (click)="openReplyDialog(dialog)">
          <nb-icon icon="corner-down-left-outline"></nb-icon>
        </button>
      </div>
    </div>
  </nb-list-item>
</nb-list>
<ng-container *ngIf="activity.children">
  <div class="indent" style="margin-left: 15px;">
    <app-client-activity [activity]="childActivity" *ngFor="let childActivity of activity.children"
                         [allowedBusinessUnitTerms]="allowedBusinessUnitTerms"
                         [activityTypeTerms]="activityTypeTerms" [client]="client"></app-client-activity>
  </div>
</ng-container>

<ng-template #dialog let-ref="dialogRef">
  <nb-card id="activity-{{activity.id}}" class="activity-dialog">
    <nb-card-header class="d-flex justify-content-between align-items-baseline">
      <div>
        <span title="{{activity.type}}"><nb-icon *ngIf="activity.icon" icon="{{ activity.icon }}"></nb-icon></span>
        {{activity.title}}</div>
      <div class="icons d-inline-flex">
        <nb-action class="print" icon="printer-outline" (click)="print()" title="Print activity" i18n-title="@@printActivityTitle"></nb-action>
        <nb-icon class="close" (click)="ref.close()" icon="close-outline"></nb-icon>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="activity-information row">
        <div class="col-8">
          <app-calculation-data *ngIf="show.calculationData && activity.calculation" [calculation]="activity.calculation"></app-calculation-data>

          <h5 i18n="@@additionalActivityInformation">Additional activity information</h5>
          <div class="activity-information--item" *ngIf="activity.assignee">
            <span class="label" i18n="@@createdBy">Created by</span>
            <div class="value" [innerHTML]="activity.assignee"></div>
          </div>
          <div class="activity-information--item" *ngIf="activity.short_description">
            <span class="label" i18n="@@shortDescription">Short description</span>
            <div class="value" [innerHTML]="activity.short_description"></div>
          </div>
          <div class="activity-information--item" *ngIf="activity.long_description">
            <span class="label" i18n="@@longDescription">Long description</span>
            <div class="value" [innerHTML]="activity.long_description"></div>
          </div>
          <div class="activity-information--item" *ngIf="activity.time_spent">
            <span class="label" i18n="@@timeSpent">Time spent</span>
            <div class="value" [innerHTML]="activity.time_spent"></div>
          </div>
          <div class="activity-information--item" *ngIf="activity.business_unit_ids">
            <span class="label" i18n="@@businessUnit">Business unit</span>
            <div class="value">
              <ng-container *ngFor="let item of allowedBusinessUnitTerms">
                <span *ngIf="activity.business_unit_ids.includes(item.termId.toString())" class="business-unit">
                  {{item.name}}
                </span>
              </ng-container>
          </div>
          </div>
          <app-form-submission [valuesOnly]="true"
                               *ngIf="activity.form_submission_data && activity.form_submission_data['submission']"
                               [formSubmissionData]="activity.form_submission_data"></app-form-submission>
        </div>
        <div class="col-4">
          <div class="actions" *ngIf="showActions()">
            <h5>Actions</h5>

            <form *ngIf="show.productList" [formGroup]="productSelectForm" (ngSubmit)="submitProductSelectForm(ref)">
              <span class="label" i18n="@@selectProduct">Select a product</span><br>
              <nb-alert outline="danger" *ngIf="client.addresses.length === 0">
                <p i18n="@@calculationRequirements">The following requirements needs to be met before a calculation can be made:</p>
                <ul class="m-0">
                  <li i18n="@@calculationRequirementsAddressLength" *ngIf="client.addresses.length === 0">At least one address is needed.</li>
                </ul>
              </nb-alert>
              <nb-select multiple
                         i18n-placeholder="@@selectProductPlaceholder"
                         placeholder="Select one or more products"
                         formControlName="products"
              >
                <nb-option *ngFor="let product of products" [value]="product.productId">{{product.productId}}</nb-option>
              </nb-select>
              <button nbButton
                      i18n="@@select"
                      [disabled]="client.addresses.length === 0"
              >
                Select
              </button>
            </form>

            <div *ngIf="show.generateAdditionalInfoLink">
              <button nbButton i18n="@@generateAdditionalInfo" (click)="generateFormLink()">Generate form link</button>
              <br>
              <br>
              <div *ngIf="generatedFormLinks.nl">
                <label class="label">NL</label>
                <br>
                <div class="d-flex">
                  <input nbInput type="text" readonly value="{{generatedFormLinks.nl}}" #nl_input />
                  <button class="btn btn-light btn-sm d-inline" title="Copy" i18n-title="@@copy" (click)="copyInputMessage(nl_input)">
                    <nb-icon icon="clipboard-outline"></nb-icon>
                  </button>
                </div>
              </div>
              <br>
              <div *ngIf="generatedFormLinks.en">
                <label class="label">EN</label>
                <br>
                <div class="d-flex">
                  <input nbInput type="text" readonly value="{{generatedFormLinks.en}}" #en_input />
                  <button class="btn btn-light btn-sm d-inline" title="Copy" i18n-title="@@copy" (click)="copyInputMessage(en_input)">
                    <nb-icon icon="clipboard-outline"></nb-icon>
                  </button>
                </div>
              </div>
            </div>

            <form *ngIf="show.getQuotation" [formGroup]="quotationForm" (ngSubmit)="submitQuotationForm(ref)">
              <nb-alert outline="danger" *ngIf="!isQuotationValid()">
                <p i18n="@@quotationRequirements">The following requirements needs to be met before a quotation can be made:</p>
                <ul class="m-0">
                  <li i18n="@@quotationRequirementsAddressLength" *ngIf="client.addresses.length === 0">At least one address is needed.</li>
                  <li i18n="@@quotationRequirementsPrimaryContact" *ngIf="client.contacts.primary.length === 0">A primary contact is needed.</li>
                  <li i18n="@@quotationRequirementsPrimaryContactEmailRequired"
                      *ngIf="client.contacts.primary.length > 0 && client.contacts.primary[0].primaryEmailAddress.length === 0">The primary contact needs to have an e-mail address</li>
                  <li i18n="@@quotationRequirementsSelectedProduct" *ngIf="!selectedProductForQuotation">There is no reference to the selected product.</li>
                  <li i18n="@@quotationRequirementsCalculationActivityId" *ngIf="!calculationActivityId">There is no reference to the calculation activity.</li>
                </ul>
              </nb-alert>
              <div class="form-item">
                <label for="quotation_business_address" class="label" i18n="@@selectBusinessAddress">Select a business address</label>
                <nb-select  id="quotation_business_address"
                            name="quotation_business_address"
                            i18n-placeholder="@@selectAddressPlaceholder"
                            placeholder="Select an address"
                            formControlName="business_address"
                >
                  <nb-option *ngFor="let address of mappedAddresses.regular" [value]="address.uuid">
                    <div>{{address.title}}</div>
                    <div><small>{{address.address_line1}}, {{address.postal_code}}, {{address.country_code}}</small></div>
                  </nb-option>
                </nb-select>
              </div>
              <div class="form-item">
                <label for="quotation_risk_address" class="label" i18n="@@selectRiskAddress">Select a risk address</label>
                <nb-select  id="quotation_risk_address"
                            name="quotation_risk_address"
                            i18n-placeholder="@@selectAddressPlaceholder"
                            placeholder="Select an address"
                            formControlName="risk_address"
                >
                  <nb-option *ngFor="let address of mappedAddresses.risk" [value]="address.uuid">
                    <div>{{address.title}}</div>
                    <div><small>{{address.address_line1}}, {{address.postal_code}}, {{address.country_code}}</small></div>
                  </nb-option>
                </nb-select>
              </div>
              <button nbButton [disabled]="quotationForm.invalid || !isQuotationValid()" status="primary">
                Send quotation
              </button>
            </form>

          </div>
          <app-form-submission [infoOnly]="true"
                               *ngIf="activity.form_submission_data && activity.form_submission_data['submission']"
                               [formSubmissionData]="activity.form_submission_data"></app-form-submission>
        </div>
        <div class="col-12">
          <div class="activity-reply">
            <hr>
            <h4 i18n="@@replyToActivity">Reply to the activity</h4>
            <form [formGroup]="activityReplyForm" (ngSubmit)="submitReplyForm(ref)">
              <div class="form-item">
                <label class="label" for="replyMessage">Message</label>
                <ckeditor id="replyMessage" fullWidth [editor]="Editor" formControlName="message"></ckeditor>
              </div>
              <div class="form-item" *ngIf="activityTypeTerms">
                <label class="label" for="activityType" i18n="@@activityType">Activity type</label>
                <nb-select id="activityType" formControlName="activityType" placeholder="Select activity type"
                           i18n-placeholder="@@activityTypePlaceholder">
                  <nb-option *ngFor="let activityType of allowedActivityTypeTerms;"
                             [value]="activityType.id">{{activityType.name}}</nb-option>
                </nb-select>
              </div>
              <div class="align-right form-actions">
                <button nbButton status="primary" type="submit" [disabled]="activityReplyForm.invalid"
                        i18n="@@submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div id="activity-{{activity.id}}__print-version" class="activity__print-version">
        <h2>{{activity.title}}</h2>

        <section>
          <h3 i18n="@@additionalActivityInformation">Additional activity information</h3>
          <div class="activity-information--item" *ngIf="activity.assignee">
            <strong class="label" i18n="@@createdBy">Created by</strong>
            <div class="value" [innerHTML]="activity.assignee"></div>
          </div>
          <div class="activity-information--item" *ngIf="activity.short_description">
            <strong class="label" i18n="@@shortDescription">Short description</strong>
            <div class="value" [innerHTML]="activity.short_description"></div>
          </div>
          <div class="activity-information--item" *ngIf="activity.long_description">
            <strong class="label" i18n="@@longDescription">Long description</strong>
            <div class="value" [innerHTML]="activity.long_description"></div>
          </div>
          <div class="activity-information--item" *ngIf="activity.time_spent">
            <strong class="label" i18n="@@timeSpent">Time spent</strong>
            <div class="value" [innerHTML]="activity.time_spent"></div>
          </div>
        </section>
        <section>
          <app-calculation-data *ngIf="show.calculationData && activity.calculation" [calculation]="activity.calculation"></app-calculation-data>
        </section>
        <section>
          <app-form-submission [valuesOnly]="true"
                               *ngIf="activity.form_submission_data && activity.form_submission_data['submission']"
                               [formSubmissionData]="activity.form_submission_data"></app-form-submission>
        </section>
        <section>
          <app-form-submission [infoOnly]="true"
                               *ngIf="activity.form_submission_data && activity.form_submission_data['submission']"
                               [formSubmissionData]="activity.form_submission_data"></app-form-submission>
        </section>
      </div>

    </nb-card-body>
  </nb-card>
</ng-template>
