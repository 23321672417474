<div class="form-submission">
  <div class="form-submission--information" *ngIf="!valuesOnly">
    <h3 i18n="@@formSubmissionInfoHeader">Form submission information</h3>
    <div class="form-submission--item">
      <strong class="label" i18n="@@submittedFormLabel">Submitted form</strong>
      <div class="value">{{submittedForm.title}}</div>
    </div>
<!--    <div class="form-submission&#45;&#45;item">-->
<!--      <span class="label" i18n="@@submittedFormDescription">Form description</span>-->
<!--      <div class="value" [innerHTML]="submittedForm.description"></div>-->
<!--    </div>-->
    <div class="form-submission--item">
      <strong class="label" i18n="@@submittedOnLabel">Form submitted on</strong>
      <div class="value">
        {{ formSubmission['created'] * 1000 | date: 'd MMMM y, H:mm:ss' }}
      </div>
    </div>
  </div>
  <div class="form-submission--values" *ngIf="!infoOnly">
    <h3 i18n="@@submittedValuesHeader">Submitted form values</h3>
    <div class="form-submission--item" *ngFor="let element of formElements | keyvalue: keepOriginalOrder;">
      <div *ngIf="this.isContainerElement(element) else noContainer">
        <div class="form-submission--item" *ngFor="let subElement of element.value | keyvalue: keepOriginalOrder;">
          <strong class="label">{{subElement.value['#title']}}</strong>
          <div *ngIf="this.isContainerElement(subElement) else noSubContainer">
            <div class="form-submission--item" *ngFor="let thirdLevelElement of subElement.value | keyvalue: keepOriginalOrder;">
              <strong class="label">{{thirdLevelElement.value['#title']}}</strong>
              <div class="values"
                   *ngIf="isArray(formSubmission.values[thirdLevelElement.key]) else singleValueThirdLevel;">
                <div class="values--value"
                     *ngFor="let item of formSubmission.values[thirdLevelElement.key] | keyvalue: keepOriginalOrder;">
                  {{item.value}}
                </div>
              </div>
              <ng-template #singleValueThirdLevel>
                <div class="item">{{formSubmission.values[thirdLevelElement.key]}}</div>
              </ng-template>
            </div>
          </div>
          <ng-template #noSubContainer>
            <div class="values"
                 *ngIf="isArray(formSubmission.values[subElement.key]) else singleValue;">
              <div class="values--value"
                   *ngFor="let item of formSubmission.values[subElement.key] | keyvalue: keepOriginalOrder;">
                {{item.value}}
              </div>
            </div>
            <ng-template #singleValue>
              <div class="item">{{formSubmission.values[subElement.key]}}</div>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <ng-template #noContainer>
        <strong class="label">{{element.value['#title']}}</strong>
        <div class="values"
             *ngIf="isArray(formSubmission.values[element.key]) else singleValueNoContainer;">
          <div class="values--value"
               *ngFor="let item of formSubmission.values[element.key] | keyvalue: keepOriginalOrder;">
            {{item.value}}
          </div>
        </div>
        <ng-template #singleValueNoContainer>
          <div class="item">{{formSubmission.values[element.key]}}</div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
