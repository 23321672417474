import { Component, OnInit, Input } from '@angular/core';
import { ActivityService } from 'src/app/services/activity/activity.service';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';
import {Client} from '../../../models/client/client';
import {ActivityPatch} from '../../../models/activity/activity-patch';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-client-checking-alert',
  templateUrl: './client-checking-alert.component.html',
  styleUrls: ['./client-checking-alert.component.scss']
})
export class ClientCheckingAlertComponent implements OnInit {

  @Input()
  client: Client;

  private identityClaims;

  constructor(private activityService: ActivityService,
              private authService: AuthService,
              private toastrService: SolarifToastrService) { }

  ngOnInit() {
    this.identityClaims = this.authService.identityClaims;
  }

  markChecked() {
    const activity: ActivityPatch = {
      clientId: this.client.id,
      date: UtilitiesService.ISODateString(new Date()),
      title: 'Gegevens gecontroleerd',
      description: `Gecontroleerd door gebruiker "${this.identityClaims.name}"`,
      typeId: environment.taxonomyTermIds.activityType.customerDataChecked
    };

    this.activityService.postActivity(activity).subscribe(result => {
      this.activityService.reloadActivities();
      this.client.isMarkedForChecking = false;
      this.toastrService.success($localize`:@@clientCheckedSucces:Client data has been succesfully checked.`);
    }, error => {
      console.log('ClientCheckingAlertComponent markChecked error:', error);
      this.toastrService.error($localize`:@@clientCheckedError:Client data could not be checked.`);
    });
  }

}
