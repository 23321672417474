<nb-card class="confirm-delete-task-card">
  <nb-card-header i18n="@@confirmDelete" class="d-flex justify-content-between align-items-baseline">
    Confirm delete
  </nb-card-header>
  <nb-card-body>
    <p i18n="@@confirmDeleteMessageTask">Are you sure you want to delete this task?</p>

  </nb-card-body>
  <div *ngIf="task" class="item">
    <app-task-list-item [task]="task" [viewOnly]="true"></app-task-list-item>
  </div>
  <nb-card-footer>
    <div class="form-actions text-right">
      <input type="button" nbButton ghost status="primary" (click)="cancelDelete()" value="Cancel" i18n-value="@@cancel">
      <button nbButton status="danger" type="submit" [disabled]="!task.id" (click)="deleteTask()" i18n="@@delete">Delete</button>
    </div>
  </nb-card-footer>
</nb-card>
