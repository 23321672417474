<div class="activities">
  <div class="top-bar">
    <div class="activity-filters">
      <div class="filter--business-units">
        <button
          *ngFor="let businessUnit of businessUnitTerms"
          (click)="setBusinessUnitFilter(businessUnit)"
          size="small"
          [status]="businessUnitFilterModel === businessUnit.termId ? 'primary' : 'basic'"
          nbButton>{{businessUnit.name}}</button>
      </div>
      <div class="filter--types">
        <nb-checkbox *ngFor="let filter of filtersModel | keyvalue; let i = index;"
                     [(ngModel)]="filtersModel[filter.key]" (ngModelChange)="filterChange()">
          <nb-icon icon="{{ filterIcons.get(filter.key) }}"></nb-icon>
        </nb-checkbox>
      </div>
    </div>
    <button class="btn btn-light btn-sm" i18n-title="@@add" title="Add" (click)="openAddDialog()"><nb-icon icon="plus-outline"></nb-icon></button>
  </div>
  <div *ngIf="filteredActivities && filteredActivities.length > 0; else noResults">
    <app-client-activity [allowedBusinessUnitTerms]="businessUnitTerms" [activityTypeTerms]="activityTypeTerms" [allowedActivityTypeTerms]="allowedActivityTypeTerms" [activity]="activity" [open]="activity.id === openActivityId" [client]="client" *ngFor="let activity of filteredActivities" class="parent-list-item"></app-client-activity>
  </div>
  <ng-template #noResults>
    <nb-list>
      <nb-list-item>
        <span i18n="@@noResultsActivities">No activities found for the specified filters.</span>
      </nb-list-item>
    </nb-list>
  </ng-template>
</div>

<!--<nb-card *ngIf="activities">-->
<!--  <nb-card-header class="d-flex justify-content-between align-items-center">-->
<!--    <span i18n="@@Activities">Activities</span>-->
<!--    <div class="activity-filters">-->
<!--      <button-->
<!--        *ngFor="let businessUnit of businessUnitTerms"-->
<!--        (click)="setBusinessUnitFilter(businessUnit)"-->
<!--        size="small"-->
<!--        [status]="businessUnitFilterModel === businessUnit.termId ? 'primary' : 'basic'"-->
<!--        nbButton>{{businessUnit.name}}</button>-->
<!--      <br>-->
<!--      <nb-checkbox *ngFor="let filter of filtersModel | keyvalue; let i = index;"-->
<!--                   [(ngModel)]="filtersModel[filter.key]" (ngModelChange)="filterChange()">-->
<!--        <nb-icon icon="{{ filterIcons.get(filter.key) }}"></nb-icon>-->
<!--      </nb-checkbox>-->
<!--    </div>-->
<!--    <button class="btn btn-light btn-sm" i18n-title="@@add" title="Add" (click)="openAddDialog()"><nb-icon icon="plus-outline"></nb-icon></button>-->
<!--  </nb-card-header>-->
<!--  <div *ngIf="filteredActivities && filteredActivities.length > 0; else noResults">-->
<!--      <app-client-activity [allowedBusinessUnitTerms]="businessUnitTerms" [activityTypeTerms]="activityTypeTerms" [allowedActivityTypeTerms]="allowedActivityTypeTerms" [activity]="activity" [open]="activity.id === openActivityId" [client]="client" *ngFor="let activity of filteredActivities" class="parent-list-item"></app-client-activity>-->
<!--  </div>-->
<!--  <ng-template #noResults>-->
<!--    <nb-list>-->
<!--      <nb-list-item>-->
<!--        <p i18n="@@noResultsActivities">No activities found for the specified filters.</p>-->
<!--      </nb-list-item>-->
<!--    </nb-list>-->
<!--  </ng-template>-->
<!--</nb-card>-->
