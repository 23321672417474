<!--<div class="address">-->
<!--  <strong>{{ address.title }}-->
<!--    <button class="btn btn-light btn-sm" (click)="openEditDialog(editDialog)"> <nb-icon icon="edit-outline"></nb-icon></button>-->
<!--  </strong>-->
<!--  <p>{{ address.address_line1 }}</p>-->
<!--  <p>{{ address.address_line2 }}</p>-->
<!--  <p>{{ address.postal_code }}</p>-->
<!--  <p>{{ address.locality }}</p>-->
<!--  <p>{{ address.dependent_locality }}</p>-->
<!--  <p>{{ address.administrative_area }}</p>-->
<!--  <p>{{ address.sorting_code }}</p>-->
<!--  <p>{{ address.country_code }}</p>-->
<!--</div>-->
<nb-card class="address">
  <nb-card-header class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <span *ngIf="address.is_post_address" title="Post adress" i18n-title="@@addressPostAddress" class="d-flex align-items-center mr-2"><nb-icon icon="email-outline"></nb-icon></span>
      {{ address.title }}</div>
    <button class="btn btn-light btn-sm" [disabled]="currentLock === 1" (click)="openEditDialog(editDialog)">
      <nb-icon icon="edit-outline"></nb-icon>
    </button>
  </nb-card-header>
  <nb-list>
    <nb-list-item *ngIf="address.type.name">Type: {{ address.type.name }}</nb-list-item>
    <nb-list-item *ngIf="address.address_line1">{{ address.address_line1 }}</nb-list-item>
    <nb-list-item *ngIf="address.address_line2">{{ address.address_line2 }}</nb-list-item>
    <nb-list-item *ngIf="address.postal_code">{{ address.postal_code }}</nb-list-item>
    <nb-list-item *ngIf="address.locality">{{ address.locality }}</nb-list-item>
    <nb-list-item *ngIf="address.dependent_locality">{{ address.dependent_locality }}</nb-list-item>
    <nb-list-item *ngIf="address.administrative_area">{{ address.administrative_area }}</nb-list-item>
    <nb-list-item *ngIf="address.sorting_code">{{ address.sorting_code }}</nb-list-item>
    <nb-list-item *ngIf="address.country_code">{{ address.country_code }}</nb-list-item>
  </nb-list>
</nb-card>

<ng-template #editDialog let-ref="dialogRef">
  <div class="address edit">
    <form [formGroup]="editAddressForm" class="dialog-form" novalidate (ngSubmit)="onSubmit(ref)">
      <nb-card>
        <nb-card-header class="d-flex justify-content-between align-items-baseline">
          <span i18n="@@editAddressHeader">Edit address</span>
          <button class="btn btn-light btn-sm" (click)="confirmDelete(ref, deleteConfirm)">
            <nb-icon class="text-danger" icon="trash-2-outline"></nb-icon>
          </button>
        </nb-card-header>
        <nb-card-body>
          <nb-alert outline="warning" *ngIf="isUnsupportedCountry">
            <span i18n="@@addressCountryNotSupportedMessage">Editing this address is disabled because its country is currently not supported.</span>
          </nb-alert>
          <div class="row">
            <div class="col-md-6">
              <div class="form-item">
                <label class="label" i18n="@@addressTitle">Title</label>
                <input nbInput
                       fullWidth
                       formControlName="title"
                       placeholder="Title"
                       i18n-placeholder="@@addressTitlePlaceholder"
                       [status]="(editAddressForm.controls.title.invalid) ? 'danger' : 'basic'"
                >
              </div>
              <div class="form-item">
                <nb-checkbox formControlName="isPostAddress" id="is_post_address" i18n="@@addressIsPostAddressText">This address is a post address</nb-checkbox>
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressType">Adress type</label>
                <nb-select placeholder="Select address type" i18n-placeholder="@@addressTypePlaceholder"
                           [status]="(editAddressForm.controls.type.invalid) ? 'danger' : 'basic'"
                           formControlName="type" fullWidth>
                  <nb-option *ngFor="let type of addressTypes" value="{{type.id}}">{{type.name}}</nb-option>
                </nb-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-item">
                <label class="label" i18n="@@addressCountry">Country</label>
                <nb-select placeholder="Select country" i18n-placeholder="@@countrySelectPlaceholder"
                           [status]="(editAddressForm.controls.countryCode.errors || editAddressForm.controls.countryCode.invalid) ? 'danger' : 'basic'"
                           (ngModelChange)="onCountryUpdated()"
                           formControlName="countryCode" fullWidth>
                  <nb-option value="DE" i18n="@@germany">Germany</nb-option>
                  <nb-option value="NL" i18n="@@netherlands">The Netherlands</nb-option>
                  <nb-option value="BE" i18n="@@belgium">Belgium</nb-option>
                </nb-select>
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressLine1">Address Line 1</label>
                <input nbInput fullWidth formControlName="addressLine" placeholder="Address" i18n-placeholder="@@addressLine1Placeholder">
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressLine2">Address Line 2</label>
                <input nbInput fullWidth formControlName="addressLineSecondary" placeholder="Address Line 2" i18n-placeholder="@@addressLine2Placeholder">
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressPostalCode">Postal Code</label>
                <input nbInput fullWidth
                       [status]="(editAddressForm.controls.postalCode.invalid) ? 'danger' : 'basic'"
                       formControlName="postalCode"
                       placeholder="Postal Code" i18n-placeholder="@@postalCodePlaceholder">
              </div>
              <div class="form-item">
                <label class="label" i18n="@@addressCity">City</label>
                <input nbInput fullWidth formControlName="locality" placeholder="City" i18n-placeholder="@@cityPlaceholder">
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="form-actions text-right">
            <input type="button" nbButton ghost status="primary" (click)="cancelEdit(ref)" value="Cancel" i18n-value="@@cancel">
            <button nbButton status="primary" type="submit" [disabled]="editAddressForm.invalid || isUnsupportedCountry" i18n="@@submit">Submit</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </form>
  </div>
</ng-template>

<ng-template #deleteConfirm let-ref="dialogRef">
  <nb-card>
    <nb-card-header i18n="@@confirmDelete" class="d-flex justify-content-between align-items-baseline">
      Confirm delete
    </nb-card-header>
    <nb-card-body>
      <p i18n="@@confirmDeleteMessageAddress">Are you sure you want to delete this address?</p>
    </nb-card-body>
    <nb-card-footer>
      <div class="form-actions text-right">
        <input type="button" nbButton ghost status="primary" (click)="cancelDelete(ref)" value="Cancel" i18n-value="@@cancel">
        <button nbButton status="danger" type="submit" [disabled]="!address.uuid" (click)="deleteAddress(ref)" i18n="@@delete">Delete</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
