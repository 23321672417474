<nb-card>
  <nb-card-header class="d-flex justify-content-between align-items-baseline">
    <span i18n="@@accountManagerHeader">Account manager</span>
    <button class="btn btn-light btn-sm" (click)="openEditDialog(editDialog)">
      <nb-icon icon="plus-outline"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body *ngIf="client.accountManagers && client.accountManagers.length else noAccountManager">
    <div class="main d-flex justify-content-between align-items-center" *ngFor="let accountManager of client.accountManagers">
      <div class="user-container">
        <div class="user-picture initials">
          {{ (accountManager.businessUnit || '').substr(0, 2).toUpperCase() }}
        </div>

        <div class="info-container">
          <div *ngIf="accountManager.fullName else noFullName" class="user-name">{{ accountManager.fullName }}</div>
          <ng-template #noFullName><div *ngIf="accountManager.username" class="user-name">{{ accountManager.username }}</div></ng-template>
          <div class="contact-info">
            <div *ngIf="accountManager.email">
              <nb-icon icon="email-outline"></nb-icon>
              <a href="mailto:{{ accountManager.email }}">{{ accountManager.email }}</a>
            </div>
          </div>
        </div>
        <button class="edit-account-manager btn btn-light btn-sm" (click)="openEditDialog(editDialog, accountManager)">
          <nb-icon icon="edit-outline"></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-body>
  <ng-template #noAccountManager>
    <app-client-contact-list-item-empty text="No account manager"></app-client-contact-list-item-empty>
  </ng-template>
</nb-card>
<ng-template #editDialog let-ref="dialogRef">
  <form [formGroup]="accountManagerForm" class="dialog-form" (ngSubmit)="onSubmit(ref)" novalidate>
    <nb-card>
      <nb-card-header class="d-flex justify-content-between align-items-baseline">
        <span  i18n="@@editAccountManager" *ngIf="selectedAccountManager">Edit account manager</span>
        <span  i18n="@@addAccountManager" *ngIf="!selectedAccountManager">Add account manager</span>
        <button *ngIf="selectedAccountManager"type="button" class="btn btn-light btn-sm" (click)="confirmDelete(ref)">
          <nb-icon class="text-danger" icon="trash-2-outline"></nb-icon>
        </button>
      </nb-card-header>
      <nb-card-body>
        <div class="form-item">
          <label class="label" i18n="@@businessUnitLabel">Business unit</label>
          <nb-select placeholder="Select business unit" i18n-placeholder="@@businessUnitPlaceholder" formControlName="businessUnit">
            <nb-option *ngFor="let businessUnit of businessUnitTerms;" [value]="businessUnit.id">
              {{ businessUnit.name }}
            </nb-option>
          </nb-select>
        </div>
        <div class="form-item" *ngIf="allAccountManagers">
          <label class="label" i18n="@@accountManagerLabel">Account manager</label>
          <nb-select placeholder="Select account manager" i18n-placeholder="@@accountManagerPlaceholder" formControlName="accountManager">
            <nb-option *ngFor="let accountManager of allAccountManagers;" [value]="accountManager.uuid">
              {{ (accountManager.fullName) ? accountManager.fullName : accountManager.username }}
            </nb-option>
          </nb-select>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="form-actions text-right">
          <input type="button" nbButton ghost status="primary" (click)="cancelEdit(ref)" value="Cancel"
                 i18n-value="@@cancel">
          <button nbButton status="primary" type="submit" [disabled]="accountManagerForm.invalid" i18n="@@submit">
            Submit
          </button>
        </div>
      </nb-card-footer>
    </nb-card>
  </form>
</ng-template>
