export const patterns = {
  email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-z]{2,62}$',
  numbers: '^[0-9]*$',
  url: 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&\\/\\/=]*)',
  zipCodes: {
    be: '^[0-9]{4}$',
    de: '^[0-9]{5}$',
    nl: '^[0-9]{4}[ ]?[A-Z]{2}$',
  }
};
