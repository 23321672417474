import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {IdentityClaims} from '../../models/identityClaims';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  isAuthenticated: Observable<boolean>;
  isDoneLoading: Observable<boolean>;
  canActivateProtectedRoutes: Observable<boolean>;
  identity;

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
    this.clearStorage();
  }

  refresh() {
    this.authService.refresh();
  }

  reload() {
    window.location.reload();
  }

  clearStorage() {
    localStorage.clear();
  }

  constructor(private authService: AuthService, private oauthService: OAuthService) {
    this.isAuthenticated = this.authService.isAuthenticated$;
    this.isDoneLoading = this.authService.isDoneLoading$;
    this.canActivateProtectedRoutes = this.authService.canActivateProtectedRoutes$;
  }


  get identityClaims(): IdentityClaims {
    return this.authService.identityClaims;
  }


  ngOnInit() {
  }

}
