import {Component, Inject, LOCALE_ID, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {RestApiService} from '../../../services/rest/rest-api.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {environment} from '../../../../environments/environment';
import {Subject, Subscription} from 'rxjs';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {DataTableDirective} from 'angular-datatables';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {ClientExportDialogComponent} from './client-export-dialog/client-export-dialog.component';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {TaxonomyService} from '../../../services/taxonomy/taxonomy.service';
import {ClientService} from '../../../services/client/client.service';
import * as jQuery from 'jquery';
import {AccountManagerService} from '../../../services/account-manager/account-manager.service';
import {AccountManager} from '../../../models/AccountManager';
import {TaxonomyTerm} from '../../../models/terms/taxonomyTerm';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientListComponent implements OnInit, OnDestroy {

  canAddCompany = false;
  businessUnitTerms: Array<TaxonomyTerm> = [];
  clientTypeTerms: Array<TaxonomyTerm> = [];
  relationTypeTerms: Array<TaxonomyTerm> = [];
  accountManagerNames: Array<string> = [];
  allAccountManagers: Array<AccountManager> = [];

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  addForm: FormGroup;
  addFormShowAccountManagers = false;
  filterForm: FormGroup;


  subscription = new Subscription();

  constructor(private apiService: RestApiService,
              private http: HttpClient,
              @Inject(LOCALE_ID) private locale: string,
              private clientService: ClientService,
              private router: Router,
              private dialogService: NbDialogService,
              private authService: AuthService,
              private accountManagerService: AccountManagerService,
              private solarifToastrService: SolarifToastrService,
              private taxonomyService: TaxonomyService) {
  }

  ngOnInit() {
    this.getBusinessUnitTerms();
    this.getRelationTypeTerms();
    this.getClientTypeTerms();
    this.getAssignedAccountManagers();
    this.getAllAccountManagers();

    this.resetFilter();
    // this.initAddForm();

    this.canAddCompany = this.authService.canAddCompany();

    this.filterForm.disable();
    this.initDataTable();

  }

  resetFilter() {
    this.filterForm = new FormGroup({
      title: new FormControl(''),
      businessUnit: new FormControl(''),
      relationType: new FormControl(''),
      clientType: new FormControl(''),
      status: new FormControl(''),
      markedForChecking: new FormControl(''),
      accountManager: new FormControl(''),
      memberSearch: new FormControl('')
    });
  }

  onFilterSubmit() {
    this.redrawTable();
  }

  onExportClick() {
    this.dialogService.open(ClientExportDialogComponent, {
      context: {
        filterForm: this.filterForm
      },
    });
  }

  redrawTable() {
    this.filterForm.disable();

    if (!this.dtElement) {
      return;
    }

    // Reload the DataTables instance;
    try {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    } catch (err) {
      console.log('Datatable redraw error:', err);
    }
  }


  openAddDialog(dialog: TemplateRef<any>) {
    this.initAddForm();
    this.dialogService.open(dialog).onClose.subscribe(() => {
      this.initAddForm();
    });
  }

  initAddForm() {
    const accManager = this.allAccountManagers.filter(manager => {
      // @ts-ignore
      return (manager.uuid === this.authService.identityClaims.uuid);
    });
    // @ts-ignore
    const accManagerValue = (accManager.length !== 0) ? this.authService.identityClaims.uuid : null;
    console.log('accManagerValue', accManagerValue);
    this.addForm = new FormGroup({
      title: new FormControl(null, [Validators.required]),
      clientType: new FormControl(null, [Validators.required]),
      relationType: new FormControl(null, [Validators.required]),
      businessUnit: new FormControl(null, [Validators.required]),
      accountManager: new FormControl(accManagerValue, [Validators.required])
    });
    console.log('field value', this.addForm.get('accountManager').value);
  }

  onAddSubmit(ref: NbDialogRef<any>) {
    const title = this.addForm.get('title').value;
    const clientType = this.addForm.get('clientType').value;
    const relationType = this.addForm.get('relationType').value;
    const businessUnit = this.addForm.get('businessUnit').value;
    const accountManager = this.addForm.get('accountManager').value;

    const sub = this.clientService.add(title, clientType, relationType, businessUnit, accountManager).subscribe(data => {
      const uuid = data.data.id;
      this.solarifToastrService.success(
        $localize`:@@newClientSaved:New client has been added.`,
        $localize`:@@saved:Saved`
      );
      ref.close();
      this.router.navigateByUrl('/client/' + uuid);
    }, error => {
      this.solarifToastrService.error(
        $localize`:@@clientCreateError:Client could not be created, try again later.`
      );
    });

    this.subscription.add(sub);

  }


  cancelAdd(ref: NbDialogRef<any>) {
    ref.close();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.subscription.unsubscribe();
  }

  rowClickHandler(data) {
    this.router.navigate(['client/' + data['uuid']]);
  }

  initDataTable() {
    const that = this;

    // Default language strings based on https://datatables.net/plug-ins/i18n/English. Translate using Angular i18n.
    this.dtOptions = {
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      info: false,
      pageLength: 50,
      searchDelay: 500,
      language: {
        emptyTable: $localize`:@@datatablesEmptyTable:No data available in table.`,
        info: $localize`:@@datatablesInfo:Showing _START_ to _END_ of _TOTAL_ entries`,
        infoEmpty: $localize`:@@datatablesInfoEmpty:No results to show`,
        infoFiltered: $localize`:@@datatablesInfoFiltered:(filtered from _MAX_ total entries)`,
        infoPostFix: $localize`:@@datatablesInfoPostFix: `,
        thousands: $localize`:@@datatablesThousands: 'Enter thousands separator only.`,
        lengthMenu: $localize`:@@datatablesLengthMenu:Show _MENU_ entries`,
        loadingRecords: $localize`:@@datatablesLoadingRecords:Loading...`,
        processing: $localize`:@@datatablesProcessing:Processing...`,
        search: $localize`:@@datatablesSearch:Search:`,
        searchPlaceholder: $localize`:@@datatablesSearchPlaceholder:Search`,
        zeroRecords: $localize`:@@datatablesZeroRecords:No matching records found`,
        paginate: {
          first: $localize`:@@datatablesPaginateFirst:First`,
          last: $localize`:@@datatablesPaginateLast:Last`,
          next: $localize`:@@datatablesPaginateNext:Next`,
          previous: $localize`:@@datatablesPaginatePrevious:Previous`,
        },
        aria: {
          sortAscending: $localize`:@@datatablesAriaSortAscending:: activate to sort column ascending`,
          sortDescending: $localize`:@@datatablesAriaSortDescending:: activate to sort column descending`,
        },
      },
      ajax: (dataTablesParameters: any, callback) => {

        console.log('dtParameters', dataTablesParameters);
        const index = dataTablesParameters.order[0].column;
        const orderColumn = dataTablesParameters.columns[index].data;
        const orderDir = dataTablesParameters.order[0].dir;

        const params = new HttpParams()
          .set('draw', dataTablesParameters.draw)
          .set('start', dataTablesParameters.start)
          .set('length', dataTablesParameters.length)
          .set('column', orderColumn)
          .set('order', orderDir)
          .set('search', this.filterForm.get('title').value) // .set('search', dataTablesParameters.search.value);
          .set('businessUnit', this.filterForm.get('businessUnit').value)
          .set('relationType', this.filterForm.get('relationType').value)
          .set('clientType', this.filterForm.get('clientType').value)
          .set('status', this.filterForm.get('status').value)
          .set('markedForChecking', this.filterForm.get('markedForChecking').value)
          .set('accountManager', this.filterForm.get('accountManager').value)
          .set('memberSearch', this.filterForm.get('memberSearch').value);

        that.clientService.clients(params)
          .subscribe(response => {
            callback({
              recordsTotal: response['recordsFiltered'],
              recordsFiltered: response['recordsTotal'],
              data: response['data'],
            });
          });
      },
      columns: [
        // { title: "Client number", data: "field_clientnumber" },
        {
          title: $localize`:@@clientTitle:Title`,
          data: 'label',
          orderable: true,
          render(data, type, row, meta) {
            if (data) {
              return `<a href="client/${row.uuid}">${data}</a>`;
              // return '<span class="routerLink">' + data + '</span>';
            } else {
              return null;
            }
          },
        },
        {
          title: $localize`:@@businessUnit:Business unit`,
          data: 'business_unit',
          orderable: false,
        },
        {
          title: $localize`:@@relationType:Relation type`,
          data: 'relation_type',
          orderable: false,
        },
        {
          title: $localize`:@@clientType:Client type`,
          data: 'client_type',
          orderable: false,
        },
        {
          title: $localize`:@@clientIsMarkedForChecking:Marked for checking`,
          data: 'marked_for_checking',
          orderable: false,
        },
        {
          title: $localize`:@@clientStatus:Status`,
          data: 'status',
          orderable: false,
          render(data) {
            return (data) ? '<span class="text-capitalize">' + data + '</span>' : null;
          },
        },
        // {
        //   title: $localize`:@@contracts:Contracts`,
        //   data: 'contract_count',
        //   orderable: true,
        // },
        {
          title: $localize`:@@accountManager:Account manager`,
          data: 'account_manager',
          orderable: false,
          render(data) {
            return data ? `<div class="small">${data.replace(/\,/g, '<br>')}</div>` : data;
          }
        }
      ],
      drawCallback: _ => {
        this.filterForm.enable();
      },
      // tslint:disable-next-line:ban-types
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        jQuery('td .routerLink', row).off('click');
        jQuery('td .routerLink', row).on('click', () => {
          self.rowClickHandler(data);
        });
        return row;
      },
    };
  }

  private getBusinessUnitTerms() {
    this.taxonomyService.getTermsByVocabulary('business_unit').subscribe(data => {
      this.businessUnitTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary client type', error);
    });
  }

  private getClientTypeTerms() {
    this.taxonomyService.getTermsByVocabulary('client_type').subscribe(data => {
      this.clientTypeTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary client type', error);
    });
  }

  private getRelationTypeTerms() {
    this.taxonomyService.getTermsByVocabulary('relatie_type').subscribe(data => {
      this.relationTypeTerms = data;
    }, error => {
      console.log('Error getting terms for vocabulary client type', error);
    });
  }

  private getAssignedAccountManagers() {
    // this.accountManagerService.accountManagers().subscribe(accountManagers => {
    //   accountManagers.forEach(accountManager => {
    //     const name = accountManager.field_full_name[0] ? accountManager.field_full_name[0].value : accountManager.name[0].value;
    //     this.accountManagers.push(name);
    //   });
    // });

    this.accountManagerService.assignedAccountManagers().subscribe(accountManagers => {
      this.accountManagerNames = accountManagers.map(accountManager => (accountManager.fullName) ? accountManager.fullName : accountManager.username);
    });
  }

  private getAllAccountManagers() {
    this.accountManagerService.accountManagers().subscribe(accountManagers => {
      this.allAccountManagers = accountManagers;
      // console.log('All account managers', this.allAccountManagers);
    });
  }


}
