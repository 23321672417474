import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {RestApiService} from '../../../services/rest/rest-api.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {Subject} from 'rxjs';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-content-overview',
  templateUrl: './content-overview.component.html',
  styleUrls: ['./content-overview.component.scss']
})
export class ContentOverviewComponent implements OnInit, OnDestroy {

  constructor(private apiService: RestApiService,
              private dialogService: NbDialogService,
              private http: HttpClient,
              private toastrService: NbToastrService ) {
  }

  nodes;
  private subscription = new Subscription();

  private uuidToDelete;

  dtTrigger: Subject<any> = new Subject();


  ngOnInit() {
    const subscription = this.apiService.getNodes().subscribe(data => {
      this.nodes = data;
      this.dtTrigger.next();
    }, error => {
      console.log(error);
    });
  }

  confirmDeleteModal(dialog: TemplateRef<any>, uuid: string) {
    this.uuidToDelete = uuid;
    this.dialogService.open(dialog, {closeOnEsc: false, closeOnBackdropClick: false});
  }

  cancelDeleteNode(dialog) {
    dialog.close();
    this.uuidToDelete = null;
  }

  deleteNode(dialog) {
    console.log('uuid to delete:', this.uuidToDelete);
    const subscription = this.http.delete(environment.drupalUrl + '/jsonapi/node/page/' + this.uuidToDelete).subscribe(result => {
        this.toastrService.show('Node has been deleted.', 'Deleted', {status: 'success', icon: 'checkmark-outline'});
        dialog.close();
      },
      error => {
        this.toastrService.show('Node could not be deleted.', 'Error', {status: 'danger', icon: 'close-outline'});
        dialog.close();
      });
    this.subscription.add(subscription);
  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.dtTrigger.unsubscribe();
  }

}
