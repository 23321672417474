import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Client} from '../../../models/client/client';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {LockingService} from '../../../services/locking/locking.service';
import {Subscription} from 'rxjs';
import {I18n} from "@ngx-translate/i18n-polyfill";
import {Socket} from "ngx-socket-io";
import {ClientService} from "../../../services/client/client.service";

@Component({
  selector: 'app-client-memo',
  templateUrl: './client-memo.component.html',
  styleUrls: ['./client-memo.component.scss']
})
export class ClientMemoComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line:variable-name
  component_name = 'client-memo';
  currentLock = 0;
  private subscription: Subscription;

  @Input() client: Client;
  memoForm: FormGroup;

  get memo() { return this.memoForm.get('memo'); }

  constructor(
    private dialogService: NbDialogService,
    private httpClient: HttpClient,
    private toastrService: SolarifToastrService,
    private lockingService: LockingService,
    private clientService: ClientService,
    private socket: Socket
  ) { }

  ngOnInit() {
    this.subscription = this.socket.fromEvent<any>(this.component_name).subscribe(currentLock => this.currentLock = currentLock);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initForm() {
    this.memoForm = new FormGroup({
      memo: new FormControl(this.client.memo, [Validators.maxLength(1000)])
    });
  }

  openEditDialog(dialog: TemplateRef<any>) {
    this.addLock();
    this.initForm();
    this.dialogService.open(dialog).onClose.subscribe(() => {
      this.initForm();
      this.removeLock();
    });
  }

  cancelEdit(ref: NbDialogRef<any>) {
    this.initForm();
    ref.close();
  }

  onSubmit(ref) {

    const body = {
      data: {
        type: 'group--relation',
        id: this.client.id,
        attributes: {
          field_memo: this.memoForm.get('memo').value
        },
      }
    };

    this.httpClient.patch(environment.drupalUrl + '/jsonapi/group/relation/' + this.client.id, body).subscribe(result => {
      this.toastrService.success(
        $localize`:@@generalInfoUpdateSuccess:Information has been updated`,
        $localize`:@@saved:Saved`,
      );
      this.clientService.client(this.client.id).subscribe(response => {
        this.client = response;
        this.initForm();
      });
      ref.close();
    }, error => {
      this.toastrService.error(
        $localize`:@@generalInfoUpdateFailure:Information could not be updated`,
        $localize`:@@errorSaving:Error`,
      );
    });
  }

  addLock() {
    this.lockingService.addLock(this.client.id, this.component_name);
  }

  removeLock() {
    this.lockingService.removeLock(this.client.id, this.component_name);
  }

}
