import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbButtonModule,
  NbSidebarService,
  NbIconModule,
  NbSelectModule,
  NbActionsModule,
  NbSearchModule,
  NbMenuModule,
  NbCardModule,
  NbInputModule,
  NbContextMenuModule,
  NbToastrModule,
  NbDialogModule,
  NbTabsetModule,
  NbBadgeModule,
  NbListModule,
  NbUserModule,
  NbCheckboxModule,
  NbRadioModule,
  NbDatepickerModule,
  NbAlertModule,
  NbToggleModule,
  NbSpinnerModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {AddPageComponent} from './components/add-page/add-page.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {environment} from '../environments/environment';
import {AuthGuardService} from './services/auth-guard.service';
import {LoginComponent} from './components/login/login.component';
import {AdminDashboardComponent} from './components/admin/admin-dashboard/admin-dashboard.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContentOverviewComponent} from './components/admin/content-overview/content-overview.component';
import {DataTablesModule} from 'angular-datatables';

import {AuthConfig, OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {AuthService} from './services/auth.service';
import {authConfig} from './auth.config';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {EditPageComponent} from './components/admin/edit-page/edit-page.component';
import {NodeResolverService} from './services/resolver/node-resolver.service';
import {ClientComponent} from './components/client/client.component';
import {ClientResolverService} from './services/resolver/client-resolver.service';
import {ClientContactsListComponent} from './components/client/client-contacts/client-contacts-list/client-contacts-list.component';
import {ClientContactInfoComponent} from './components/client/client-contact-info/client-contact-info.component';
import {ClientActivitiesComponent} from './components/client/client-activities/client-activities.component';
import {ClientAddressesComponent} from './components/client/client-addresses/client-addresses.component';
import {ClientGeneralInfoComponent} from './components/client/client-general-info/client-general-info.component';
import {ClientAddressComponent} from './components/client/client-addresses/client-address/client-address.component';
import {PatchHeaderInterceptor} from './services/interceptors/PatchHeaderInterceptor';
import {TaxonomyService} from './services/taxonomy/taxonomy.service';
// tslint:disable-next-line:max-line-length
import {ClientContactListItemComponent} from './components/client/client-contacts/client-contacts-list/client-contact-list-item/client-contact-list-item.component';
import {AtpDialogComponent} from './components/atp/atp-dialog/atp-dialog.component';
import {FormSubmissionComponent} from './components/form-submission/form-submission.component';
import {ClientActivityComponent} from './components/client/client-activities/client-activity/client-activity.component';
import {ClientAccountManagerComponent} from './components/client/client-account-manager/client-account-manager.component';
import {OpenFactuurPipe} from './pipes/open-factuur.pipe';
import {ClientListComponent} from './components/client/client-list/client-list.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

/* Socket IO */
import {LockingService} from './services/locking/locking.service';
import {SocketIoModule, SocketIoConfig} from 'ngx-socket-io';
const socketConfig: SocketIoConfig = environment.socketConfig;

/* Locale data */
import {registerLocaleData} from '@angular/common';
import localeEnGbExtra from '@angular/common/locales/extra/en-GB';
import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/nl';
import {ParseToArrayPipe} from './pipes/parse-to-array.pipe';
// tslint:disable-next-line:max-line-length
import {ClientContactsPrimaryListComponent} from './components/client/client-contacts/client-contacts-primary-list/client-contacts-primary-list.component';
import {I18n} from '@ngx-translate/i18n-polyfill';
import { ClientCheckingAlertComponent } from './components/client/client-checking-alert/client-checking-alert.component';
import { CalculationDataComponent } from './components/calculation-data/calculation-data.component';
import { ClientPolicyContractsComponent } from './components/client/client-policy-contracts/client-policy-contracts.component';
// tslint:disable-next-line:max-line-length
import { ClientContactListItemEmptyComponent } from './components/client/client-contacts/client-contacts-list/client-contact-list-item-empty/client-contact-list-item-empty.component';
import {FormatOptionValuePipe} from './pipes/format-option-value.pipe';
import { ClientMemoComponent } from './components/client/client-memo/client-memo.component';
import {PolicyApplicationsComponent} from './components/policy-applications/policy-applications.component';
import {ClientExportDialogComponent} from './components/client/client-list/client-export-dialog/client-export-dialog.component';
import {ClientActivityDialogComponent} from './components/client/client-activities/client-activity-dialog/client-activity-dialog.component';
import {ClientOrganizationChartComponent} from './components/client/client-general-info/client-organization-chart/client-organization-chart.component';
import { TaskDialogComponent } from './components/task/task-dialog/task-dialog.component';
import { TasksComponent } from './components/task/tasks.component';
import { TaskListItemComponent } from './components/task/task-list/task-list-item/task-list-item.component';
import { TaskListComponent } from './components/task/task-list/task-list.component';
import { SolarifDatePipe } from './pipes/solarif-date.pipe';
import { TaskDatePipe } from './pipes/task-date.pipe';
import { TaskFilterComponent } from './components/task/task-filter/task-filter.component';
import { TaskConfirmDeleteComponent } from './components/task/task-confirm-delete/task-confirm-delete.component';
import { PolicyContractStatusFormatPipe } from './pipes/policy-contract-status-format.pipe';

registerLocaleData(localeEn, 'en', localeEnGbExtra);
registerLocaleData(localeNl, 'nl', localeNlExtra);

declare const require;

@NgModule({
  declarations: [
    AppComponent,
    AddPageComponent,
    LoginComponent,
    AdminDashboardComponent,
    HeaderComponent,
    ContentOverviewComponent,
    EditPageComponent,
    ClientListComponent,
    ClientComponent,
    ClientContactsListComponent,
    ClientContactInfoComponent,
    ClientActivitiesComponent,
    ClientAddressesComponent,
    ClientContactsPrimaryListComponent,
    ClientGeneralInfoComponent,
    ClientAddressComponent,
    ClientContactListItemComponent,
    AtpDialogComponent,
    FormSubmissionComponent,
    ClientActivityComponent,
    ClientAccountManagerComponent,
    OpenFactuurPipe,
    ClientListComponent,
    ParseToArrayPipe,
    ClientCheckingAlertComponent,
    CalculationDataComponent,
    ClientPolicyContractsComponent,
    ClientContactListItemEmptyComponent,
    FormatOptionValuePipe,
    ClientMemoComponent,
    ClientExportDialogComponent,
    PolicyApplicationsComponent,
    ClientActivityDialogComponent,
    ClientOrganizationChartComponent,
    TaskDialogComponent,
    TasksComponent,
    TaskListItemComponent,
    TaskListComponent,
    SolarifDatePipe,
    TaskDatePipe,
    TaskFilterComponent,
    TaskConfirmDeleteComponent,
    PolicyContractStatusFormatPipe
  ],
  imports: [
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.drupalUrl + '/api', environment.drupalUrl + '/node', environment.drupalUrl + '/jsonapi'],
        sendAccessToken: true
      }
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({name: 'solarif-custom'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbButtonModule,
    NbIconModule,
    NbSelectModule,
    NbActionsModule,
    NbSearchModule,
    NbInputModule,
    NbMenuModule.forRoot(),
    NbCardModule,
    NbTabsetModule,
    NbListModule,
    CKEditorModule,
    FormsModule,
    DataTablesModule,
    NbContextMenuModule,
    NbToastrModule.forRoot({duration: 10000}),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    NbDialogModule.forRoot(),
    NbUserModule,
    NbCheckboxModule,
    ReactiveFormsModule,
    NbRadioModule,
    FontAwesomeModule,
    NbDatepickerModule.forRoot(),
    NbAlertModule,
    NbToggleModule,
    NbSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SocketIoModule.forRoot(socketConfig),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PatchHeaderInterceptor,
      multi: true
    },
    AuthService,
    TaxonomyService,
    {
      provide: AuthConfig,
      useValue: authConfig
    },
    AuthGuardService,
    NbSidebarService,
    {
      provide: OAuthStorage,
      useValue: localStorage
    },
    {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'},
    // {
    //   provide: TRANSLATIONS,
    //   useFactory: (locale) => {
    //     locale = locale || 'en'; // default to english if no locale provided
    //     return require(`raw-loader!../locale/messages.${locale}.xlf`);
    //   },
    //   deps: [LOCALE_ID]
    // },
    // {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'},
    // {
    //   provide: TRANSLATIONS, useFactory: (locale) => {
    //     locale = locale || 'en'; // default to english if no locale provided
    //     if (locale !== 'en') {
    //       return require(`raw-loader!../locale/messages.${locale}.xlf`).default;
    //     } else {
    //       return '';
    //     }
    //   },
    //   deps: [LOCALE_ID]
    // },
    // I18n,
    NodeResolverService,
    ClientResolverService,
    LockingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
