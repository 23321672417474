<nb-card *ngIf="isAuthenticated | async; else notLoggedIn">
  <nb-card-header>
    <h1><span i18n="@@welcome">Welcome</span>, <span *ngIf="identityClaims.name">{{ identityClaims.name }}</span></h1>
  </nb-card-header>
  <nb-card-body>
    <p i18n="@@loginMenuHelpText">Use the side-menu on the left to navigate the site.</p>
    <p></p>
  </nb-card-body>
</nb-card>
<ng-template #notLoggedIn>
  <nb-card>
    <nb-card-header>
      <h1 i18n="@@login">Login</h1>
    </nb-card-header>
    <nb-card-body *ngIf="isDoneLoading | async; else authorizing">
      <p i18n="@@loginWithAccount">Please log in using your Solarif Klantenportal account.</p>
      <button nbButton (click)="login()" i18n="@@login">Login</button>
    </nb-card-body>
    <ng-template #authorizing>
      <nb-card-body>
        <p i18n="@@authorizing">Authorizing, please wait...</p>
      </nb-card-body>
    </ng-template>
  </nb-card>
</ng-template>
