import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {EMPTY, Observable, of} from 'rxjs';
import {concatMap, expand, finalize, map, reduce, share, tap} from 'rxjs/operators';
import {empty} from 'rxjs/internal/Observer';
import {
  ActivityTaxonomyTerm,
  ContactTypeTaxonomyTerm,
  TaskTypeTaxonomyTerm,
  TaxonomyTerm
} from '../../models/terms/taxonomyTerm';

@Injectable()
export class TaxonomyService {

  termsByVocabulary = new Map<string, Array<TaxonomyTerm>>();
  cachedObservable = new Map<string, Observable<Array<TaxonomyTerm>>>();

  constructor(private httpClient: HttpClient) {
  }

  getTermsByVocabulary(vocabulary: string): Observable<Array<TaxonomyTerm>> {

    let observable: Observable<Array<TaxonomyTerm>>;

    const localValues = this.termsByVocabulary.get(vocabulary);
    if (localValues && localValues.length > 0) {
      console.log(`Getting local values for ${vocabulary}`, localValues);
      observable = of(localValues);
    } else if (this.cachedObservable.get(vocabulary)) {
      console.log('Getting cached observable for', vocabulary);
      observable = this.cachedObservable.get(vocabulary);
    } else {
      // this.termsByVocabulary.set(vocabulary, []);

      const httpRequest = this.httpClient.get<any>(`${environment.drupalUrl}/jsonapi/taxonomy_term/${vocabulary}?sort=weight`)
        .pipe(
          expand((response) => {
            if (response && response.links && response.links.next) {
              // console.log(`getTermsByVocabulary ${vocabulary} NEXT`, response.links.next.href);
              return this.httpClient.get<any>(response.links.next.href);
            } else {
              // console.log(`getTermsByVocabulary ${vocabulary} END`);
              return EMPTY;
            }
          }),
          share(),
          map(response => {
            console.log(`getTermsByVocabulary ${vocabulary} response`, response);

            return this.mapTerms(response, vocabulary);
          }),
          reduce((acc, x: any) =>
            acc.concat(x), []
          ),
          finalize(() => this.cachedObservable.set(vocabulary, null))
        );
      this.cachedObservable.set(vocabulary, httpRequest);
      observable = this.cachedObservable.get(vocabulary);
    }

    return observable;
  }

  mapTerms(response, vocabulary) {
    const termsArray: Array<TaxonomyTerm> = [];

    response.data.forEach(term => {
      const taxonomyTerm: TaxonomyTerm = {
        id: term.id,
        name: term.attributes.name,
        type: term.type,
        weight: term.attributes.weight,
        status: term.attributes.status,
        termId: term.attributes.drupal_internal__tid
      };

      if (taxonomyTerm.status !== null && taxonomyTerm.status !== undefined && taxonomyTerm.status === false) {
        return;
      }

      switch (taxonomyTerm.type) {
        case 'taxonomy_term--activiteit_type':
          const activityTerm: ActivityTaxonomyTerm = {
            ...taxonomyTerm,
            icon: term.attributes.field_icon,
            userCanCreate: term.attributes.field_user_can_create
          };
          termsArray.push(activityTerm);
          break;
        case 'taxonomy_term--task_type':
          const taskTypeTerm: TaskTypeTaxonomyTerm = {
            ...taxonomyTerm,
            icon: term.attributes.field_icon,
          };
          termsArray.push(taskTypeTerm);
          break;
        case 'taxonomy_term--contact_type':
          const contactTypeTerm: ContactTypeTaxonomyTerm = {
            ...taxonomyTerm,
            icon: term.attributes.field_icon,
          };
          termsArray.push(contactTypeTerm);
          break;
        default:
          termsArray.push(taxonomyTerm);
      }
    });

    if (termsArray.length > 0) {
      const currentTerms = this.termsByVocabulary.get(vocabulary);

      if (currentTerms) {
        termsArray.forEach(termToAdd => {
          if (currentTerms.filter(term => term.id === termToAdd.id).length === 0) {
            currentTerms.push(termToAdd);
          }
        });

        this.termsByVocabulary.set(vocabulary, currentTerms);

      } else {
        this.termsByVocabulary.set(vocabulary, termsArray);
      }

      return termsArray;

    } else {
      return null;
    }
  }
}
