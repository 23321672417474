import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';

export const authConfig: AuthConfig = {
  // Url of the provider
  issuer: environment.issuerUrl + '/',
  skipIssuerCheck: true,
  redirectUri: window.location.origin + '/',
  scope: 'openid profile email user',
  requireHttps: false,
  oidc: true,
  responseType: 'token id_token',
  requestAccessToken: true,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  silentRefreshTimeout: 5000,
  timeoutFactor: 0.1,
  clientId: 'angular_backoffice',
  /*tokenEndpoint: 'http://jsonapi-drupal.localhost/oauth2/token',*/
};
