import {Component, Input, OnInit} from '@angular/core';
import {Task} from '../../../../models/task';
import {TaskDialogComponent} from '../../task-dialog/task-dialog.component';
import {NbDialogService} from '@nebular/theme';
import {AccountManagerService} from '../../../../services/account-manager/account-manager.service';
import {AccountManager} from '../../../../models/AccountManager';
import {TaxonomyService} from '../../../../services/taxonomy/taxonomy.service';
import {Client} from '../../../../models/client/client';
import {ClientService} from '../../../../services/client/client.service';

@Component({
  selector: 'app-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss']
})
export class TaskListItemComponent implements OnInit {

  @Input() task: Task;
  @Input() accountManagers: Array<AccountManager>;
  @Input() taskStatusTerms: Array<any>;
  @Input() taskTypeTerms: Array<any>;
  // @Input() businessUnitTerms: Array<any>;
  @Input() viewOnly = false;

  assigneeName = '';
  status = '';
  type: {icon: string, name: string} = {icon: '', name: ''};

  constructor(private dialogService: NbDialogService,
              private taxonomyService: TaxonomyService,
              private clientService: ClientService,
              private accountManagerService: AccountManagerService) { }

  ngOnInit() {
    if (!this.accountManagers) {
      this.getAccountManagers();
    } else {
      this.updateAssignee();
    }

    if (!this.taskStatusTerms) {
      this.getTaskStatusTerms();
    } else {
      this.updateStatus();
    }

    if (!this.taskTypeTerms) {
      this.getTaskTypeTerms();
    } else {
      this.updateType();
    }

    if (this.task.clientId) {
      this.getTaskClient();
    }
  }

  openEditDialog(): void {
    this.dialogService.open(TaskDialogComponent, {
      context: {
        actionType: 'edit',
        task: this.task,
        client: this.task.client,
        // businessUnitTerms: this.businessUnitTerms,
        accountManagers: this.accountManagers,
        taskStatusTerms: this.taskStatusTerms,
        taskTypeTerms: this.taskTypeTerms
      },
    });
  }

  getInitials(val: string) {
    return val.split(' ').map((n) => n.charAt(0))
      .splice(0, 2)
      .join('')
      .toUpperCase();
  }

  private updateAssignee() {
    if (!this.task.assignee) { return; }

    const assignee = this.accountManagers.filter(manager => manager.uuid === this.task.assignee)[0];
    this.assigneeName = (assignee.fullName) ? assignee.fullName : assignee.username;
  }

  private updateStatus() {
    if (!this.task.statusId) { return; }

    const myStatus = this.taskStatusTerms.filter(status => status.id === this.task.statusId)[0];
    if (!myStatus) { return; }

    this.status = myStatus.name;
  }

  private updateType() {
    if (!this.task.typeId) { return; }

    const myType = this.taskTypeTerms.filter(type => type.id === this.task.typeId)[0];
    if (!myType) { return; } // are you not my type???

    this.type = {
      icon: myType.icon,
      name: myType.name
    };
  }

  private getTaskClient() {
    if (!this.task.clientId) { return; }
    if (this.task.client) { return; }
    this.clientService.client(this.task.clientId, null, null).subscribe(client => {
      this.task.client = client;
    });
  }

  private getAccountManagers() {
    this.accountManagerService.accountManagers().subscribe(accountManagers => {
      this.accountManagers = accountManagers;
      this.updateAssignee();
    });
  }

  private getTaskTypeTerms() {
    this.taxonomyService.getTermsByVocabulary('task_type').subscribe(data => {
      this.taskTypeTerms = data;
      this.updateType();
    });
  }

  private getTaskStatusTerms() {
    this.taxonomyService.getTermsByVocabulary('task_status').subscribe(data => {
      this.taskStatusTerms = data;
      this.updateStatus();
    });
  }

  // private getBusinessUnitTerms() {
  //   this.taxonomyService.getTermsByVocabulary('business_unit').subscribe(data => {
  //     this.businessUnitTerms = data;
  //   });
  // }

}
