import {Injectable} from '@angular/core';
import {NbToastrService} from '@nebular/theme';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Injectable({
  providedIn: 'root'
})
export class SolarifToastrService {

  constructor(private toastrService: NbToastrService) {
  }

  basic(message: string, title: string) {
    this.toastrService.show(
      message,
      title,
      {status: '', icon: 'alert-circle-outline', duration: 1500}
    );
  }

  success(message: string, title = $localize`:@@toastSaved:Saved`) {
    this.toastrService.show(
      message,
      title,
      {status: 'success', icon: 'checkmark-outline'}
    );
  }

  warning(message: string, title = $localize`:@@toastWarning:Warning`) {
    this.toastrService.show(
      message,
      title,
      {status: 'warning', icon: 'alert-triangle-outline'}
    );
  }

  info(message: string, title = $localize`:@@toastInfo:Info`) {
    this.toastrService.show(
      message,
      title,
      {status: 'info', icon: 'alert-triangle-outline'}
    );
  }

  error(message: string, title = $localize`:@@toastError:Error`) {
    this.danger(message, title);
  }

  danger(message: string, title = $localize`:@@toastDanger:Danger`) {
    this.toastrService.show(
      message,
      title,
      {status: 'danger', icon: 'close-outline'}
    );
  }
}
