import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {ActivityService} from 'src/app/services/activity/activity.service';
import {SolarifToastrService} from 'src/app/services/toastr/solarif-toastr.service';
import {Client} from '../../../../models/client/client';
import {Activity} from '../../../../models/activity/activity';
import {patterns} from '../../../../validators/patterns';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-client-activity-dialog',
  templateUrl: './client-activity-dialog.component.html',
  styleUrls: ['./client-activity-dialog.component.scss']
})
export class ClientActivityDialogComponent implements OnInit {

  @Input() activity?: Activity;
  @Input() businessUnitTerms: Array<any>;
  @Input() client: Client;
  @Input() allowedActivityTypeTerms: Array<any>;
  @Input() actionType: 'add' | 'edit';

  form: FormGroup;
  isSaving: boolean;

  constructor(
    protected ref: NbDialogRef<ClientActivityDialogComponent>,
    private toastrService: SolarifToastrService,
    private activityService: ActivityService
  ) {
  }

  ngOnInit() {
    this.createForm();
    this.isSaving = false;
  }

  private createForm() {
    const fb = new FormBuilder();
    const businessUnitFormGroup = fb.group({});
    for (const businessUnitTerm of this.businessUnitTerms) {
      businessUnitFormGroup.addControl(businessUnitTerm.name, new FormControl(false));
    }

    this.form = new FormGroup({
      dateTime: new FormControl(new Date(), Validators.required),
      description: new FormControl(null, Validators.required),
      businessUnits: businessUnitFormGroup,
      timeSpent: new FormControl(0, [
        Validators.required,
        Validators.pattern(patterns.numbers)
      ]),
      title: new FormControl(null, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      type: new FormControl(null, [Validators.required]),
    });

    if (this.activity) {
      this.form.get('dateTime').setValue(this.activity.date);
      this.form.get('description').setValue(this.activity.long_description);
      this.form.get('timeSpent').setValue(this.activity.time_spent);
      this.form.get('title').setValue(this.activity.title);
      this.form.get('type').setValue(this.activity.type_uuid);
      for (const businessUnitTerm of this.businessUnitTerms) {
        const isChecked = this.activity.business_unit_ids.includes(businessUnitTerm.termId.toString());
        businessUnitFormGroup.get(businessUnitTerm.name).setValue(isChecked);
      }
    }
    console.log('Form', this.form);
  }

  onSubmit() {

    this.isSaving = true;

    const businessUnits = this.form.get('businessUnits').value;
    const businessUnitIds = this.businessUnitTerms.filter(term => businessUnits[term.name]).map(term => term.id);

    const activityPatch = {
      date: UtilitiesService.ISODateString(new Date(this.form.get('dateTime').value)),
      description: this.form.get('description').value,
      title: this.form.get('title').value,
      typeId: this.form.get('type').value,
      timeSpent: this.form.get('timeSpent').value,
      clientId: this.client.id,
      businessUnitIds
    };

    console.log('activityPatch', activityPatch);
    console.log('this.activity', this.activity );

    const req = this.activity ? this.activityService.patchActivity(this.activity.id, activityPatch) :
      this.activityService.postActivity(activityPatch);
    req.subscribe(result => {
      this.toastrService.success(
        $localize`:@@activitySaveSuccess:Activity has been saved.`,
        $localize`:@@saved:Saved`
      );

      this.activityService.reloadActivities();

      this.ref.close();
      this.isSaving = false;
    }, error => {
      this.isSaving = false;
      this.toastrService.error($localize`:@@activityAddFailure:Activity could not be saved.`);
    });
  }

  cancelDialog() {
    if (!this.isSaving) {
      this.ref.close();
    }
  }

}
