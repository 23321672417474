import {Component, Input, OnInit} from '@angular/core';
import {TaskDialogComponent} from './task-dialog/task-dialog.component';
import {NbDialogService} from '@nebular/theme';
import {TaxonomyService} from '../../services/taxonomy/taxonomy.service';
import {AccountManager} from '../../models/AccountManager';
import {AccountManagerService} from '../../services/account-manager/account-manager.service';
import {ActivatedRoute} from '@angular/router';
import {TaskService} from '../../services/task/task.service';
import {flatMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {ExcelExportService} from '../../services/excel/excel-export.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  businessUnitTerms: Array<any>;
  accountManagers: Array<AccountManager>;
  taskTypeTerms: Array<any>;
  taskStatusTerms: Array<any>;

  loadingExport = false;

  constructor(private route: ActivatedRoute,
              private taskService: TaskService,
              private dialogService: NbDialogService,
              private excelExportService: ExcelExportService) { }

  ngOnInit() {
    const referencedData = this.route.snapshot.data.referencedData;
    this.accountManagers = referencedData.accountManagers;
    this.businessUnitTerms = referencedData.businessUnits;
    this.taskStatusTerms = referencedData.statuses;
    this.taskTypeTerms = referencedData.types;
  }

  openAddDialog(): void {
    this.dialogService.open(TaskDialogComponent, {
      context: {
        actionType: 'add',
        businessUnitTerms: this.businessUnitTerms,
        accountManagers: this.accountManagers,
        taskStatusTerms: this.taskStatusTerms,
        taskTypeTerms: this.taskTypeTerms
      },
    });
  }

  export(): void {
    this.loadingExport = true;
    this.taskService.getFilteredTasks()
      .pipe(
        flatMap(tasks => {
          if (!tasks) {
            return of(null);
          }

          const headers = [
            $localize`:@@title:Title`,
            $localize`:@@client:Client`,
            $localize`:@@businessUnits:Business units`,
            $localize`:@@policy:Policy`,
            $localize`:@@dossier:Dossier`,
            $localize`:@@description:Description`,
            $localize`:@@taskStatus:Status`,
            $localize`:@@taskType:Type`,
            $localize`:@@dateCreated:Date created`,
            $localize`:@@expireDate:Expire date`,
            $localize`:@@dateFinished:Date finished`,
            $localize`:@@employee:Employee`,
            $localize`:@@memo:Memo`,
          ];

          const rows = tasks.map(task => {

            let assigneeName;
            if (task.assignee) {
              const assignee = this.accountManagers.filter(manager => manager.uuid === task.assignee)[0];
              assigneeName = (assignee.fullName) ? assignee.fullName : assignee.username;
            }

            let businessUnitsString;
            if (task.businessUnitIds) {
              businessUnitsString = this.businessUnitTerms
                .filter(businessUnit => task.businessUnitIds.includes(businessUnit.id))
                .map(businessUnit => businessUnit.name)
                .join(', ');
            }

            let statusString;
            if (task.statusId) {
              statusString = this.taskStatusTerms
                .filter(status => status.id === task.statusId)
                .map(status => status.name)
                .join(', ');
            }

            let typeString;
            if (task.typeId) {
              typeString = this.taskTypeTerms
                .filter(type => type.id === task.typeId)
                .map(type => type.name)
                .join(', ');
            }

            return [
              task.title,
              (task.client) ? task.client.title : '',
              businessUnitsString,
              task.policy,
              task.dossier,
              task.longDescription,
              statusString,
              typeString,
              this.formatTaskDate(task.dateCreated),
              this.formatTaskDate(task.expireDate),
              (task.dateFinished) ? this.formatTaskDate(task.dateFinished) : null,
              assigneeName,
              task.memo
            ];
          });
          console.log(`Exporting data`, {
            headers,
            rows
          });
          return this.excelExportService.export(headers, rows, `Task_export`);
        })
      )
      .subscribe(data => {
        this.loadingExport = false;
        if (data.url) {
          window.location.href = data.url;
        }
      });
  }

  private formatTaskDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let hour = '' + d.getHours();
    let minute = '' + d.getMinutes();
    let seconds = '' + d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if (hour.length < 2) {
      hour = '0' + hour;
    }
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    if (seconds.length < 2) {
      seconds = '0' + seconds;
    }

    return `${[day, month, year].join('-')} ${[hour, minute, seconds].join(':')}`;
  }

  private getExportTitleDate() {
    const d = new Date();
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const hour = '' + d.getHours();
    const minute = '' + d.getMinutes();
    const seconds = '' + d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day, hour, minute, seconds].join('');
  }
}
