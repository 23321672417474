<nb-alert outline="warning">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <strong i18n="@@clientCheckingAlertTitle">This client needs to be checked</strong>
        <p class="m-0" i18n="@@clientCheckingAlertDescription">Take a look at this client's data and update it where needed. Once finished, click the 'Checked' button.</p>
      </div>
      <div>
        <button class="btn btn-primary" (click)="markChecked()">
          <nb-icon icon="checkmark-outline"></nb-icon> <span i18n="@@clientButtonChecked">Checked</span>
        </button>
      </div>
    </div>
  </nb-alert>
