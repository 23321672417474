import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {
  faBriefcase, faLanguage, faMobileAlt,
  faPhoneAlt, faTags, faToggleOn,
  faVenusMars
} from '@fortawesome/free-solid-svg-icons';
import {faSkype} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope as faEnvelopeRegular,
  faBuilding as faBuildingRegular,
  faIdBadge,
} from '@fortawesome/free-regular-svg-icons';
import {ContactService} from '../../../../../services/contact/contact.service';
import {SolarifToastrService} from 'src/app/services/toastr/solarif-toastr.service';
import {LockingService} from '../../../../../services/locking/locking.service';
import {Socket} from 'ngx-socket-io';
import {patterns} from '../../../../../validators/patterns';
import {Client} from '../../../../../models/client/client';
import {ClientContact} from '../../../../../models/client/clientcontact';
import {ContactTypeTaxonomyTerm, TaxonomyTerm} from '../../../../../models/terms/taxonomyTerm';
import {ClientContactPatch} from '../../../../../models/client/client-contact-patch';

@Component({
  selector: 'app-client-contact-list-item',
  templateUrl: './client-contact-list-item.component.html',
  styleUrls: ['./client-contact-list-item.component.scss']
})
export class ClientContactListItemComponent implements OnInit, OnDestroy {
  componentName = 'client-contact-list-item';
  currentLock = 0;
  @Input() contact: ClientContact;
  @Input() client: Client;

  @Input() showInitials = true;
  showMoreInfo = false;

  subscription = new Subscription();
  lockingSubscription = new Subscription();

  @Input() contactTypeTerms: Array<ContactTypeTaxonomyTerm> = [];
  selectedClientType;

  @Input() languageTerms: Array<TaxonomyTerm> = [];
  selectedLanguages;

  @Input() departmentTerms: Array<TaxonomyTerm> = [];
  selectedDepartment;

  originalFormState;
  clientEditForm: FormGroup;

  icons = {
    phone: faPhoneAlt,
    contactFunction: faBriefcase,
    gender: faVenusMars,
    mobilePhone: faMobileAlt,
    primaryEmail: faEnvelopeRegular,
    secondaryEmail: faEnvelopeRegular,
    languages: faLanguage,
    tags: faTags,
    skype: faSkype,
    status: faToggleOn,
    department: faBuildingRegular,
    contactType: faIdBadge
  };

  constructor(private dialogService: NbDialogService,
              private contactService: ContactService,
              private toastrService: SolarifToastrService,
              private http: HttpClient,
              private lockingService: LockingService,
              private socket: Socket) {
  }

  ngOnInit() {
    if (this.contact) {
      this.lockingSubscription = this.socket.fromEvent<any>(this.componentName).subscribe(currentLock => this.currentLock = currentLock);


      this.buildDisplayName();

      this.clientEditForm = new FormGroup({
        firstName: new FormControl(this.contact.firstName, Validators.required),
        insertion: new FormControl(this.contact.insertions),
        lastName: new FormControl(this.contact.lastName, Validators.required),
        gender: new FormControl(this.contact.gender, Validators.required),
        secondaryEmail: new FormControl(this.contact.secondaryEmailAddress, Validators.pattern(patterns.email)),
        skypeAccount: new FormControl(this.contact.skypeAccount),
        contactFunction: new FormControl(this.contact.contactFunction),
        contactType: new FormControl(this.contact.contactType),
        languages: new FormControl(this.selectedLanguages),
        department: new FormControl(this.selectedDepartment),
        status: new FormControl(this.contact.status, Validators.required),
        primaryContact: new FormControl(this.contact.primary)
      });

      // If there's any phone numbers, loop through them and create the correct amount of inputs.
      if (this.contact.phoneNumber && this.contact.phoneNumber.length > 0) {
        let i = 0;
        this.contact.phoneNumber.forEach(phoneNumber => {
          // During the first iteration the forArray has to be initialized.
          if (i === 0) {
            this.clientEditForm.addControl('phoneNumberArray', new FormArray([this.createPhoneNumber(phoneNumber)]));
          } else {
            this.addAdditionalPhoneNumber(phoneNumber);
          }
          i++;
        });
      } else {
        // No phone numbers, add an empty control to the array.
        this.clientEditForm.addControl('phoneNumberArray', new FormArray([this.createPhoneNumber()]));
      }

      // If there's any email addresses, loop through them and create the correct amount of inputs.
      if (this.contact.primaryEmailAddress && this.contact.primaryEmailAddress.length > 0) {
        let i = 0;
        this.contact.primaryEmailAddress.forEach(emailAddress => {
          // During the first iteration the forArray has to be initialized.
          if (i === 0) {
            this.clientEditForm.addControl('emailAddressArray', new FormArray([this.createEmailAddress(emailAddress)]));
          } else {
            this.addAdditionalEmailAddress(emailAddress);
          }
          i++;
        });
      } else {
        // No phone numbers, add an empty control to the array.
        this.clientEditForm.addControl('emailAddressArray', new FormArray([this.createEmailAddress()]));
      }

      if (this.contact.contactType) {
        if (this.contact.contactType.length > 0) {
          this.contact.contactType.forEach(type => {
            this.selectedClientType = type.id;
            this.clientEditForm.get('contactType').setValue(type.id);
          });
        }
      }

      if (this.contact.department) {
        if (this.contact.department.length > 0) {
          this.contact.department.forEach(type => {
            this.selectedDepartment = type.id;
            this.clientEditForm.get('department').setValue(type.id);
          });
        }
      }

      if (this.contact.languages) {
        this.contact.languages.forEach(language => {
          this.selectedLanguages = language.id;
        });
        this.clientEditForm.get('languages').setValue(this.selectedLanguages);
      }
      this.originalFormState = this.clientEditForm.value;

    }
  }

  createPhoneNumber(phoneNumber = null) {
    return new FormGroup({
      phone: new FormControl(phoneNumber)
    });
  }

  addAdditionalPhoneNumber(phoneNumber = null) {
    (this.clientEditForm.get('phoneNumberArray') as FormArray).push(this.createPhoneNumber(phoneNumber));
  }

  createEmailAddress(emailAddress = null) {
    return new FormGroup({
      email: new FormControl(emailAddress, [Validators.pattern(patterns.email), Validators.required])
    });
  }

  addAdditionalEmailAddress(emailAddress = null) {
    (this.clientEditForm.get('emailAddressArray') as FormArray).push(this.createEmailAddress(emailAddress));
  }

  toggleMoreInfo() {
    this.showMoreInfo = !this.showMoreInfo;
  }

  getInitials() {
    if (this.contact.displayName) {
      const names = this.contact.displayName.split(' ');
      return names.map((n) => n.charAt(0))
        .splice(0, 2)
        .join('')
        .toUpperCase();
    }
    return '';
  }

  get emailAddressFormData() {
    return this.clientEditForm.get('emailAddressArray') as FormArray;
  }

  get phoneNumberFormData() {
    return this.clientEditForm.get('phoneNumberArray') as FormArray;
  }

  formatTags(): string {
    return this.contact.tags.join(', ');
  }

  openEditDialog(dialog: TemplateRef<any>) {
    this.addLock();
    this.dialogService.open(dialog).onClose.subscribe(() => {
      this.resetForm();
      this.removeLock();
    });
  }

  cancelEdit(ref) {
    ref.close();
  }

  confirmDelete(ref, deleteConfirm: TemplateRef<any>) {
    ref.close();
    this.dialogService.open(deleteConfirm);
  }

  cancelDelete(ref) {
    ref.close();
  }

  deleteContact(ref) {
    console.log('Deleting contact', this.contact);
    this.contactService.deleteContact(this.client.id, this.contact.user_id).subscribe(result => {
      this.toastrService.success(
        $localize`:@@contactRemovedSuccess:Contact removed from client`
      );
      this.contactService.reloadContacts();
      ref.close();
    }, error => {
      const translatedMessage = this.getErrorMessage(error.error.type);
      this.toastrService.error(translatedMessage);
      console.log(error);
    });
  }

  submitForm(ref) {

    const phoneNumberArray = this.clientEditForm.get('phoneNumberArray').value;
    const phoneNumbers = [];
    if (phoneNumberArray) {
      phoneNumberArray.forEach(phoneNumber => {
        if (phoneNumber.phone) {
          phoneNumbers.push(phoneNumber.phone);
        }
      });
    }

    const emailAddressesArray = this.clientEditForm.get('emailAddressArray').value;
    const emailAddresses = [];
    if (emailAddressesArray) {
      emailAddressesArray.forEach(emailAddress => {
        if (emailAddress.email) {
          emailAddresses.push(emailAddress.email);
        }
      });
    }


    // field_ref_contact_type
    const updateRelationType = [];
    if (this.clientEditForm.get('contactType').value) {
      updateRelationType.push({type: 'taxonomy_term--relatie_type', id: this.clientEditForm.get('contactType').value});
    }

    const updateLanguages = [];
    if (this.clientEditForm.get('languages').value) {
      updateLanguages.push({type: 'taxonomy_term--spoken_languages', id: this.clientEditForm.get('languages').value});
    }

    const updateDepartment = [];
    if (this.clientEditForm.get('department').value) {
      updateDepartment.push({type: 'taxonomy_term--contact_department', id: this.clientEditForm.get('department').value});
    }

    let contactComponentsNeedReload = false;
    if (this.contact.primary !== this.clientEditForm.get('primaryContact').value) {
      contactComponentsNeedReload = true;
    }

    const updatedContact: ClientContactPatch = {
      firstName: this.clientEditForm.get('firstName').value,
      id: this.contact.id,
      userId: this.contact.user_uuid,
      insertions: this.clientEditForm.get('insertion').value,
      lastName: this.clientEditForm.get('lastName').value,
      phoneNumbers,
      primaryEmail: emailAddresses[0],
      emailAddresses,
      secondaryEmail: this.clientEditForm.get('secondaryEmail').value,
      skypeAccount: this.clientEditForm.get('skypeAccount').value,
      gender: this.clientEditForm.get('gender').value,
      contactFunction: this.clientEditForm.get('contactFunction').value,
      status: this.clientEditForm.get('status').value,
      departments: updateDepartment,
      relationTypes: updateRelationType,
      languages: updateLanguages,
      primary: this.clientEditForm.get('primaryContact').value || false
    };

    const patchSubscription = this.contactService.updateContact(updatedContact).subscribe(
      result => {

        if (result.error) {
          switch (result.error.type) {
            case 'mailTaken':
              this.toastrService.error(
                $localize`:@@mailTakenFailure:Email is already taken.`
              );
              break;
            default:
              this.toastrService.error(
                $localize`:@@contactUpdateFailure:Contact could not be updated.`
              );
          }
        } else {

          this.toastrService.success(
            $localize`:@@contactUpdateSuccess:Contact has been updated.`
          );

          // When the primary contact changed, different components need to reload data.
          if (contactComponentsNeedReload) {
            ref.close();
            this.contactService.reloadContacts(); // Fire the event to subscribers
          } else {
            // After the update we need to map the new values to the existing object.
            this.contact.lastName = this.clientEditForm.get('lastName').value;
            this.contact.firstName = this.clientEditForm.get('firstName').value;
            this.contact.insertions = this.clientEditForm.get('insertion').value;
            this.contact.phoneNumber = phoneNumbers;
            this.contact.primaryEmailAddress = emailAddresses;
            this.contact.secondaryEmailAddress = this.clientEditForm.get('secondaryEmail').value;
            this.contact.skypeAccount = this.clientEditForm.get('skypeAccount').value;
            this.contact.gender = this.clientEditForm.get('gender').value;
            this.contact.contactFunction = this.clientEditForm.get('contactFunction').value;
            this.contact.status = this.clientEditForm.get('status').value;

            // Mapping new terms to an existing contact.
            // First get the new value.
            const newDepartment = this.clientEditForm.get('department').value;
            // Then clear the array.
            this.contact.department = [];
            // Finally loop through the existing terms to find the corresponding term for the new selection.
            // Note: this does not currently work for multi-value fields.
            if (this.departmentTerms) {
              this.departmentTerms.forEach(department => {
                if (department.id === newDepartment) {
                  // Push the match to the departments array.
                  this.contact.department.push(department);
                }
              });
            }

            // Mapping new contact type terms to an existing contact. See above for detailed comments.
            const newContactType = this.clientEditForm.get('contactType').value;
            this.contact.contactType = [];
            if (this.contactTypeTerms) {
              this.contactTypeTerms.forEach(contactType => {
                if (contactType.id === newContactType) {
                  this.contact.contactType.push(contactType);
                }
              });
            }

            // Mapping new language terms to an existing contact. See above for detailed comments.
            const newLanguages = this.clientEditForm.get('languages').value;
            this.contact.languages = [];
            if (this.languageTerms) {
              this.languageTerms.forEach(language => {
                if (newLanguages === language.id) {
                  this.contact.languages.push(language);
                }
              });
            }

            this.buildDisplayName();
            this.getInitials();
            this.originalFormState = this.clientEditForm.value;
            ref.close();
          }
        }

      }, error => {
        this.toastrService.error(
          $localize`:@@contactUpdateFailure:Contact could not be updated.`
        );
      }
    );
    this.subscription.add(patchSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.lockingSubscription.unsubscribe();
  }

  private buildDisplayName(): void {
    if (this.contact.firstName == null && this.contact.lastName == null) {
      this.contact.displayName = this.contact.username;
    } else {
      this.contact.displayName =
        // tslint:disable-next-line:max-line-length
        (this.contact.firstName ? ' ' + this.contact.firstName + ' ' : ' ') + (this.contact.insertions ? ' ' + this.contact.insertions + ' ' : ' ') + this.contact.lastName;
    }
  }

  private resetForm() {
    // Remove the phone number and email controls, we'll add these again manually.
    this.clientEditForm.removeControl('phoneNumberArray');
    this.clientEditForm.removeControl('emailAddressArray');

    // Loop through the phoneNumbersArray to add them to the form again.
    let i = 0;
    this.originalFormState.phoneNumberArray.forEach(phoneNumber => {
      if (i === 0) {
        this.clientEditForm.addControl('phoneNumberArray', new FormArray([this.createPhoneNumber(phoneNumber.phone)]));
      } else {
        this.addAdditionalPhoneNumber(phoneNumber.phone);
      }
      i++;
    });

    i = 0;
    this.originalFormState.emailAddressArray.forEach(emailAddress => {
      if (i === 0) {
        this.clientEditForm.addControl('emailAddressArray', new FormArray([this.createEmailAddress(emailAddress.email)]));
      } else {
        this.addAdditionalEmailAddress(emailAddress.email);
      }
      i++;
    });

    this.clientEditForm.setValue(this.originalFormState);
  }

  addLock() {
    this.lockingService.addLock(this.contact.id, this.componentName);
  }

  removeLock() {
    this.lockingService.removeLock(this.contact.id, this.componentName);
  }

  private getErrorMessage(type) {
    switch (type) {
      case 'no_userId':
        return $localize`:@@removeContactErrorNoUserId:No userId given`;

      case 'missing_group':
        return $localize`:@@removeContactErrorMissingGroup:No valid group given`;

      default:
        return $localize`:@@unknownError:Unknown error`;
    }
  }

}
