import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../auth.service';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable()
export class PatchHeaderInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Only apply if the method is one of the following.
    if (req.method === 'PATCH' || req.method === 'POST' || req.method === 'DELETE') {
      let patchHeaders;

      if (this.authService.isAuthenticated$) {
        const token = this.authService.accessToken;
        if (req.url.startsWith(environment.drupalUrl + '/jsonapi/')) {
          patchHeaders = new HttpHeaders({
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            Authorization: 'Bearer ' + token
          });
        } else {
          patchHeaders = new HttpHeaders({
            Authorization: 'Bearer ' + token
          });
        }
      } else {
        if (req.url.startsWith(environment.drupalUrl + '/jsonapi/')) {
          patchHeaders = new HttpHeaders({
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
          });
        }
      }

      // Clone the request to add the new header
      req = req.clone({
        headers: patchHeaders
      });
    }

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(req);
  }
}
