import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {RestApiService} from '../../../services/rest/rest-api.service';
import {Observable, Subscription} from 'rxjs';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditPageComponent implements OnInit, OnDestroy {

  uuid: number;
  node;
  pageData = {title: null, body: null};
  @ViewChild('form') form: any;

  public Editor = ClassicEditor;

  private subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private toastrService: SolarifToastrService,
    private router: Router,
    private apiService: RestApiService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    // Get the node from the route snapshot data.
    this.node = this.route.snapshot.data.node;

    // Assign the data to the pageData object so that the default values get set in the form automatically.
    this.pageData.title = this.node.data.attributes.title;
    this.pageData.body = this.node.data.attributes.body.value;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  saveEditPage() {
    const node = {
      data: {
        type: 'node--page',
        id: this.node.data.id,
        attributes: {
          title: this.form.value.title,
          body: {
            value: this.form.value.body,
            format: 'full_html'
          },
        }
      },
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
      })
    };

    this.http.patch(environment.drupalUrl + '/jsonapi/node/page/' + this.node.data.id, node, httpOptions).subscribe(result => {
      this.toastrService.success(
        $localize`:@@nodeUpdateSuccess:Node has been updated.`
      );
      this.router.navigateByUrl('/admin/content/list');
    }, error => {
      this.toastrService.error(
        $localize`:@@nodeUpdateFailure:Node could not be updated.`
      );
    });
  }

}
