import {Component, Input, OnInit} from '@angular/core';
import {Calculation} from '../../models/calculation';

@Component({
  selector: 'app-calculation-data',
  templateUrl: './calculation-data.component.html',
  styleUrls: ['./calculation-data.component.scss']
})
export class CalculationDataComponent implements OnInit {

  @Input() calculation: Calculation;

  public keepOriginalOrder = (a, b) => a.key;

  constructor() { }

  ngOnInit() {
    console.log('CalculationDataComponent ngoninit ', this.calculation);
  }

}
