<div class="d-flex flex-column">
  <div class="main d-flex justify-content-between align-items-center">
    <div class="user-container">
      <div class="user-picture initials d-flex d-lg-none">
      </div>

      <div class="info-container">
        {{text}}
      </div>
    </div>
  </div>
</div>
