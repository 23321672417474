import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../../../../services/auth.service';
import {TaxonomyService} from '../../../../services/taxonomy/taxonomy.service';
import {ActivityService} from '../../../../services/activity/activity.service';
import {SolarifToastrService} from '../../../../services/toastr/solarif-toastr.service';
import {ClientActivityDialogComponent} from '../client-activity-dialog/client-activity-dialog.component';
import {ProductService} from '../../../../services/product/product.service';
import {Product} from '../../../../models/product';
import {environment} from '../../../../../environments/environment';
import {Calculation} from '../../../../models/calculation';
import {Activity} from '../../../../models/activity/activity';
import {Client} from '../../../../models/client/client';
import {Address} from '../../../../models/address';
import {ActivityTaxonomyTerm} from '../../../../models/terms/taxonomyTerm';
import {Reply} from '../../../../models/activity/reply';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-client-activity',
  templateUrl: './client-activity.component.html',
  styleUrls: ['./client-activity.component.scss']
})
export class ClientActivityComponent implements OnInit {

  @ViewChild('dialog', { static: true }) dialogRef;

  @Input() open?: boolean;
  @Input() activity: Activity;
  @Input() client: Client;
  @Input() activityTypeTerms?: Array<ActivityTaxonomyTerm>;
  @Input() allowedActivityTypeTerms?: Array<ActivityTaxonomyTerm>;
  @Input() allowedBusinessUnitTerms: Array<any>;
  businessUnits: Array<any>;


  Editor = ClassicEditor;
  products: Array<Product>;
  show = {
    calculationData: false,
    generateAdditionalInfoLink: false,
    getQuotation: false,
    productList: false
  };
  activityReplyForm: FormGroup;
  productSelectForm: FormGroup;
  quotationForm: FormGroup;
  generatedFormLinks = {
    nl: null,
    en: null
  };
  calculationActivityId: string = null;
  selectedProductForQuotation: string = null;
  mappedAddresses: {regular: Array<Address>, risk: Array<Address>} = {
    regular: [],
    risk: []
  };

  private identityClaims;

  constructor(private dialogService: NbDialogService,
              private activityService: ActivityService,
              private taxonomyService: TaxonomyService,
              private productService: ProductService,
              private authService: AuthService,
              private toastrService: SolarifToastrService) {
    this.identityClaims = this.authService.identityClaims;
  }

  ngOnInit() {
    this.buildActivityReplyForm();
    if (this.open) {
      this.open = false;
      this.openReplyDialog(this.dialogRef);
    }
  }

  openEditDialog(dialog) {
    this.dialogService.open(ClientActivityDialogComponent, {
      context: {
        activity: this.activity,
        client: this.client,
        businessUnitTerms: this.allowedBusinessUnitTerms,
        allowedActivityTypeTerms: this.allowedActivityTypeTerms,
        actionType: 'edit'
      },
    });
  }

  openReplyDialog(dialog) {
    this.dialogService.open(dialog);

    switch (this.activity.type_uuid) {
      case environment.taxonomyTermIds.activityType.policyApplication:
        this.getProducts();
        this.buildSelectProductForm();
        this.show.productList = true;
        break;
      case environment.taxonomyTermIds.activityType.receivedCalculation:
        this.activity.calculation = this.activity.referenced_data_object['data'] as Calculation;
        this.show.generateAdditionalInfoLink = true;
        this.show.calculationData = true;
        break;
      case environment.taxonomyTermIds.activityType.acceptanceInformation:
        this.buildQuotationFrom();
        // @ts-ignore
        this.calculationActivityId = this.activity.form_submission_data.submission.values.activity_id;
        // @ts-ignore
        this.selectedProductForQuotation = this.activity.form_submission_data.submission.values.product_id;
        this.mapAddresses();
        this.show.getQuotation = true;
        break;
    }
  }

  submitReplyForm(ref) {
    const reply: Reply = {
      previousActivityId: this.activity.id,
      datetime: UtilitiesService.ISODateString(new Date()),
      message: this.activityReplyForm.get('message').value,
      title: 'Reply: ' + this.activity.title + ' by ' + this.identityClaims.name,
      type: this.activityReplyForm.get('activityType').value,
    };

    this.activityService.postReply(reply, this.client.id).subscribe(result => {
      this.toastrService.success($localize`:@@activityReplyAddSuccess:Activity has been saved.`);
      this.activityService.reloadActivities();
      ref.close();

    }, error => {
      this.toastrService.error($localize`:@@activityReplyAddFailure:Activity could not be saved.`);
    });
  }

  submitProductSelectForm(ref) {
    const selectedProducts = this.productSelectForm.get('products').value;

    // TODO: Select an address or choose primary?
    const selectedAddress = this.client.addresses[0].uuid;

    if (this.client.addresses.length === 0 || selectedAddress === null) {
      this.toastrService.error(
        $localize`:@@selectProductNoAddressAvailable:Address needed to make a calculation. Add an address to this client first.`
      );
    } else {
      console.log('This idenntity uuid', this.identityClaims.uuid);
      this.productService.selectProducts(this.activity.id, selectedProducts, selectedAddress, this.identityClaims.uuid)
      .subscribe(response => {
        if (response.status === 200) {
          this.toastrService.success($localize`:@@productSelectedSucces:Products have been successfully selected.`);
          this.activityService.reloadActivities();
          ref.close();
        } else {
          this.toastrService.error($localize`:@@productSelectedFailed:Unable to select a product.`);
        }
      });
    }

  }

  submitQuotationForm(ref) {
    const selectedBusinessAddress = this.quotationForm.get('business_address').value;
    const selectedRiskAddress = this.quotationForm.get('risk_address').value;

    this.productService.makeQuotation(this.activity.id, selectedBusinessAddress, selectedRiskAddress, this.calculationActivityId, this.selectedProductForQuotation, this.identityClaims.uuid)
    .subscribe(response => {
      this.toastrService.success($localize`:@@quotationSendSuccess:Quotation has been send`);
      this.activityService.reloadActivities();
      ref.close();
    }, error => {
      this.toastrService.error($localize`:@@quotationSendError:Unable to send quotation`);
    });
  }

  generateFormLink() {
    // Base 64 encode all url values
    const name = encodeURIComponent(btoa(this.client.title));
    const email = encodeURIComponent(btoa(this.client.contactInfo.email));
    const activityId = encodeURIComponent(btoa(this.activity.id));
    const product = encodeURIComponent(btoa(this.activity.calculation.product_id));

    // this.generatedFormLinks.nl = `${environment.websiteUrl.nl}/acceptatie_info?name=${name}&email=${email}`;
    // this.generatedFormLinks.en = `${environment.websiteUrl.en}/acceptation_info?name=${name}&email=${email}`;

    const queryParameters = `n=${name}&e=${email}&p=${product}&a=${activityId}`;

    this.generatedFormLinks.nl = `${environment.drupalUrl}/nl/webform/acceptatie_informatie?${queryParameters}`;
    this.generatedFormLinks.en = `${environment.drupalUrl}/en/webform/acceptatie_informatie?${queryParameters}`;
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastrService.basic('', $localize`:@@copied:Copied`);
  }

  showActions() {
    return Object.keys(this.show).some(key => this.show[key]);
  }

  isQuotationValid() {
    return (
      this.client.addresses.length > 0
      && this.client.contacts.primary.length > 0
      && this.client.contacts.primary[0].primaryEmailAddress.length > 0
      && this.selectedProductForQuotation != null
      && this.calculationActivityId != null
    );
  }

  print() {
    const printContent = document.getElementById(`activity-${this.activity.id}__print-version`);
    let popupWin;
    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
          <html>
            <head>
              <link rel="stylesheet" type="text/css" media="screen,print" href="/assets/style/printer-style.css">
            </head>
            <body onload="window.print();window.close();">${printContent.innerHTML}</body>
          </html>
    `);
    popupWin.document.close();

  }

  private buildActivityReplyForm() {
    this.activityReplyForm = new FormGroup({
      message: new FormControl('', Validators.required),
      activityType: new FormControl('', Validators.required)
    });
  }

  private buildSelectProductForm() {
    this.productSelectForm = new FormGroup({
      products: new FormControl([], Validators.required)
    });
  }

  private buildQuotationFrom() {
    this.quotationForm = new FormGroup({
      business_address: new FormControl('', Validators.required),
      risk_address: new FormControl('', Validators.required)
    });
  }

  private getProducts() {
    // TODO: Don't save the products in the activity, but get them from the service
    this.productService.products().subscribe(products => {
      this.products = products;
    });
  }

  private mapAddresses() {
    this.mappedAddresses.regular = this.client.addresses.filter(address => {
      return address.type.uuid !== environment.taxonomyTermIds.addressType.riskAddress;
    });
    this.mappedAddresses.risk = this.client.addresses.filter(address => {
      return address.type.uuid === environment.taxonomyTermIds.addressType.riskAddress;
    });
    console.log('Mapped Addresses', this.mappedAddresses);
  }

}
