import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AccountManager} from '../../../models/AccountManager';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Client} from '../../../models/client/client';
import {SolarifToastrService} from '../../../services/toastr/solarif-toastr.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {AccountManagerService} from '../../../services/account-manager/account-manager.service';
import {ClientService} from 'src/app/services/client/client.service';
import {TaxonomyService} from 'src/app/services/taxonomy/taxonomy.service';

@Component({
  selector: 'app-client-account-manager',
  templateUrl: './client-account-manager.component.html',
  styleUrls: ['./client-account-manager.component.scss']
})
export class ClientAccountManagerComponent implements OnInit {

  @Input() client: Client;

  selectedAccountManager: AccountManager;
  accountManagerForm: FormGroup;
  allAccountManagers: Array<AccountManager> = [];


  constructor(private dialogService: NbDialogService,
              private http: HttpClient,
              private toastrService: SolarifToastrService,
              private clientService: ClientService,
              private taxonomyService: TaxonomyService,
              private accountManagerService: AccountManagerService
  ) {}

  ngOnInit() {
    this.resetForm();
    this.getAccountManagers();
  }

  private getAccountManagers() {
    this.accountManagerService.accountManagers().subscribe(accountManagers => {
      this.allAccountManagers = accountManagers;
    });
  }

  get businessUnitTerms() {
    return this.client.businessUnits;
  }

  openEditDialog(dialog: TemplateRef<any>, accountManager?: AccountManager) {
    this.selectedAccountManager = accountManager;
    this.resetForm();
    this.dialogService.open(dialog).onClose.subscribe(() => {
      this.resetForm();
    });
  }

  cancelEdit(ref: NbDialogRef<any>) {
    this.resetForm();
    ref.close();
  }

  private resetForm() {
    const defaultAccountManager = this.selectedAccountManager ? this.selectedAccountManager.uuid : '';
    const defaultBusinessUnit = this.selectedAccountManager ?
      this.businessUnitTerms.find(x => x.name === this.selectedAccountManager.businessUnit).id : '';

    this.accountManagerForm = new FormGroup({
      accountManager: new FormControl(defaultAccountManager, [Validators.required]),
      businessUnit: new FormControl(defaultBusinessUnit, [Validators.required]),
    });
  }

  onSubmit(ref) {
    return this.selectedAccountManager ? this.updateAccountManager(ref) : this.addAccountManager(ref);
  }

  private addAccountManager(ref) {

    const accountManagerUuid = this.accountManagerForm.get('accountManager').value;
    const businessUnitUuid = this.accountManagerForm.get('businessUnit').value;
    const accountManager = this.allAccountManagers.find(x => x.uuid === accountManagerUuid);
    const businessUnit = this.businessUnitTerms.find(x => x.id === businessUnitUuid);

    this.clientService.addAndAssignAccountManager(this.client, accountManagerUuid, businessUnitUuid).subscribe((result: any) => {
      const relationUuid =  result.data.relationships.field_ref_accountmanager.data[this.client.accountManagers.length].id;
      this.client.accountManagers.push({ ...accountManager, businessUnit: businessUnit.name, relationUuid });
      this.toastrService.success(
        $localize`:@@accountManagerAddSuccess:Account manager has been added.`
      );
      ref.close();
    }, error => {
      this.toastrService.error(
        $localize`:@@accountManagerAddFailure:Account manager could not be added.`
      );
    });
  }


  private updateAccountManager(ref) {

    const accountManagerUuid = this.accountManagerForm.get('accountManager').value;
    const businessUnitUuid = this.accountManagerForm.get('businessUnit').value;
    const accountManager = this.allAccountManagers.find(x => x.uuid === accountManagerUuid);
    const relationUuid = this.selectedAccountManager.relationUuid;
    const businessUnit = this.businessUnitTerms.find(x => x.id === businessUnitUuid);

    this.clientService.updateAssignedAccountManager(relationUuid, accountManagerUuid, businessUnitUuid).subscribe(result => {
      Object.assign(this.selectedAccountManager, {
        ...accountManager,
        relationUuid,
        businessUnit: businessUnit.name
      });
      this.toastrService.success(
        $localize`:@@accountManagerUpdateSuccess:Account manager has been updated.`
      );
      ref.close();
    }, error => {
      this.toastrService.error(
        $localize`:@@accountManagerUpdateFailure:Account manager could not be updated.`
      );
    });
  }

  confirmDelete(ref) {
    const relationUuid = this.selectedAccountManager.relationUuid;
    this.clientService.unassignAccountManager(this.client, relationUuid).subscribe(result => {
      const index = this.client.accountManagers.findIndex(x => x.relationUuid === relationUuid);
      this.client.accountManagers.splice(index, 1);

      this.toastrService.success(
        $localize`:@@accountManagerDeleteSuccess:Account manager has been removed.`
      );
      ref.close();
    }, error => {
      this.toastrService.error(
        $localize`:@@accountManagerDeleteFailure:Account manager could not be removed.`
      );
    });

  }

}
