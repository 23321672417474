import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Client} from 'src/app/models/client/client';
import {ClientHierarchyItem} from 'src/app/models/client/client-hierarchy';

interface ChartDrawingContext {
  x: number;
  y: number;
}

@Component({
  selector: 'app-client-organization-chart',
  templateUrl: './client-organization-chart.component.html',
  styleUrls: ['./client-organization-chart.component.scss']
})
export class ClientOrganizationChartComponent implements OnInit {

  // Visual configuration

  readonly SHADOW_FILL          = '#999';
  readonly SHADOW_OFFSET        = 2;
  readonly SHADOW_BLUR          = 4;
  readonly BACKGROUND_FILL      = '#fff';
  readonly NODE_FILL            = '#f1f1f1';
  readonly NODE_STROKE          = '#b9b9b9';
  readonly SELECTED_NODE_FILL   = '#f1f1ff';
  readonly SELECTED_NODE_STROKE = '#3366ff';
  readonly LINE_STROKE          = '#a8a8a8';
  readonly TEXT_FILL            = '#050535';
  readonly SELECTED_TEXT_FILL   = '#3366ff';
  readonly FONT                 = '14px sans-serif';
  readonly SELECTED_FONT        = 'bold 14px sans-serif';

  readonly NODE_WIDTH           = 80;
  readonly NODE_HEIGHT          = 30;
  readonly PADDING              = 25;

  @ViewChild('chart', { static: true }) chart: ElementRef<HTMLCanvasElement>;

  @Input() client: Client;

  canvasWidth: number;
  canvasHeight: number;
  ctx: CanvasRenderingContext2D;

  organization: ClientHierarchyItem;

  constructor() { }

  ngOnInit() {
    this.organization = this.client.hierarchy.fullHierarchy;
    this.setupCanvas();
    window.requestAnimationFrame(this.draw.bind(this));
  }

  private setupCanvas() {
    this.ctx = this.chart.nativeElement.getContext('2d');
    this.setCanvasTextStyles();
    this.canvasWidth = this.measureWidth(this.organization) + this.PADDING * 2;
    this.canvasHeight = this.measureHeight(this.organization) + this.PADDING * 2;
  }

  private setCanvasTextStyles() {
    this.ctx.font = this.FONT;
    this.ctx.textBaseline = 'middle';
    this.ctx.textAlign = 'center';
  }

  private draw() {
    this.setCanvasTextStyles();
    this.ctx.clearRect(0, 0, this.chart.nativeElement.width, this.chart.nativeElement.height);
    this.ctx.strokeStyle = '#000';
    this.ctx.fillStyle = this.BACKGROUND_FILL;
    this.ctx.fillRect(0, 0,
      this.measureWidth(this.organization) + this.PADDING * 2,
      this.measureHeight(this.organization) + this.PADDING * 2);

    this.drawNode(this.organization, { x: this.PADDING, y: this.PADDING });
  }

  private drawNode(node: ClientHierarchyItem, context: ChartDrawingContext) {
    const isRoot = node === this.organization;
    const isSelectedNode = node.id === this.client.id;

    const width = this.measureWidth(node);
    const textWidth = this.measureTextWidth(node);
    let x = context.x + width / 2;
    if (node.children.length === 0) {
      if (!isRoot) {
        this.drawLine(x, context.y - this.PADDING / 2, x, context.y);
      }
      this.drawRect(x - textWidth / 2, context.y, textWidth, this.NODE_HEIGHT, node.title, isSelectedNode);
      return;
    }

    const pos = this.measurePos(node, context);
    x = pos.self;

    // Draw node
    if (!isRoot) {
      this.drawLine(x, context.y - this.PADDING / 2, x, context.y);
    }
    this.drawRect(x - textWidth / 2, context.y, textWidth, this.NODE_HEIGHT, node.title, isSelectedNode);

    // Draw connecting line
    this.drawLine(
      pos.first, context.y + this.NODE_HEIGHT + this.PADDING / 2,
      pos.last, context.y + this.NODE_HEIGHT + this.PADDING / 2
    );
    this.drawLine(x, context.y + this.NODE_HEIGHT, x, context.y + this.NODE_HEIGHT + this.PADDING / 2);

    // Draw children
    node.children.reduce((childNodeX, childNode) => {
      const childNodeWidth = this.measureWidth(childNode);
      this.drawNode(childNode, { x: context.x + childNodeX + pos.offset, y: context.y + this.NODE_HEIGHT + this.PADDING });
      return childNodeX + childNodeWidth + this.PADDING;
    }, 0.0);
  }

  private measureWidth(node: ClientHierarchyItem) {
    if (node.children.length === 0) {
      return this.measureTextWidth(node);
    }
    const childNodesSummedWidth = node.children.reduce((acc, childNode) => acc + this.measureWidth(childNode), 0.0);
    const padding = (node.children.length - 1) * this.PADDING;
    return Math.max(this.measureTextWidth(node), childNodesSummedWidth + padding);
  }

  private measureTextWidth(node: ClientHierarchyItem) {
    const isSelectedNode = node.id === this.client.id;
    this.ctx.font = isSelectedNode ? this.SELECTED_FONT : this.FONT;
    return this.ctx.measureText(node.title).width + this.PADDING;
  }

  private measurePos(node: ClientHierarchyItem, context: ChartDrawingContext) {
    if (node.children.length === 0) {
      return { first: 0, last: 0, self: context.x + this.measureTextWidth(node) / 2, narrowingOffset: 0 };
    }

    const width = this.measureWidth(node);

    const childrenWidth = node.children.reduce((acc, childNode) => acc + this.measureWidth(childNode), 0.0) +
      (node.children.length - 1) * this.PADDING;
    const lastChildWidth = this.measureWidth(node.children[node.children.length - 1]);
    const offset = childrenWidth - lastChildWidth;
    const narrowingOffset = (width - childrenWidth) / 2;

    const first = this.measurePos(node.children[0], { ...context, x: context.x + narrowingOffset }).self;
    const last = this.measurePos(node.children[node.children.length - 1], { x: context.x + offset + narrowingOffset, y: context.y }).self;
    const self = (first + last) / 2;
    return { first, last, self, offset: narrowingOffset };
  }

  private measureHeight(node: ClientHierarchyItem) {
    if (node.children.length === 0) {
      return this.NODE_HEIGHT;
    }

    return this.NODE_HEIGHT + node.children.reduce((max, childNode) => {
      return Math.max(max, this.PADDING + this.measureHeight(childNode));
    }, 0.0);
  }

  private drawLine(x1, y1, x2, y2) {
    this.ctx.strokeStyle = this.LINE_STROKE;
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x2, y2);
    this.ctx.stroke();
  }

  private drawRect(x, y, w, h, text, isSelectedNode = false) {
    this.ctx.fillStyle = isSelectedNode ? this.SELECTED_NODE_FILL : this.NODE_FILL;
    this.ctx.strokeStyle = isSelectedNode ? this.SELECTED_NODE_STROKE : this.NODE_STROKE;
    this.ctx.shadowBlur = this.SHADOW_BLUR;
    this.ctx.shadowOffsetX = this.SHADOW_OFFSET;
    this.ctx.shadowOffsetY = this.SHADOW_OFFSET;
    this.ctx.shadowColor = this.SHADOW_FILL;
    this.ctx.fillRect(x, y, w, h);
    this.ctx.shadowBlur = 0;
    this.ctx.shadowOffsetX = 0;
    this.ctx.shadowOffsetY = 0;
    this.ctx.strokeRect(x, y, w, h);

    this.ctx.font = isSelectedNode ? this.SELECTED_FONT : this.FONT;
    this.ctx.fillStyle = isSelectedNode ? this.SELECTED_TEXT_FILL : this.TEXT_FILL;
    this.ctx.fillText(text, x + w / 2, y + h / 2);
  }
}
