<div (click)="toggleMoreInfo()" class="main d-flex justify-content-between align-items-center" *ngIf="contact">
  <div class="user-container">
    <div class="user-picture initials d-flex d-lg-none">
      <ng-container *ngIf="showInitials && contact">
        {{ getInitials() }}
      </ng-container>
    </div>

    <div class="info-container" *ngIf="contact">
      <div *ngIf="contact.displayName" class="user-name">
        {{ contact.displayName }}
        <div *ngIf="contact.contactType && contact.contactType.length > 0" class="contact__type_icons">
          <div *ngFor="let type of contact.contactType">
            <nb-icon *ngIf="type.icon" [icon]="type.icon" [title]="type.name"></nb-icon>
          </div>
        </div>
      </div>
      <div class="contact-info d-flex flex-row flex-lg-column" *ngIf="contact.contactFunction || contact.primaryEmailAddress.length > 0 || contact.phoneNumber.length > 0">
        <div *ngIf="contact.contactFunction">
          <fa-icon [icon]="icons.contactFunction"></fa-icon>
          {{ contact.contactFunction }}
        </div>
        <div *ngIf="contact.primaryEmailAddress.length > 0">
          <fa-icon [icon]="icons.primaryEmail"></fa-icon>
          <a href="mailto:{{ contact.primaryEmailAddress[0] }}">{{ contact.primaryEmailAddress[0] }}</a>
        </div>
        <div *ngIf="contact.phoneNumber.length > 0">
          <fa-icon [icon]="icons.phone"></fa-icon>
          {{ contact.phoneNumber[0] }}
        </div>
      </div>
    </div>
  </div>

  <div class="toggleIcon">
    <nb-icon *ngIf="!showMoreInfo else moreInfoOpen" icon="chevron-left-outline"></nb-icon>
    <ng-template #moreInfoOpen><nb-icon icon="chevron-down-outline"></nb-icon></ng-template>
  </div>
</div>

<div class="more-info" *ngIf="showMoreInfo">
  <div class="actions">
    <button class="btn btn-light btn-sm" [disabled]="currentLock === 1" (click)="openEditDialog(dialog)"><nb-icon icon="edit-outline"></nb-icon></button>
<!--    <nb-action icon="trash-2-outline" (click)="openDeleteDialog(dialog_delete)"></nb-action>-->
  </div>
  <div class="icon-list">
<!--    <div *ngIf="contact.phoneNumber.length > 0">-->

      <!--      <div class="item">-->
      <!--        <fa-icon [icon]="icons.phone"></fa-icon>-->
      <!--        -->
      <!--      </div>-->

      <span class="phone-number item" *ngFor="let phoneNumber of contact.phoneNumber; let last = last;">
        <fa-icon [icon]="icons.phone"></fa-icon>
        <a class="item-text" href="tel:{{phoneNumber}}">{{ phoneNumber }}</a>
        <!--        <ng-container *ngIf="!last">, </ng-container>-->
      </span>
<!--    </div>-->
<!--    <div *ngIf="contact.primaryEmailAddress.length > 0">-->
      <!--      <div i18n="clientContactEmailLabel"></div>-->
      <span class="email-address item"
                 *ngFor="let emailAddress of contact.primaryEmailAddress; let last = last;">
        <fa-icon [icon]="icons.primaryEmail"></fa-icon>
        <a href="mailto:{{emailAddress}}">
        <div class="item-text">{{ emailAddress }}</div>
      </a>
        <!--        <ng-container *ngIf="!last">, </ng-container>-->
        </span>
<!--    </div>-->
    <div class="item" *ngIf="contact.contactFunction">
      <fa-icon [icon]="icons.contactFunction"></fa-icon>
      <div class="item-text">{{ contact.contactFunction }}</div>
    </div>
    <div class="item" *ngIf="contact.gender">
      <fa-icon [icon]="icons.gender"></fa-icon>
      <div class="item-text">
        <ng-container *ngIf="contact.gender == 'm'; else femaleGender" i18n="@@genderMale">Male</ng-container>
        <ng-template #femaleGender i18n="@@genderFemale">Female</ng-template>
      </div>
    </div>
    <div class="item" *ngIf="contact.mobilePhoneNumber">
      <fa-icon [icon]="icons.mobilePhone"></fa-icon>>
      <div class="item-text">{{ contact.mobilePhoneNumber }}</div>
    </div>
    <div class="item" *ngIf="contact.secondaryEmailAddress">
      <fa-icon [icon]="icons.secondaryEmail"></fa-icon>
      <div class="item-text">{{ contact.secondaryEmailAddress }}</div>
    </div>
    <div class="item" *ngIf="contact.department && contact.department.length > 0">
      <fa-icon [icon]="icons.department"></fa-icon>
      <div class="item-text" *ngFor="let department of contact.department">{{ department.name }}</div>
    </div>
    <div class="item" *ngIf="contact.contactType && contact.contactType.length > 0">
      <fa-icon [icon]="icons.contactType"></fa-icon>
      <div class="item-text" *ngFor="let contactType of contact.contactType">{{ contactType.name }}</div>
    </div>
    <div class="item" *ngIf="contact.languages && contact.languages.length > 0">
      <span class="languages item" *ngFor="let language of contact.languages; let last = last;">
        <fa-icon [icon]="icons.languages"></fa-icon>
        <span class="language item-text">{{language.name}}</span>
<!--          <ng-container *ngIf="!last">, </ng-container>-->
      </span>
    </div>
    <div class="item" *ngIf="contact.tags.length > 0">
      <fa-icon [icon]="icons.tags"></fa-icon>
      <div class="item-text">{{ formatTags() }}</div>
    </div>
    <div class="item" *ngIf="contact.skypeAccount">
      <fa-icon [icon]="icons.skype"></fa-icon>
      <div class="item-text">{{ contact.skypeAccount }}</div>
    </div>
    <div class="item" *ngIf="contact.status">
      <fa-icon [icon]="icons.status"></fa-icon>
      <div class="item-text">{{ contact.status }}</div>
    </div>
  </div>
</div>

<ng-template #dialog let-ref="dialogRef">
  <nb-card class="edit-dialog">
    <nb-card-header class=" d-flex justify-content-between align-items-baseline">
      <span i18n="@@editContactHeader">Edit contact</span>
      <button class="btn btn-light btn-sm" (click)="confirmDelete(ref, deleteConfirm)">
        <nb-icon class="text-danger" icon="trash-2-outline"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="clientEditForm" class="dialog-form edit-contact-form">
        <fieldset class="user-data">
          <legend i18n="@@personalInfo">Personal info</legend>
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-item">
                <label class="label" for="firstName" i18n="@@firstName">First name</label>
                <input nbInput fullWidth id="firstName" placeholder="First Name" i18n-placeholder="@@firstName"
                       [status]="(clientEditForm.controls.firstName.invalid) ? 'danger' : 'basic'"
                       formControlName="firstName">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-item">
                <label class="label" for="insertion" i18n="@@insertion">Insertion</label>
                <input nbInput fullWidth id="insertion" placeholder="Insertion" i18n-placeholder="@@insertion"
                       formControlName="insertion">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-item">
                <label class="label" for="lastName" i18n="@@lastName">Last name</label>
                <input nbInput fullWidth id="lastName" placeholder="Last Name" i18n-placeholder="@@lastName"
                       [status]="(clientEditForm.controls.lastName.invalid) ? 'danger' : 'basic'"
                       formControlName="lastName">
              </div>
            </div>
            <div class="col-12">
              <div class="form-item first">
                <label class="label" for="gender" i18n="@@contactGenderLabel">Gender</label>
                <nb-radio-group formControlName="gender" id="gender"
                  [status]="(clientEditForm.controls.gender.invalid) ? 'danger' : 'basic'"
                >
                  <nb-radio value="m" i18n="@@genderMale">Male</nb-radio>
                  <nb-radio value="v" i18n="@@genderFemale">Female</nb-radio>
                </nb-radio-group>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <!--            <div class="multi form-item">-->
              <!--              <label class="label" for="emailArrayInput" i18n="@@emailAddressesLabel">Email addresses</label>-->
              <!--              <div formArrayName="emailAddressArray"-->
              <!--                   *ngFor="let item of emailAddressFormData.controls; let i = index;">-->
              <!--                <div [formGroupName]="i">-->
              <!--                  <input nbInput fullWidth type="text" id="emailArrayInput" placeholder="Email address"-->
              <!--                         formControlName="email" i18n="@@emailAddressesPlaceholder">-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <button class="add-more btn d-block m-auto" (click)="addAdditionalEmailAddress()">-->
              <!--                <nb-icon icon="plus-outline"></nb-icon>-->
              <!--              </button>-->
              <!--            </div>-->
              <div class="form-item">
                <label class="label" for="emailArrayInput" i18n="@@emailAddressLabel">Email address</label>
                <div formArrayName="emailAddressArray"
                     *ngFor="let item of emailAddressFormData.controls; let i = index;">
                  <div [formGroupName]="i">
                    <input nbInput fullWidth type="text" id="emailArrayInput" placeholder="Email address"
                           formControlName="email" i18n="@@emailAddressesPlaceholder">
                  </div>
                </div>
                <!--              <button class="add-more btn d-block m-auto" (click)="addAdditionalEmailAddress()">-->
                <!--                <nb-icon icon="plus-outline"></nb-icon>-->
                <!--              </button>-->
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-item spoken-languages last" *ngIf="languageTerms">
                <label for="languages" i18n="@@contactLanguagesLabel" class="label">Spoken languages</label>
                <nb-select fullWidth id="languages" formControlName="languages" placeholder="Select spoken languages"
                           i18n-placeholder="@@contactLanguagesPlaceholder">
                  <nb-option *ngFor="let language of languageTerms" [value]="language.id">{{language.name}}</nb-option>
                </nb-select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-item multi">
                <label class="label" for="phoneNumberArrayInput" i18n="@@phoneNumbersLabel">Phone numbers</label>
                <div formArrayName="phoneNumberArray"
                     *ngFor="let item of phoneNumberFormData.controls; let i = index;">
                  <div [formGroupName]="i">
                    <input nbInput fullWidth type="text" id="phoneNumberArrayInput" i18n-placeholder="@@phoneNumberPlaceholder" placeholder="Phone number"
                           formControlName="phone">
                  </div>
                </div>
                <button class="add-more btn d-block m-auto" (click)="addAdditionalPhoneNumber()">
                  <nb-icon icon="plus-outline"></nb-icon>
                </button>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset class="member-data">
          <legend i18n="@@memberInfo">Member info</legend>
<!--          <div class="row">-->
<!--            <div class="col-12">-->
            <div class="form-item">
              <label class="label" for="secondaryEmail" i18n="@@emailAddressLabel">Email address</label>
              <input nbInput fullWidth type="email" id="secondaryEmail" placeholder="Email address"
                     [status]="(clientEditForm.controls.secondaryEmail.invalid) ? 'danger' : 'basic'"
                     formControlName="secondaryEmail" i18n="@@emailAddressesPlaceholder">
<!--              <div *ngIf="clientEditForm.controls.secondaryEmail.invalid && (clientEditForm.controls.secondaryEmail.dirty || clientEditForm.controls.secondaryEmail.touched)"-->
<!--                   class="alert">-->
<!--                Dirty or touched-->
<!--              </div>-->
            </div>

              <div class="form-item">
                <label class="label" for="skype" i18n="@@contactSkypeLabel">Skype account</label>
                <input nbInput fullWidth id="skype" placeholder="Skype account" i18n-placeholder="@@contactSkypeAccount"
                       formControlName="skypeAccount">
              </div>
<!--            </div>-->
<!--            <div class="col-12">-->
              <div class="form-item">
                <label class="label" for="function" i18n="@@contactFunctionLabel">Function</label>
                <input nbInput fullWidth id="function" placeholder="Function" i18n-placeholder="@@contactFunction"
                       formControlName="contactFunction">
              </div>
<!--            </div>-->
<!--            <div class="col-12">-->
              <div class="contact-type form-item">
                <label class="label" for="contactType" i18n="@@contactTypeLabel">Contact type</label>
                <nb-select fullWidth id="contactType" formControlName="contactType" placeholder="Select contact type"
                           i18n-placeholder="@@contactTypePlaceholder">
                  <nb-option *ngFor="let contactType of contactTypeTerms"
                             [value]="contactType.id">{{contactType.name}}</nb-option>
                </nb-select>
              </div>
<!--            </div>-->
<!--            <div class="col-12">-->
              <div class="contact-type form-item">
                <label class="label" for="department" i18n="@@contactDepartmentLabel">Department</label>
                <nb-select fullWidth id="department" formControlName="department" placeholder="Select department"
                           i18n-placeholder="@@contactDepartmentPlaceholder">
                  <nb-option *ngFor="let department of departmentTerms"
                             [value]="department.id">{{department.name}}</nb-option>
                </nb-select>
              </div>
<!--            </div>-->
<!--            <div class="col-12">-->
              <div class="form-item">
                <label class="label" for="status" i18n="@@statusLabel">Status</label>
                <nb-select fullWidth id="status" placeholder="Select status" i18n-placeholder="@@statusPlaceholder" formControlName="status"
                  [status]="(clientEditForm.controls.status.invalid) ? 'danger' : 'basic'"
                >
                  <nb-option value="active" i18n="@@statusActive">Active</nb-option>
                  <nb-option value="inactive" i18n="@@statusInactive">Inactive</nb-option>
                </nb-select>
<!--                <nb-radio-group formControlName="status" id="status">-->
<!--                  <nb-radio value="active" i18n="@@statusActive">Active</nb-radio>-->
<!--                  <nb-radio value="inactive" i18n="@@statusInactive">Inactive</nb-radio>-->
<!--                </nb-radio-group>-->
              </div>
<!--            </div>-->
<!--            <div class="col-12">-->
              <div class="form-item">
                <label for="primary_contact" class="label" i18n="@@primaryContactLabel">Primary contact</label>
                <nb-checkbox formControlName="primaryContact" id="primary_contact" i18n="@@primaryContactText">The primary contact of this client</nb-checkbox>
              </div>
<!--            </div>-->
<!--          </div>-->
        </fieldset>
      </form>
    </nb-card-body>
    <nb-card-footer>
      <div class="text-right form-actions">
        <input type="button" nbButton ghost status="primary" (click)="cancelEdit(ref)" value="Cancel" i18n-value="@@cancel">
        <button nbButton status="primary" type="submit" [disabled]="clientEditForm.invalid" (click)="submitForm(ref)" i18n="@@submit">Submit</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #deleteConfirm let-ref="dialogRef">
  <nb-card>
    <nb-card-header i18n="@@confirmDelete" class="d-flex justify-content-between align-items-baseline">
      Confirm delete
    </nb-card-header>
    <nb-card-body>
      <p i18n="@@confirmDeleteMessageContact">Are you sure you want to delete this contact?</p>
    </nb-card-body>
    <nb-card-footer>
      <div class="form-actions text-right">
        <input type="button" nbButton ghost status="primary" (click)="cancelDelete(ref)" value="Cancel" i18n-value="@@cancel">
        <button nbButton status="danger" type="submit" [disabled]="!contact.id" (click)="deleteContact(ref)" i18n="@@delete">Delete</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
