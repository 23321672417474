<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Delete node</nb-card-header>
    <nb-card-body>Are you sure you want to delete the selected node? This action <em>cannot</em> be undone!</nb-card-body>
    <nb-card-footer>
      <button nbButton (click)="cancelDeleteNode(ref)">Cancel</button>
      <button nbButton status="danger" (click)="deleteNode(ref)">Confirm</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
<h1>All content</h1>
<nb-card>
  <nb-card-body>
    <table datatable class="row-border hover" [dtTrigger]="dtTrigger" [dtOptions]="{order:[2, 'desc']}">
      <thead>
      <tr>
        <th>Title</th>
        <th>Type</th>
        <th>Published on</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let node of nodes">
        <td [innerHTML]="node.title[0]['value']"></td>
        <td *ngIf="node.type" [innerHtml]="node.type[0]['target_id']"></td>
        <td>{{node.created[0]['value']}}</td>
        <td>
          <nb-actions>
            <nb-action [link]="'/admin/content/page/'+ node.uuid[0].value +'/edit'" icon="edit-outline"></nb-action>
            <nb-action icon="trash-2-outline" (click)="confirmDeleteModal(dialog, node.uuid[0].value)"></nb-action>
          </nb-actions>
        </td>
      </tr>
      </tbody>
    </table>
  </nb-card-body>
</nb-card>
