import {LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

@Pipe({
  name: 'taskDate'
})
export class TaskDatePipe implements PipeTransform {

  // adding a default format in case you don't want to pass the format
  // then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'EE d MMMM y - HH:mm', locale: string = 'nl'): string {
    date = new Date(date);  // if original type was a string
    date.setDate(date.getDate());
    return new DatePipe(locale).transform(date, format);
  }

}
