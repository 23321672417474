import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';
import {Client} from '../../models/client/client';
import {ClientService} from '../../services/client/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactService} from '../../services/contact/contact.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {SolarifToastrService} from '../../services/toastr/solarif-toastr.service';
import {ActivityService} from '../../services/activity/activity.service';
import {LockingService} from '../../services/locking/locking.service';
import {Socket} from 'ngx-socket-io';
import {Activity} from '../../models/activity/activity';
import {TaskDialogComponent} from '../task/task-dialog/task-dialog.component';
import {NbDialogService} from '@nebular/theme';
import {ClientContact} from '../../models/client/clientcontact';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line:variable-name
  component_name = 'client';
  client: Client;
  mode = 'view';
  currentLock = 0;
  private subscription: Subscription;

  @Input() clientTitle;
  clientForm: FormGroup;

  contacts: {
    normal: Array<ClientContact>,
    primary: Array<ClientContact>
  };

  activities: Array<Activity>;
  isLoadingActivities = false;
  openActivityId?: string;

  showTaskFilters = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private contactService: ContactService,
    private httpClient: HttpClient,
    private toastrService: SolarifToastrService,
    private activityService: ActivityService,
    private dialogService: NbDialogService,
    private lockingService: LockingService,
    private socket: Socket) {
  }

  ngOnInit() {
    this.client = this.route.snapshot.data.client;
    this.initClient();

    this.route.queryParamMap.subscribe((qpm: any) => {
      if (qpm && qpm.params['activity']) {
        this.openActivityId = qpm.params['activity'];
      }
    });

    // On routerLink to another client
    this.route.params.pipe(skip(1)).subscribe(routeParams => {
      this.clientService.client(routeParams.gid).subscribe(client => {
        this.client = client;
        this.activities = null;
        this.contacts = null;
        this.initClient();
      });
    });
  }

  initClient() {
    this.client.contacts = {
      primary: [],
      normal: [],
      unfiltered: []
    };

    if (this.client) {
      this.fetchContacts();
      this.fetchActivities();
      this.onActivitiesChangedHandler();

      this.contactService.contactsChanged$.subscribe(_ => {
        this.client.contacts = {
          primary: [],
          normal: [],
          unfiltered: []
        };
        this.fetchContacts();
      });

      this.subscription = this.socket.fromEvent<any>(this.component_name).subscribe(currentLock => {
        this.currentLock = currentLock;
      });

      this.joinLockRooms();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setMode() {
    if (this.mode === 'view') {
      this.addLock();
      this.mode = 'edit';
      this.clientForm = new FormGroup({
        title: new FormControl(this.client.title, Validators.required),
      });
    } else {
      this.mode = 'view';
      this.removeLock();
    }
  }

  onSubmit() {

    this.clientService.updateTitle(this.client.id, this.clientForm.get('title').value).subscribe(result => {
      this.toastrService.success(
        $localize`:@@clientNameUpdateSuccess:Client name has been updated.`
      );
      this.client.title = this.clientForm.get('title').value;
      this.setMode();
    }, error => {
      this.toastrService.error(
        $localize`:@@clientNameUpdateFailure:Client name could not be updated.`
      );
    });

  }

  openAddTaskDialog() {
    this.dialogService.open(TaskDialogComponent, {
      context: {
        actionType: 'add',
        client: this.client
      },
    });
  }

  private fetchActivities(): void {
    this.isLoadingActivities = true;
    this.activityService.activities(this.client.id).subscribe(activities => {
      this.activities = activities;
      this.isLoadingActivities = false;
    });
  }

  private fetchContacts(): void {
    this.contactService.contacts(this.client)
      .subscribe(contacts => {
        this.client.contacts = contacts;
      });
  }

  private onActivitiesChangedHandler() {
    this.activityService.activitiesChanged$.subscribe(_ => {
      this.activityService.activities(this.client.id).subscribe(activities => {
        this.activities = activities;
      });
    });
  }

  onGeneralInfoChanged() {
    this.clientService.client(this.client.id).subscribe(client => {
      this.client.businessUnits = client.businessUnits;
      this.client.accountManagers = client.accountManagers;
    });
  }

  addLock() {
    this.lockingService.addLock(this.client.id, this.component_name);
  }

  removeLock() {
    this.lockingService.removeLock(this.client.id, this.component_name);
  }

  getLockStatus() {
    return this.lockingService.getLockStatus(this.client.id, this.component_name);
  }

  joinLockRooms() {
    this.lockingService.joinRooms(this.client.id, [
      'client',
      'client-general-info',
      'client-contact-list-item',
      'client-contact-info',
      'client-address',
    ]);
  }

}
