<h1>Edit page</h1>
<nb-card>
  <nb-card-body>
    <form (ngSubmit)="saveEditPage()" #form="ngForm" name="pageAdd" *ngIf="node">
      <div class="form-item">
        <label for="title" class="label">Title</label>
        <input nbInput fullWidth fieldSize="giant" id="title" type="text" name="title" [required]="true"
               #title="ngModel" [ngModel]="pageData.title">
        <ng-container *ngIf="title.invalid && title.touched ">
          <p class="caption status-danger" *ngIf="title.errors?.required">
            Title is required!
          </p>
        </ng-container>
      </div>
      <div class="form-item">
        <label for="body" class="label">Body</label>
        <ckeditor [editor]="Editor" name="body" id="body" [required]="true" #body="ngModel"
                  [(ngModel)]="pageData.body"></ckeditor>
        <ng-container *ngIf="body.invalid && body.touched">
          <p class="caption status-danger" *ngIf="body.errors?.required">
            Body is required!
          </p>
        </ng-container>
      </div>
      <button type="submit" nbButton fullWidth status="primary" [disabled]="!form.valid || form.submitted">Save</button>
    </form>
  </nb-card-body>
</nb-card>
